import React from "react";
import { Col, Row, Spinner, Table } from "react-bootstrap";
import { useParams } from "react-router-dom";
import { ToastContainer } from "react-toastify";
import AdminEditRestaurantHook from "../../hook/admin/AdminEditRestaurantHook";
import { useTranslation } from "react-i18next";
import Cookies from "js-cookie";
import { useSelector } from "react-redux";

const AdminEditRestaurantInfo = () => {
  const { id } = useParams();
  const { t } = useTranslation();
  const [
    nameAr,
    nameEn,
    address,
    mobile,
    takeOrderCost,
    tableReservedCost,
    tableNumberPerTime,
    onChangenameAr,
    onChangenameEn,
    onChangeSetAddress,
    onChangeSetMobile,
    onChangeSetTakeOrderCost,
    onChangeSetTableReservedCost,
    onChangeSetTableNumberPerTime,
    allCountries,
    cities,
    img,
    onChangeSetCountry,
    onChangeSetCity,
    onImageChange,
    restaurantTypes,
    onChangerestauranttypes,
    sliderImg,
    onSliderImgChange,
    descriptionAr,
    descriptionEn,
    onChangedescriptionAr,
    onChangedescriptionEn,
    resturantType,
    country,
    city,
    onlineReserve,
    onlineTakeOrder,
    onChangeOnlineTakeOrder,
    onChangeOnlineReserve,

    tablekeysValues,
    deleteElement,
    key,
    onChangeKey,
    value,
    onChangeValue,
    clickHandler,

    onSubmit,
  ] = AdminEditRestaurantHook(id);
  const color = useSelector((state) => state.settingsReducers.oneSettings.primaryColor);

  return (
    <div>
      <title>{`${t("edit restaurant")}`} </title>
      <Row className="justify-content-start">
        <div className="admin-content-text pb-4">{t("edit restaurant")}</div>

        <Col sm="8">
          <div className="text-form pb-2">{t("restaurant Image")}</div>
          <div>
            <label htmlFor="upload-photo">
              <img
                src={img}
                alt="fzx"
                height="120px"
                width="120px"
                style={{ cursor: "pointer" }}
              />
            </label>
            <input
              type="file"
              name="photo"
              onChange={onImageChange}
              id="upload-photo"
            />
          </div>

          <div className="text-form pb-2">{t("slider Image")}</div>
          <div>
            <label htmlFor="upload-photo-2">
              <img
                src={sliderImg}
                alt="fzx"
                height="120px"
                width="120px"
                style={{ cursor: "pointer" }}
              />
            </label>
            <input
              type="file"
              name="photo-2"
              onChange={onSliderImgChange}
              id="upload-photo-2"
            />
          </div>

          <label htmlFor="firstname" className="d-block mt-2 ">
            {" "}
            {t("nameAr")}{" "}
          </label>
          <input
            id="firstname"
            value={nameAr}
            type="text"
            className="input-form d-block mt-2 px-3"
            placeholder={t("nameAr")}
            readOnly
          />

          <label htmlFor="countries" className="d-block mt-2 ">
            {t("restaurant type")}
          </label>

          <select
            id="countries"
            name="countries"
            value={resturantType}
            className="select input-form-area mt-2 px-3 "
            readOnly
            disabled
          >
            <option value="0">{t("select restaurant type")}</option>
            {restaurantTypes
              ? restaurantTypes.map((el) => {
                return (
                  <option key={el._id} value={el._id}>
                    {Cookies.get("i18next") === "ar"
                      ? el.resturantTypeAr
                      : el.resturantTypeEn}
                  </option>
                );
              })
              : null}
          </select>

          <label htmlFor="firstname" className="d-block mt-2 ">
            {" "}
            {t("nameEn")}{" "}
          </label>
          <input
            id="firstname"
            value={nameEn}
            type="text"
            className="input-form d-block mt-2 px-3"
            placeholder={t("nameEn")}
            readOnly
          />
          <label htmlFor="countries" className="d-block mt-2 ">
            {t("country")}
          </label>
          <select
            id="countries"
            name="countries"
            value={country}
            readOnly
            disabled
            className="select input-form-area mt-2 px-3 "
          >
            <option value="0">{t("select country")}</option>
            {allCountries
              ? allCountries.map((el) => {
                return (
                  <option key={el._id} value={el._id}>
                    {Cookies.get("i18next") === "ar"
                      ? el.countryAr
                      : el.countryEn}
                  </option>
                );
              })
              : null}
          </select>

          <label htmlFor="countries" className="d-block mt-2 ">
            {t("city")}
          </label>
          <select
            id="countries"
            name="countries"
            onChange={onChangeSetCity}
            value={city}
            disabled
            className="select input-form-area mt-2 px-3 "
          >
            <option value="0">{t("select city")}</option>
            {cities
              ? cities.map((el) => {
                return (
                  <option key={el._id} value={el._id}>
                    {Cookies.get("i18next") === "ar" ? el.cityAr : el.cityEn}
                  </option>
                );
              })
              : null}
            )
          </select>

          <label htmlFor="firstname" className="d-block mt-2 ">
            {" "}
            {t("address")}{" "}
          </label>
          <input
            id="firstname"
            value={address}
            type="text"
            className="input-form d-block mt-2 px-3"
            placeholder={t("address")}
            readOnly
          />

          <label htmlFor="firstname" className="d-block mt-2 ">
            {" "}
            {t("mobile")}{" "}
          </label>
          <input
            id="firstname"
            value={mobile}
            type="number"
            className="input-form d-block mt-2 px-3"
            placeholder={t("mobile")}
            readOnly
          />

          <label htmlFor="firstname" className="d-block mt-2 ">
            {" "}
            {t("descriptionAr")}{" "}
          </label>
          <input
            id="firstname"
            value={descriptionAr}
            onChange={onChangedescriptionAr}
            type="text"
            className="input-form d-block mt-2 px-3"
            placeholder={t("descriptionAr")}
          />

          <label htmlFor="firstname" className="d-block mt-2 ">
            {" "}
            {t("descriptionEn")}{" "}
          </label>
          <input
            id="firstname"
            value={descriptionEn}
            onChange={onChangedescriptionEn}
            type="text"
            className="input-form d-block mt-2 px-3"
            placeholder={t("descriptionEn")}
          />

          <label htmlFor="firstname" className="d-block mt-2 ">
            {" "}
            {t("takeOrderCost")}{" "}
          </label>
          <input
            id="firstname"
            value={takeOrderCost}
            onChange={onChangeSetTakeOrderCost}
            type="number"
            className="input-form d-block mt-2 px-3"
            placeholder={t("takeOrderCost")}
          />
          <label htmlFor="firstname" className="d-block mt-2 ">
            {" "}
            {t("tableReservedCost")}{" "}
          </label>
          <input
            id="firstname"
            value={tableReservedCost}
            onChange={onChangeSetTableReservedCost}
            type="number"
            className="input-form d-block mt-2 px-3"
            placeholder={t("tableReservedCost")}
          />
          <label htmlFor="firstname" className="d-block mt-2 ">
            {" "}
            {t("tableNumberPerTime")}{" "}
          </label>
          <input
            id="firstname"
            value={tableNumberPerTime}
            onChange={onChangeSetTableNumberPerTime}
            type="number"
            className="input-form d-block mt-2 px-3"
            placeholder={t("tableNumberPerTime")}
          />

          <label htmlFor="countries" className="d-block mt-2 ">
            {t("online reserv")}
          </label>

          <select
            id="countries"
            name="countries"
            value={onlineReserve}
            onChange={onChangeOnlineReserve}
            className="select input-form-area mt-2 px-3 "
          >
            <option value="true">{t("active")}</option>
            <option value="false">{t("not active")}</option>
          </select>

          <label htmlFor="countries" className="d-block mt-2 ">
            {t("online take order")}
          </label>

          <select
            id="countries"
            name="countries"
            value={onlineTakeOrder}
            onChange={onChangeOnlineTakeOrder}
            className="select input-form-area mt-2 px-3 "
          >
            <option value="true">{t("active")}</option>
            <option value="false">{t("not active")}</option>
          </select>

          {/*
            <label htmlFor="countries" className="d-block mt-2 ">
              {t("infolist")}
          </label>

     
      <input
      style={{
        borderColor: "gray",
        borderWidth: "1px",
        borderStyle: "solid",
        borderRadius: "6px",
      }}
      value={value}
      onChange={onChangeValue}
      className="d-inline-block mx-3 mt-2 p-1 "
      placeholder="value"
      />
      <button
      onClick={()=>clickHandler()}
      className="btn-save d-inline mt-2 "
      style={{ backgroundColor: 'red' }}
      >
      {t("add")}
      </button>
      

       
      <Table striped bordered hover className="mt-3 mx-5 w-50" style={{textAlign:'center'}}>
      <thead>
        <tr>
          <th>#</th>
         
          <th>value </th>
          
          <th >allawed </th>

        </tr>
      </thead>
      <tbody>
      {
        tablekeysValues? tablekeysValues.map(el=>(
            <tr>
            <td>{el.count}</td>
          
            <td >{el.value}</td>
          
            <td >{el.value2}</td>

            <td><i onClick={()=>deleteElement(el.count)} class="fa-regular fa-trash-can"></i></td>
       
       
          </tr>
        )):''
      }
        
      </tbody>
      </Table>

        */}
        </Col>
      </Row>
      <Row>
        <Col sm="8" className="d-flex justify-content-end ">
          <button
            onClick={onSubmit}
            className="btn-save d-inline mt-2 "
            style={{ backgroundColor: color }}
          >
            {t("edit")}
          </button>
        </Col>

        <Col sm-8></Col>
      </Row>

      <ToastContainer />
    </div>
  );
};

export default AdminEditRestaurantInfo;

import React, { useEffect, useState } from "react";
import { Button, Container, Modal, Row, Spinner } from "react-bootstrap";
import DataTable from "react-data-table-component";
import AdminResturantTimeTableHook from "../../hook/admin/AdminResturantTimeTableHook";
import DataTableExtensions from "react-data-table-component-extensions";
import "react-data-table-component-extensions/dist/index.css";
import { ToastContainer } from "react-toastify";
import { useNavigate } from "react-router-dom";
import AddButton from "../Utilities/AddButton";
import { useTranslation } from "react-i18next";
import Cookies from "js-cookie";
import { useParams } from "react-router-dom";
import formatDate2 from "../../Utilities/formDate2";
import SearchButton from "../Utilities/SearchButton";

const AdminResturantTimeTable = () => {
  const { id } = useParams();
  const { t } = useTranslation();
  const navigate = useNavigate();

  

   
  const[
    times,
    clickHandler,
    handleShowFun
  ] =AdminResturantTimeTableHook(id);

  if(times)
    {
        times.map((time)=>{
           let day=time.dayNum;

           switch(day)
           {
             case 0:
                return time.dayNum=t("Sunday");
                
             case 1:
                return time.dayNum=t("Monday");
                
                case 2:
                    return time.dayNum=t("Tuesday");
                    

                case 3:
                    return time.dayNum=t("Wednesday");
                    

                case 4:
                    return time.dayNum=t("Thursday");
                    

                case 5:
                    return time.dayNum=t("Friday");
                    

                case 6:
                    return time.dayNum=t("Saturday");
                default:
                    
           }
        });
    }

  
  let columns = [
    {
      name: t("time"),
      selector: "time",
      sortable: true,
    },
    {
      name: t("dayNum"),
      selector: "dayNum",
      sortable: true,
    },
   
    {
      name: t("modify"),
      cell: (row) => (
        <Button
          variant="secondary"
          onClick={() => clickHandler(row._id)}
          id={row._id}
        >
          {t("Modify")}
          <i className="fa fa-pencil-square-o mx-1" />
        </Button>
      ),
      ignoreRowClick: true,
      allowOverflow: true,
      button: true,
    },
    {
      name: t("state"),
      cell: (row) =>
        row.status == true ? (
          <Button
            variant="danger"
            onClick={() => {
              handleShowFun(row._id, false);
            }}
            id={row._id}
          >
            {t("block")}
            <i className="fa fa-lock mx-1" />
          </Button>
        ) : (
          <Button
            variant="success"
            onClick={() => {
              handleShowFun(row._id, true);
            }}
            id={row._id}
          >
            {t("active")}
            <i className="fa fa-unlock mx-1" />
          </Button>
        ),
      ignoreRowClick: true,
      allowOverflow: true,
      button: true,
    },
  ];

  const tableData = {
    columns,
    data: times,
  };

  return (
    <div>
      <title>{`${t("time table")}`} </title>

      <div className="d-flex justify-content-between">
        <div className="admin-content-text">{t("timetablesManagment")} </div>
        <AddButton title={t("add time table")} navigateTo={`/admin/addtimetable/${id}`} />
      </div>

      <DataTableExtensions {...tableData} filterPlaceholder={t("search here")}>
        <DataTable
          noHeader
          defaultSortAsc={false}
          pagination
          highlightOnHover
        />
      </DataTableExtensions>
      <ToastContainer />
    </div>
  );

  
}

export default AdminResturantTimeTable;
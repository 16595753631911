import React from "react";
import { Col, Row, Spinner } from "react-bootstrap";
import { ToastContainer } from "react-toastify";
import AdminAddCityHook from "../../hook/admin/AdminAddCityHook";
import { useTranslation } from "react-i18next";
import Cookies from "js-cookie";
import { useParams } from "react-router-dom";
import { useSelector } from "react-redux";

const AdminAddCity = () => {
  const { t } = useTranslation();
  const { id } = useParams();
  const country_id = id;
  const [cityAr, cityEn, onChangeCityAr, onChangeCityEn, onSubmit] = AdminAddCityHook(country_id);
  //console.log(country_id)\
  const color = useSelector((state) => state.settingsReducers.oneSettings.primaryColor);

  return (
    <div>
      <title>{`${t("add cities")}`} </title>
      <Row className="justify-content-start">
        <div className="admin-content-text pb-4">{t("add cities")}</div>

        <Col sm="8">
          <label htmlFor="firstname" className="d-block mt-2 ">
            {" "}
            {t("countrycityAr")}{" "}
          </label>
          <input
            id="firstname"
            value={cityAr}
            onChange={onChangeCityAr}
            type="text"
            className="input-form d-block mt-2 px-3"
            placeholder={t("countrycityAr")}
          />
          <label htmlFor="firstname" className="d-block mt-2 ">
            {" "}
            {t("countrycityEn")}{" "}
          </label>
          <input
            id="firstname"
            value={cityEn}
            onChange={onChangeCityEn}
            type="text"
            className="input-form d-block mt-2 px-3"
            placeholder={t("countrycityEn")}
          />
        </Col>
      </Row>
      <Row>
        <Col sm="8" className="d-flex justify-content-end ">
          <button
            onClick={onSubmit}
            className="btn-save d-inline mt-2 "
            style={{ backgroundColor: color }}
          >
            {t("add")}
          </button>
        </Col>
      </Row>

      <ToastContainer />
    </div>
  );
};

export default AdminAddCity;

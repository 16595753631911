import React from "react";
import { Col, Row, Spinner } from "react-bootstrap";
import { useParams } from "react-router-dom";
import { ToastContainer } from "react-toastify";
import AdminEditCouponHook from "../../hook/admin/AdminEditCouponHook";
import { useTranslation } from "react-i18next";
import { useSelector } from "react-redux";


const AdminEditCoupon = () => {
  const { id } = useParams();
  const { t } = useTranslation();
  const [
    coponCode,
    startDate,
    endDate,
    value,
    type,
    onSubmit,
    onChangeCoponCode,
    onChangeStartDate,
    onChangeEndDate,
    onChangeValue,
    onChangeType

  ] = AdminEditCouponHook(id);
  const color = useSelector((state) => state.settingsReducers.oneSettings.primaryColor);

  return (
    <div>
      <title>{`${t("edit coupon")}`} </title>
      <Row className="justify-content-start">
        <div className="admin-content-text pb-4">{t("edit coupon")}</div>

        <Col sm="8">
          <label htmlFor="firstname" className="d-block mt-2 ">
            {" "}
            {t("coponCode")}{" "}
          </label>
          <input
            id="firstname"
            value={coponCode}
            onChange={onChangeCoponCode}
            type="text"
            className="input-form d-block mt-2 px-3"
            placeholder={t("coponCode")}
          />
          <label htmlFor="firstname" className="d-block mt-2 ">
            {" "}
            {t("startDate")}{" "}
          </label>
          <input
            id="firstname"
            value={startDate}
            onChange={onChangeStartDate}
            type="date"
            className="input-form d-block mt-2 px-3"
            placeholder={t("startDate")}
          />

          <label htmlFor="firstname" className="d-block mt-2 ">
            {" "}
            {t("endDate")}{" "}
          </label>
          <input
            id="firstname"
            value={endDate}
            onChange={onChangeEndDate}
            type="date"
            className="input-form d-block mt-2 px-3"
            placeholder={t("endDate")}
          />

          <label htmlFor="firstname" className="d-block mt-2 ">
            {" "}
            {t("coponvalue")}{" "}
          </label>
          <input
            id="firstname"
            value={value}
            onChange={onChangeValue}
            type="text"
            className="input-form d-block mt-2 px-3"
            placeholder={t("coponvalue")}
          />

          <label htmlFor="firstname" className="d-block mt-2 ">
            {" "}
            {t("copontype")}{" "}
          </label>
          <input
            id="firstname"
            value={type}
            onChange={onChangeType}
            type="text"
            className="input-form d-block mt-2 px-3"
            placeholder={t("copontype")}
          />
        </Col>
      </Row>
      <Row>
        <Col sm="8" className="d-flex justify-content-end ">
          <button
            onClick={onSubmit}
            className="btn-save d-inline mt-2 "
            style={{ backgroundColor: color }}
          >
            {t("edit")}
          </button>
        </Col>
      </Row>

      <ToastContainer />
    </div>
  );



}

export default AdminEditCoupon;

import React from "react";
import SideBar from "../../Components/Admin/SideBar";
import AdminReserveOrderDetails from "../../Components/Admin/AdminReserveOrderDetails"

const AdminReserveOrderDetailsPage = () => {
  return (
    <div className="d-flex justify-content-start">
      <div>
        <SideBar />
      </div>
      <div className="p-4" style={{ width: "100%" }}>
      <AdminReserveOrderDetails />
      </div>
    </div>
  );
};

export default AdminReserveOrderDetailsPage;

import {
    GET_TIME_TABLES_FOR_ONE_RESTURANT ,
         UPDATE_TIME_TABLE,
         ADD_TIME_TABLE,
         GET_ONE_TIME_TABLE
} from "../type";
import { useGetDataToken } from "../../hooks/useGetData";
import { useUpdateData } from "../../hooks/useUpdateData";
import { useInsertData } from "../../hooks/useInsertData";

//get all time tables for one resturant
export const getAllTimeTablesForOneResturant =(id) =>async (dispatch) =>{
    try {
        const response = await useGetDataToken(`/api/v1/timeTables/adminresturant/${id}`);
        dispatch({
            type: GET_TIME_TABLES_FOR_ONE_RESTURANT ,
            payload: response,
          });
    } catch (error) {
        dispatch({
            type: GET_TIME_TABLES_FOR_ONE_RESTURANT ,
            payload: error,
          });
    }
}

//update time table
export const updateTimeTable = (id, body) =>async(dispatch) =>{
    try {
      const response = await useUpdateData(`/api/v1/timeTables/${id}`,body);
  
      dispatch({
        type: UPDATE_TIME_TABLE ,
        payload: response,
       
      });
    } catch (error) {
      dispatch({
        type: UPDATE_TIME_TABLE ,
        payload: error,
      });
    }
}

//add time table
export const addTimeTable=(body)=>async(dispatch) =>{
    //console.log('done');
    const response = await useInsertData(`/api/v1/timeTables`,body);
    try{
    dispatch({
      type: ADD_TIME_TABLE ,
      payload: response,
     
    });
  } catch (error) {
    dispatch({
      type: ADD_TIME_TABLE ,
      payload: error,
    });
  }
}

//get one time table
export const getOneTimeTable=(id) =>async(dispatch) =>{
  try {
    const response = await useGetDataToken(`/api/v1/timeTables/${id}`);
    dispatch({
        type: GET_ONE_TIME_TABLE,
        payload: response,
      });
} catch (error) {
    dispatch({
        type: GET_ONE_TIME_TABLE,
        payload: error,
      });
}
}

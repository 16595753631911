import React, { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { getAllCountries, updateCountry } from "../../redux/actions/countriesActions";
import { useNavigate } from "react-router-dom";
import notify from "../useNotification";

const ViewAllCountriesHook = () => {
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const [loading, setLoading] = useState(true);



  useEffect(() => {
   // console.log('use effect country')
    dispatch(getAllCountries());
  }, []);



  const allCountries = useSelector((state) => state.countriesReducers.allCountries);   //allCountries

  
  let countries = [];

  if (allCountries) countries = allCountries;

  const clickHandler = (id) => {
    navigate(`/admin/editcountry/${id}`);
    
  };


  
  useEffect(() => {
    if (loading === false) {
      if (res) {
        notify("تم التعديل بنجاح", "success");
        setTimeout(() => {
          dispatch(getAllCountries());
        }, 1000);
      } else {
        notify("فشل في عملية التعديل", "error");
      }
    }
  }, [loading]);

  const handleShowFun = async (id, status) => {
   // console.log('done')
    setLoading(true);
    await dispatch(

      updateCountry(id, {
        status: status,
      })
    );
    setLoading(false);
  };
  const res = useSelector((state) => state.countriesReducers.updateCountry);
  //console.log(res);




  return [countries,clickHandler,handleShowFun];
};

export default ViewAllCountriesHook;

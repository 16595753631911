import React, { useEffect, useState } from "react";
import { Button, Container, Modal, Row, Spinner } from "react-bootstrap";
import DataTable from "react-data-table-component";
import ViewAllCountriesHook from "../../hook/admin/ViewAllCountriesHook";
import DataTableExtensions from "react-data-table-component-extensions";
import "react-data-table-component-extensions/dist/index.css";
import { ToastContainer } from "react-toastify";
import { useNavigate } from "react-router-dom";
import AddButton from "../Utilities/AddButton";
import { useTranslation } from "react-i18next";
import Cookies from "js-cookie";

const AdminAllCountries = () => {
  const { t } = useTranslation();
  //const [users, clickHandler, handleShowFun] = ViewAllUsersHook();  countries,clickHandler,handleShowFun
  const [countries, clickHandler, handleShowFun] = ViewAllCountriesHook();

  //console.log(countries);
  const navigate = useNavigate();

  let columns = [
    {
      name: t("countryAr"),
      selector: "countryAr",
      sortable: true,
    },
    {
      name: t("countryEn"),
      selector: "countryEn",
      sortable: true,
    },
    {
      name: t("countryCode"),
      selector: "countryCode",
      sortable: true,
    },
    {
      name: t("CountryTellCode"),
      selector: "CountryTellCode",
      sortable: true,
    },
    
    {
      name: t("cities"),
      cell: (row) => (
        <div
          style={{ cursor: 'pointer' }}
          variant='secondary'
          id={row._id}
          onClick={() => navigate(`/admin/cities/country/${row._id}`)}
        >
          {t("clickhere")}
          <i className="fa fa-folder-open-o mx-1" />

        </div>
      ),
      ignoreRowClick: true,
      allowOverflow: true,
    },

    {
      name: t("flag"),
      selector: "flag",
      sortable: true,
    },

    {
      name: t("modify"),
      cell: (row) => (
        <Button
          variant="secondary"
          onClick={() => clickHandler(row._id)}
          id={row._id}
        >
          {t("Modify")}
          <i className="fa fa-pencil-square-o mx-1" />
        </Button>
      ),
      ignoreRowClick: true,
      allowOverflow: true,
      button: true,
    },
    {
      name: t("state"),
      cell: (row) =>
        row.status == true ? (
          <Button
            variant="danger"
            onClick={() => {
              handleShowFun(row._id, false);
            }}
            id={row._id}
          >
            {t("block")}
            <i className="fa fa-lock mx-1" />
          </Button>
        ) : (
          <Button
            variant="success"
            onClick={() => {
              handleShowFun(row._id, true);
            }}
            id={row._id}
          >
            {t("active")}
            <i className="fa fa-unlock mx-1" />
          </Button>
        ),
      ignoreRowClick: true,
      allowOverflow: true,
      button: true,
    },
  ];

  const tableData = {
    columns,
    data: countries,
  };

  return (
    <div>
      <title>{`${t("countries")}`} </title>

      <div className="d-flex justify-content-between">
        <div className="admin-content-text">{t("countriesManagment")} </div>
        <AddButton title={t("add country")} navigateTo="/admin/addcountry" />
      </div>

      <DataTableExtensions {...tableData} filterPlaceholder={t("search here")}>
        <DataTable
          noHeader
          defaultSortAsc={false}
          pagination
          highlightOnHover
        />
      </DataTableExtensions>
      <ToastContainer />
    </div>
  );
};
export default AdminAllCountries;

import React, { useEffect, useState } from "react";
import { Button, Container, Modal, Row, Spinner } from "react-bootstrap";
import DataTable from "react-data-table-component";
import ViewAllRestaurantTypesHook from "../../hook/admin/ViewAllRestaurantTypesHook";
import DataTableExtensions from "react-data-table-component-extensions";
import "react-data-table-component-extensions/dist/index.css";
import { ToastContainer } from "react-toastify";
import { useNavigate } from "react-router-dom";
import AddButton from "../Utilities/AddButton";
import { useTranslation } from "react-i18next";
import Cookies from "js-cookie";

const AdminAllRestaurantTypes = () => {
    const { t } = useTranslation();
    const navigate = useNavigate();
  const [restaurantTypes, clickHandler, handleShowFun] =
    ViewAllRestaurantTypesHook();

    let columns = [
        {
          name: t("resturantTypeAr"),
          selector: "resturantTypeAr",
          sortable: true,
        },
        {
          name: t("resturantTypeEn"),
          selector: "resturantTypeEn",
          sortable: true,
        },
       
    
        {
          name: t("modify"),
          cell: (row) => (
            <Button
              variant="secondary"
              onClick={() => clickHandler(row._id)}
              id={row._id}
            >
              {t("Modify")}
              <i className="fa fa-pencil-square-o mx-1" />
            </Button>
          ),
          ignoreRowClick: true,
          allowOverflow: true,
          button: true,
        },
        {
          name: t("state"),
          cell: (row) =>
            row.status == true ? (
              <Button
                variant="danger"
                onClick={() => {
                  handleShowFun(row._id, false);
                }}
                id={row._id}
              >
                {t("block")}
                <i className="fa fa-lock mx-1" />
              </Button>
            ) : (
              <Button
                variant="success"
                onClick={() => {
                  handleShowFun(row._id, true);
                }}
                id={row._id}
              >
                {t("active")}
                <i className="fa fa-unlock mx-1" />
              </Button>
            ),
          ignoreRowClick: true,
          allowOverflow: true,
          button: true,
        },
      ];
    
      const tableData = {
        columns,
        data: restaurantTypes,
      };
    
      return (
        <div>
          <title>{`${t("restaurantTypes")}`} </title>
    
          <div className="d-flex justify-content-between">
            <div className="admin-content-text">{t("restaurantTypesManagment")} </div>
            <AddButton title={t("add restaurantTypes")} navigateTo="/admin/addRestaurantType" />
          </div>
    
          <DataTableExtensions {...tableData} filterPlaceholder={t("search here")}>
            <DataTable
              noHeader
              defaultSortAsc={false}
              pagination
              highlightOnHover
            />
          </DataTableExtensions>
          <ToastContainer />
        </div>
      );
};

export default AdminAllRestaurantTypes;

import { Row, Col } from "react-bootstrap";
import React from "react";
import { ToastContainer } from "react-toastify";
import AdminSettingsHook from "../../hook/admin/AdminSettingsHook";
import add from "../../Images/add.png";
import { CompactPicker } from "react-color";

import { t } from "i18next";
import { useSelector } from "react-redux";
const AdminSettings = () => {
  const [
    facebook,
    phone,
    onChangeFacebook,
    onChangePhone,
    onSubmit,
    phone2,
    onChangePhone2,
    email,
    onChangeEmail,
    address,
    onChangeAddress,
    titleAr,
    titleEn,
    onChangeTitleAr,
    onChangeTitleEn,
    showPicker,
    colors,
    handleChaneComplete,
    onChangePicker,
    img,
    onImageChange,
    seccolors,
    onChangePickerseccolors,
    handleChaneCompleteseccolors,
    showPickerseccolors,
    vat,
    onChangeVat
  ] = AdminSettingsHook();


  // const color = useSelector(
  //   (state) => state.utilitiesReducer.color
  // );

  const color = useSelector((state) => state.settingsReducers.oneSettings.primaryColor);

  return (
    <div>
      <Row className="justify-content-start" >
        <Col sm="8">
          <div className="admin-content-text pb-4">{t("settings")}</div>
          <div className="text-form pb-2">{t("websiteLogo")}</div>
          <div>
            <label htmlFor="upload-photo">
              <img
                src={img}
                alt="fzx"
                height="120px"
                width="120px"
                style={{
                  cursor: "pointer", borderRadius: '50%'
                }}
              />
            </label>
            <input
              type="file"
              onChange={onImageChange}
              name="photo"
              id="upload-photo"
            />
          </div>
          <label htmlFor="titleAr" className="d-block mt-2 ">
            {t("address in arabic")}
          </label>
          <input
            id="titleAr"
            value={titleAr}
            onChange={onChangeTitleAr}
            type="text"
            className="input-form d-block mt-2 px-3"
            placeholder={t("address")}
          />
          <label htmlFor="titleEn" className="d-block mt-2 ">
            {t("address in english")}
          </label>

          <input
            id="titleEn"
            value={titleEn}
            onChange={onChangeTitleEn}
            type="text"
            className="input-form d-block mt-2 px-3"
            placeholder="Title"
          />

          <label htmlFor="address" className="d-block mt-2 ">

            {t("Address in detail")}
          </label>
          <input
            id="address"
            value={address}
            onChange={onChangeAddress}
            type="text"
            className="input-form d-block mt-2 px-3"
            placeholder={t("Address in detail")}
          />
          <label htmlFor="face" className="d-block mt-2 ">

            {t("Facebook")}
          </label>

          <input
            id="face"
            value={facebook}
            onChange={onChangeFacebook}
            type="text"
            className="input-form d-block mt-2 px-3"
            placeholder={t("Facebook")}
          />
          <label htmlFor="phone1" className="d-block mt-2 ">
            {t("Mobile Number 1")}
          </label>

          <input
            id="phone1"
            value={phone}
            onChange={onChangePhone}
            type="text"
            className="input-form d-block mt-2 px-3"
            placeholder={t("phone number")}
          />
          <label htmlFor="phone2" className="d-block mt-2 ">
            {t("Mobile Number 2")}
          </label>
          <input
            id="phone2"
            value={phone2}
            onChange={onChangePhone2}
            type="text"
            className="input-form d-block mt-2 px-3"
            placeholder={t("phone number")}
          />
          <label htmlFor="email" className="d-block mt-2 ">

            {t("email")}
          </label>
          <input
            id="email"
            value={email}
            onChange={onChangeEmail}
            type="text"
            className="input-form d-block mt-2 px-3"
            placeholder={t("email")}
          />
          <input
            id="vat"
            value={vat}
            onChange={onChangeVat}
            type="text"
            className="input-form d-block mt-2 px-3"
            placeholder={t("vat")}
          />
          <div className="d-flex justify-content-start">
            <div className="text-form mt-3 mx-2"> اللون الرئيسي</div>
            <div className="mt-1 d-flex">
              {colors && (
                <div
                  className="color ms-2 border  mt-1 mx-2"
                  style={{ backgroundColor: colors }}
                ></div>
              )}
              <img
                src={add}
                alt=""
                width="30px"
                height="35px"
                style={{ cursor: "pointer" }}
                onClick={onChangePicker}
              />

              {showPicker === true ? (
                <CompactPicker onChangeComplete={handleChaneComplete} />
              ) : null}
            </div>

            <div className="text-form mt-3 mx-2"> اللون الفرعى</div>
            <div className="mt-1 d-flex">
              {seccolors && (
                <div
                  className="color ms-2 border  mt-1 mx-2"
                  style={{ backgroundColor: seccolors }}
                ></div>
              )}
              <img
                src={add}
                alt=""
                width="30px"
                height="35px"
                style={{ cursor: "pointer" }}
                onClick={onChangePickerseccolors}
              />

              {showPickerseccolors === true ? (
                <CompactPicker onChangeComplete={handleChaneCompleteseccolors} />
              ) : null}
            </div>


          </div>
        </Col>
      </Row>
      <Row>
        <Col sm="8" className="d-flex justify-content-end ">
          <button
            onClick={onSubmit}
            className="btn-save d-inline mt-3 "
            style={{ backgroundColor: color && color }}
          >
            {t("save")}
          </button>
        </Col>
      </Row>
      <ToastContainer />
    </div>
  );
};

export default AdminSettings;

import { useGetDataToken } from "../../hooks/useGetData";
import { useInsertDataWithImage } from "../../hooks/useInsertData";
import { useUpdateDataWithImage } from "../../hooks/useUpdateData";
import {
  ADD_CATEGORY_RESURANT,
  GET_ALL_RESTIRANT_CATEGORY,
  GET_ONE_CATEGORY,
  UPDATE_CATEGORY_RESTURANT,
} from "../type";

// get ALL Categories of resturant
export const getAllResturantCategories = (id) => async (dispatch) => {
  try {
    const response = await useGetDataToken(
      `/api/v1/categories/resturant/${id}`
    );
    dispatch({
      type: GET_ALL_RESTIRANT_CATEGORY,
      payload: response,
    });
  } catch (error) {
    dispatch({
      type: GET_ALL_RESTIRANT_CATEGORY,
      payload: error.response,
    });
  }
};

// update category of resturant
export const updateCategoryResturant = (id, body) => async (dispatch) => {
  try {
    const response = await useUpdateDataWithImage(
      `/api/v1/categories/${id}`,
      body
    );
    dispatch({
      type: UPDATE_CATEGORY_RESTURANT,
      payload: response,
    });
  } catch (error) {
    dispatch({
      type: UPDATE_CATEGORY_RESTURANT,
      payload: error.response,
    });
  }
};

// add category to resturant
export const addCategoryResturant = (body) => async (dispatch) => {
  try {
    const response = await useInsertDataWithImage(`/api/v1/categories`, body);
    console.log('response')
    console.log(response)
    dispatch({
      TYPE: ADD_CATEGORY_RESURANT,
      payload: response,
    });
  } catch (error) {
    dispatch({
      type: ADD_CATEGORY_RESURANT,
      payload: error.response,
    });
  }
};

// get one Category
export const getOneCategory = (id) => async (dispatch) => {
  try {
    const response = await useGetDataToken(`/api/v1/categories/${id}`)
    dispatch({
      type:GET_ONE_CATEGORY,
      payload:response
    })
  } catch (error) {
    dispatch({
      type:GET_ONE_CATEGORY,
      payload:error.response
    })
  }
}
import React, { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import notify from "../../hook/useNotification";
import { useNavigate } from "react-router-dom";
import { getOneCity,updateCity } from "../../redux/actions/citiesActions";
import avatar from "../../../src/Images/adminIcon.png";

const AdminEditCityHook =(id)=>{
    const navigate = useNavigate();
    const dispatch = useDispatch();
    const [cityAr, setCityAr] = useState("");
    const [cityEn, setCityEn] = useState("");
    const[country_id,setCountry_id]=useState("");
    const [loading, setLoading] = useState(true);

    

    //set city ar state
    const onChangeCityAr= (e)=>{
        e.persist();
        setCityAr(e.target.value)
    }

    //set city en state
    const onChangeCityEn=(e) =>{
        e.persist();
        setCityEn(e.target.value);
    }

      useEffect(() =>{
        dispatch(getOneCity(id));
      },[dispatch,id]);

      const oneCity=useSelector( (state) => state.citiesReducers.getOneCity);

      useEffect(()=>{
        setCityAr(oneCity.cityAr);
        setCityEn(oneCity.cityEn);
        setCountry_id(oneCity.country)
        //country_id=oneCity.country;
      },[oneCity]);

      const onSubmit=async() =>{
        if(cityAr=='')
        {
            notify("من فضلك ادخل اسم المدينة بالعربية", "error");
            return;
        }

        if(cityEn=='')
        {
            notify("من فضلك ادخل اسم المدينة بالانجليزية", "error");
            return;
        }

        await dispatch(
            updateCity(id,{
                cityAr:cityAr,
                cityEn:cityEn,
            })
        )

        setLoading(false);
      }

      const res = useSelector((state) => state.citiesReducers.updateCity);

      useEffect(()=>{
        if (loading === false) {
            if (res._id) {
              notify("تم التعديل بنجاح", "success");
              setTimeout(() => {
                navigate(`/admin/cities/country/${country_id}`);
              }, 1500);
            } else {
              notify("فشل في عملية التعديل", "error");
            }
          }

      },[loading])

      return[
        cityAr,
        cityEn,
        onChangeCityAr,
        onChangeCityEn,
        onSubmit
      ]

}
export default AdminEditCityHook;
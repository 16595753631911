import React from "react";
import { Button } from "react-bootstrap";
import { useTranslation } from "react-i18next";
import { useSelector } from "react-redux";
import { useNavigate } from "react-router-dom";

const SearchButton =({search})=>{

   // console.log(search)

     const {t}=useTranslation();
    return(
        <div>
        <label>بحث بالحالة</label>
        <select className="btn-save d-inline mt-2" style={{color:'black',marginRight:'15px',marginLeft:'15px'}}>
        <option value=""> {t("all")}</option>
        <option value="pending">{t("pending")}</option>
        <option value="preparing">{t("preparing")}</option>
        <option value="received">{t("received")}</option>
        <option value="closed">{t("closed")}</option>
        </select>
        <button className="btn-save d-inline mt-2" style={{background:'#c78ff2'}} onClick={search}>بحث</button>
        </div>
    )
}
export default SearchButton;
import React, { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import notify from "../useNotification";
import { useNavigate } from "react-router-dom";
import { updateTimeTable ,getOneTimeTable } from "../../redux/actions/timeTablesActions";
import { useTranslation } from "react-i18next";



const AdminEditResturantTimeTableHook =(id)=>{
    const navigate = useNavigate();
    const dispatch = useDispatch();
    const[loading,setLoading]=useState(true);
    const[time,setTime]=useState('');
    const[dayNum,setDayNum]=useState('');
    const[ResturantId,setResturantId]=useState('');

    useEffect(()=>{
      dispatch(getOneTimeTable(id));
    },[dispatch,id]);

    const timeTable= useSelector((state)=>state.timeTablesReducer.getOneTimeTable);
    
    useEffect(()=>{
      if(timeTable)
      {
         setTime(timeTable.time);
         setDayNum(timeTable.dayNum);
         setResturantId(timeTable.resturant)
      }
    },[timeTable])

    const onChangeSetTime= (e)=>{
        setTime(e.target.value);
     }
  
     const onChangeSetDayNum = (e)=>{
        setDayNum(e.target.value);
     }

    const onSubmit=async()=>{

      if(timeTable=='' || dayNum=='')
      {
        notify("من فضلك ادخل الحقول الناقصة", "error");
            return;
      }

      await dispatch(
        updateTimeTable(id,{
            time:time,
            dayNum:dayNum,
        })
    )

    setLoading(false);
    }

    let response= useSelector((state)=>state.timeTablesReducer.updateTimeTable);

    useEffect(() => {
      if (loading === false) {
          if (response) {
           
            notify("تم تعديل الموعد بنجاح", "success");
            setTimeout(() => {
              navigate(`/admin/timetable/${ResturantId}`);
            }, 1500);
          }
          else{
            notify("فشل في عملية التعديل", "error");
          }
        }
      
      }, [loading]);

    return[
        time,
        dayNum,
        onChangeSetTime,
        onChangeSetDayNum,
        onSubmit
        
    ]
 
}

export default AdminEditResturantTimeTableHook;
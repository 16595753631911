
import React from "react";
import { Col, Row } from "react-bootstrap";
import { useTranslation } from "react-i18next";
import { useSelector } from "react-redux";
import { useParams } from "react-router-dom";
import { ToastContainer } from "react-toastify";
import AdminEditMenuHook from "../../hook/admin/AdminEditMenuHook";

const AdminAddMenu = () => {
  const { t } = useTranslation();
  const { id } = useParams();
  const [
    nameAr,
    nameEn,
    price,
    oldPrice,
    description,
    image,
    onChangeNameAr,
    onChangeNameEn,
    onChangePrice,
    onChangeOldPrice,
    onChangeDescription,
    onImageChange,
    onSubmit
  ] = AdminEditMenuHook(id)
  const color = useSelector((state) => state.settingsReducers.oneSettings.primaryColor);


  return (
    <div>
      <title>{`${t("Edit Menu")}`} </title>
      <Row className="justify-content-start">
        <div className="admin-content-text pb-4">{t("Edit Menu")}</div>

        <Col sm="8">
          <div className="text-form pb-2">{t("image")}</div>
          <div>
            <label htmlFor="upload-photo">
              <img
                src={image}
                alt="fzx"
                height="120px"
                width="120px"
                style={{ cursor: "pointer" }}
              />
            </label>
            <input
              type="file"
              name="photo"
              onChange={onImageChange}
              id="upload-photo"
            />
          </div>

          <label htmlFor="firstname" className="d-block mt-2 ">
            {" "}
            {t("nameAr")}{" "}
          </label>
          <input
            id="firstname"
            value={nameAr}
            onChange={onChangeNameAr}
            type="text"
            className="input-form d-block mt-2 px-3"
            placeholder={t("nameAr")}
          />
          <label htmlFor="firstname" className="d-block mt-2 ">
            {" "}
            {t("nameEn")}{" "}
          </label>
          <input
            id="firstname"
            value={nameEn}
            onChange={onChangeNameEn}
            type="text"
            className="input-form d-block mt-2 px-3"
            placeholder={t("nameEn")}
          />
          <label htmlFor="price" className="d-block mt-2 ">
            {" "}
            {t("price")}{" "}
          </label>
          <input
            id="price"
            value={price}
            onChange={onChangePrice}
            type="number"
            className="input-form d-block mt-2 px-3"
            placeholder={t("price")}
          />
          <label htmlFor="Old Price" className="d-block mt-2 ">
            {" "}
            {t("Old Price")}{" "}
          </label>
          <input
            id="Old Price"
            value={oldPrice}
            onChange={onChangeOldPrice}
            type="number"
            className="input-form d-block mt-2 px-3"
            placeholder={t("Old Price")}
          />
          <label htmlFor="Description" className="d-block mt-2 ">
            {t("Description")}
          </label>
          <textarea
            id="Description"
            onChange={onChangeDescription}
            value={description}
            className="input-form-area p-2 mt-2"
            rows="4"
            cols="50"
            placeholder={t("Description")}
          />

        </Col>
      </Row>
      <Row>
        <Col sm="8" className="d-flex justify-content-end ">
          <button
            onClick={onSubmit}
            className="btn-save d-inline mt-2 "
            style={{ backgroundColor: color }}
          >
            {t("edit")}
          </button>
        </Col>
      </Row>

      <ToastContainer />
    </div>
  );
};

export default AdminAddMenu;

import React, { useState } from "react";
import { Row, Col } from "react-bootstrap";
import DataTable from "react-data-table-component";
import DataTableExtensions from "react-data-table-component-extensions";
import { t } from "i18next";
import LoadingBar from "react-top-loading-bar";
import AdminReserveOrderDetailsHook from "../../hook/admin/AdminReserveOrderDetailsHook";
import { ToastContainer } from "react-toastify";
import { useParams } from "react-router-dom";
import { useTranslation } from "react-i18next";
import UserAllOrderItem from "./UserAllOrderItem";
import { useSelector } from "react-redux";


const AdminReserveOrderDetails = () => {

    const { id } = useParams();
    const { t } = useTranslation();
    //const navigate = useNavigate();
    const color = useSelector((state) => state.settingsReducers.oneSettings.primaryColor);

   const[orderDetails,
    reserveStatus,
    onChangeReserveStatus,
    onSubmit
        ]=AdminReserveOrderDetailsHook(id);

   let status='';


   switch(orderDetails.status)
   {
    case 'pending':
        status=t("pending");
        break;

    case 'closed':
        status=t("closed");
        break;
    
    case 'preparing':
        status=t("preparing");
        break;
    
    case 'received' :
        status =t("closed");
        break;

        default:
            
   }

   return (
    <div>
    <title>{`${t("reserv details")}`} </title>
        <LoadingBar color="#000"  shadow={true} height={4} />
        <div className="admin-content-text mb-3">
            {`${t("reservedate")} :- ${orderDetails.createdAt || ""} `}
        </div>
    
        <Row className="d-flex justify-content-between">
            <Col xs="12" className="d-flex">
                <div style={{ display: 'flex', justifyContent: 'center', alignItems: 'center' }}>
                    <div className="d-inline">{t("reserve state")}</div>
                    <div className="d-inline mx-2 stat" style={{ fontWeight: 'bold' }}>
                    
                    {status}
                    </div>
                </div>
                <div style={{ display: 'flex', justifyContent: 'center', alignItems: 'center' }}>
                    <div className="d-inline">طريقة الدفع</div>
                    <div className="d-inline mx-2 stat">
                        {orderDetails?.paymentMethodId?.nameAr}
                    </div>
                </div>
            </Col>
            <Col xs="12" className="d-flex justify-content-end">
                <div style={{ textAlign: 'left' }}>
                    <div className="barnd-text mb-3 mx-2">{orderDetails.price || 0} ريال سعودى </div>
                </div>
            </Col>
        </Row >

        <Row className=" my-2 pb-3 user-data h-100">
            <Col xs="12" className=" d-flex">
                <div className="admin-content-text py-2 ">{t("Customer Details")} </div>
            </Col>
            <Col xs="12" className="d-flex p-2">
                <div
                    style={{
                        color: "#555550",
                        fontFamily: "Almarai",
                        fontSize: "18px",
                    }}
                >
                    {t("name")}:
                </div>
                <div
                    style={{
                        color: "#979797",
                        fontFamily: "Almarai",
                        fontSize: "18px",
                    }}
                    className="mx-2"
                >
                    {`${(orderDetails.userId && orderDetails.userId.fullname) || ""}`}
                </div>
            </Col>
            <Col xs="12" className="d-flex p-2">
                <div
                    style={{
                        color: "#555550",
                        fontFamily: "Almarai",
                        fontSize: "18px",
                    }}
                >
                    {t("phone number")}:
                </div>

                <div
                    style={{
                        color: "#979797",
                        fontFamily: "Almarai",
                        fontSize: "18px",
                    }}
                    className="mx-2"
                >
                    {orderDetails.userId && orderDetails.userId.mobile}
                </div>
            </Col>
            {
                orderDetails.userId && orderDetails.userId.email &&
                <Col xs="12" className="d-flex p-2">
                    <div
                        style={{
                            color: "#555550",
                            fontFamily: "Almarai",
                            fontSize: "18px",
                        }}
                    >
                        {t("email")}:
                    </div>

                    <div
                        style={{
                            color: "#979797",
                            fontFamily: "Almarai",
                            fontSize: "18px",
                        }}
                        className="mx-2"
                    >
                        {orderDetails.userId.email}
                    </div>
                </Col>
            }

            <div className="d-flex mt-2 justify-content-center">
                <label htmlFor="paid" className="mt-3 mx-3 " style={{ fontWeight: 'bold' }}>{t("reserve state")}</label>
                <div className="d-flex mt-2 justify-content-center">
                    <select
                        name="pay"
                        id="paid"
                        className="select input-form-area mt-1  text-center px-2 w-75"
                        value={reserveStatus}
                        onChange={onChangeReserveStatus}
                    >
                        <option value="pending">{t("pending")}</option>
                        <option value="preparing">{t("preparing")}</option>
                        <option value="received">{t("received")}</option>
                        <option value="closed">{t("closed")}</option>
                    </select>
                    <button
                        className="btn-a px-3 d-inline mx-2 " style={{ backgroundColor: color }}
                        onClick={onSubmit}
                        
                    >
                        {t("save")}
                    </button>
                </div>
            </div>
        </Row>
        <ToastContainer />
    </div>
);

}

export default AdminReserveOrderDetails;
import React from "react";
import { Col, Row, Spinner } from "react-bootstrap";
import { ToastContainer } from "react-toastify";
import AdminAddCountryHook from "../../hook/admin/AdminAddCountryHook";
import { useTranslation } from "react-i18next";
import Cookies from "js-cookie";
import { useSelector } from "react-redux";

const AdminAddCountry = () => {
  const { t } = useTranslation();
  const [
    countryAr,
    countryEn,
    countryCode,
    CountryTellCode,
    img,
    selectedFile,
    onSubmit,
    onChangeCountryAr,
    onChangeCountryEn,
    onChangeCountryCode,
    onChangeCountryTellCode,
  ] = AdminAddCountryHook();

  const color = useSelector((state) => state.settingsReducers.oneSettings.primaryColor);

  return (
    <div>
      <title>{`${t("add country")}`} </title>
      <Row className="justify-content-start">
        <div className="admin-content-text pb-4">{t("add country")}</div>

        <Col sm="8">
          <label htmlFor="firstname" className="d-block mt-2 ">
            {" "}
            {t("countryAr")}{" "}
          </label>
          <input
            id="firstname"
            value={countryAr}
            onChange={onChangeCountryAr}
            type="text"
            className="input-form d-block mt-2 px-3"
            placeholder={t("countryAr")}
          />
          <label htmlFor="firstname" className="d-block mt-2 ">
            {" "}
            {t("countryEn")}{" "}
          </label>
          <input
            id="firstname"
            value={countryEn}
            onChange={onChangeCountryEn}
            type="text"
            className="input-form d-block mt-2 px-3"
            placeholder={t("countryEn")}
          />

          <label htmlFor="firstname" className="d-block mt-2 ">
            {" "}
            {t("countryCode")}{" "}
          </label>
          <input
            id="firstname"
            value={countryCode}
            onChange={onChangeCountryCode}
            type="text"
            className="input-form d-block mt-2 px-3"
            placeholder={t("countryCode")}
          />

          <label htmlFor="firstname" className="d-block mt-2 ">
            {" "}
            {t("CountryTellCode")}{" "}
          </label>
          <input
            id="firstname"
            value={CountryTellCode}
            onChange={onChangeCountryTellCode}
            type="text"
            className="input-form d-block mt-2 px-3"
            placeholder={t("CountryTellCode")}
          />
        </Col>
      </Row>
      <Row>
        <Col sm="8" className="d-flex justify-content-end ">
          <button
            onClick={onSubmit}
            className="btn-save d-inline mt-2 "
            style={{ backgroundColor: color }}
          >
            {t("add")}
          </button>
        </Col>
      </Row>

      <ToastContainer />
    </div>
  );
};

export default AdminAddCountry;

import React, { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import {
  updateUserPassword,
  updateUserProfile,
} from "../../redux/actions/authActions";
import notify from "../../hook/useNotification";
import { useNavigate } from "react-router-dom";
import { getOneUser } from "../../redux/actions/usersActions";

const ProfileHook = () => {
  const navigate = useNavigate();

  let user = [];
  if (localStorage.getItem("user") != null)
    user = JSON.parse(localStorage.getItem("user"));

  const [fullname, setFullname] = useState(user.fullname);
  const [mobile, setMobile] = useState(user.mobile);
  const [email, setEmail] = useState(user.email);
  const [show, setShow] = useState(false);
  const [loading, setLoading] = useState(true);
  const handleClose = () => setShow(false);
  const handleShow = () => setShow(true);
  const [showTable, setShowTable] = useState(false);

  const dispatch = useDispatch();

  const onChangeFullname = (e) => {
    e.persist();
    setFullname(e.target.value);
  };
  const onChangeMobile = (e) => {
    e.persist();
    setMobile(e.target.value);
  };
  const onChangeEmail = (e) => {
    e.persist();
    setEmail(e.target.value);
  };


  const handleSubmit = async () => {
    setLoading(true);
    await dispatch(
      updateUserProfile(user._id, {
        firstname: fullname,
        mobile: mobile,
        email: email,
      })
    );
    setLoading(false);

    setShow(false);
  };

  const res = useSelector((state) => state.authReducers.userProfile);
  useEffect(() => {
    if (loading === false) {
      if (res && res.success === true) {
        localStorage.setItem("user", JSON.stringify(res.data));
        notify("تم التعديل بنجاح", "success");
        setTimeout(() => {
          window.location.reload(false);
        }, 1500);
      } else {
        notify("فشل فيه عملية التحديث", "warn");
      }
    }
  }, [loading]);
  // change user password
  const [oldpassword, setOldpassword] = useState("");
  const [newPassword, setNewPassword] = useState("");
  const [confirmPassword, setConfirmPassword] = useState("");
  const [loadingPass, setLoadingPass] = useState("");



  const onChangeOldPassword = (e) => {
    e.persist();
    setOldpassword(e.target.value);
  };
  const onChangeNewPassword = (e) => {
    e.persist();
    setNewPassword(e.target.value);
  };
  const onChangeConfirmPassword = (e) => {
    e.persist();
    setConfirmPassword(e.target.value);
  };

  const changePassword = async () => {
    if (oldpassword === "" || newPassword === "" || confirmPassword === "") {
      notify("من فضلك اكمل البيانات", "error");
    } else if (newPassword !== confirmPassword) {
      notify("تاكيد البسورد غير صحيح", "error");
    } else {
      setLoadingPass(true);
      await dispatch(
        updateUserPassword(user._id, {
          oldPassword: oldpassword,
          password: newPassword,
        })
      );
      setLoadingPass(false);
    }
  };

  const resPassword = useSelector((state) => state.authReducers.userPassword);

  useEffect(() => {
    if (loadingPass === false) {
      if (resPassword && resPassword.success === true) {
        notify("تم تغير كلمة المرور بنجاح", "success");
        setTimeout(() => {
          localStorage.removeItem("token");
          localStorage.removeItem("user");
          navigate("/login");
        }, 1500);
      } else {
        notify("كلمة المرور القديمة غير صحيحة", "warn");
      }
    }
  }, [loadingPass]);


  const oneUser = useSelector((state) => state.usersReducers.oneUser);

  useEffect(() => {
    const get = async () => {
      await dispatch(getOneUser(user._id));
    };
    get();
  }, []);



  return [
    user,
    handleShow,
    handleClose,
    handleSubmit,
    show,
    fullname,
    mobile,
    email,
    onChangeFullname,
    onChangeMobile,
    onChangeEmail,
    changePassword,
    oldpassword,
    newPassword,
    confirmPassword,
    onChangeOldPassword,
    onChangeNewPassword,
    onChangeConfirmPassword,

  ];
};

export default ProfileHook;

// setValuekeyInput(
//   [...valueKeyInput,
//     {value: value} ,
//   ]
// );
    // setValuekeyInput(
    //   ...valueKeyInput,
    //   valueKeyInput[key] = value,
    // );
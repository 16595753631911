import React, { useEffect, useState } from "react";
import { Button, Container, Modal, Row, Spinner } from "react-bootstrap";
import DataTable from "react-data-table-component";
import AdminAllReservOrderHook from "../../hook/admin/AdminAllReservOrderHook";
import DataTableExtensions from "react-data-table-component-extensions";
import "react-data-table-component-extensions/dist/index.css";
import { ToastContainer } from "react-toastify";
import { useNavigate } from "react-router-dom";
import AddButton from "../Utilities/AddButton";
import { useTranslation } from "react-i18next";
import Cookies from "js-cookie";
import { useParams } from "react-router-dom";
import formatDate2 from "../../Utilities/formDate2";
import SearchButton from "../Utilities/SearchButton";

const AdminAllReserveOrder = () => {
  const { id } = useParams();
  const { t } = useTranslation();
  const navigate = useNavigate();

  const [reserveOrders, clickHandler, search] = AdminAllReservOrderHook(id);
  //console.log(id);

  let status = '';


  switch (reserveOrders.status) {
    case 'pending':
      status = t("pending");
      break;

    case 'closed':
      status = t("closed");
      break;

    case 'preparing':
      status = t("preparing");
      break;

    case 'received':
      status = t("closed");
      break;

    default:

  }

  let columns = [
    {
      name: t("order number"),
      selector: "orderNumber",
      sortable: true,
    },
    {
      name: t("orderdate"),
      selector: (row) => formatDate2(row["orderDate"]),
      sortable: true,
    },
    {
      name: t("ordertime"),
      selector: (row) => row["orderTime"],
      sortable: true,
    },

    {
      name: t("ordersPersonCount"),
      selector: (row) => row["ordersPersonCount"],
      sortable: true,
    },
    {
      name: t("vat"),
      selector: (row) => row["vat"],
      sortable: true,
    },
    {
      name: t("price"),
      selector: (row) => row["price"],
      sortable: true,
    },
    {
      name: t("type"),
      selector: (row) => row["type"] == 1 ? t("table") : t("party"),
      sortable: true,
    },
    {
      name: t("status"),
      selector: (row) => t(row["status"]),
      sortable: true,
    },
    {
      name: t("details"),
      cell: (row) => (
        <Button
          variant="secondary"
          onClick={() => clickHandler(row._id)}
          id={row._id}
        >
          {t("details")}
          <i className="fa fa-pencil-square-o mx-1" />
        </Button>
      ),
      ignoreRowClick: true,
      allowOverflow: true,
      button: true,
    },

  ];

  const tableData = {
    columns,
    data: reserveOrders,
  };


  return (
    <div>
      <title>{`${t("reserv")}`} </title>

      <div className="d-flex justify-content-between">
        <div className="admin-content-text">{t("reserve Managment")} </div>

        <SearchButton search={search} />


      </div>



      <DataTableExtensions {...tableData} filterPlaceholder={t("search here")}>

        <DataTable
          noHeader
          defaultSortAsc={false}
          pagination
          highlightOnHover
        />
      </DataTableExtensions>
      <ToastContainer />
    </div>
  )
}

export default AdminAllReserveOrder;

import React from "react";
import { Button } from "react-bootstrap";
import DataTable from "react-data-table-component";
import DataTableExtensions from "react-data-table-component-extensions";
import "react-data-table-component-extensions/dist/index.css";
import { useTranslation } from "react-i18next";
import { useNavigate, useParams } from "react-router-dom";
import { ToastContainer } from "react-toastify";
import AddButton from "../Utilities/AddButton";
import AdminAllMenusHook from "../../hook/admin/AdminAllMenusHook";
const AdminAllMenus = () => {

    const { t } = useTranslation();
    const {id} = useParams()
  const navigate = useNavigate();
  const [menusCategoryResponse, clickHandler, handleShowFun] = AdminAllMenusHook(id)


  console.log('menusCategoryResponse')
  console.log(menusCategoryResponse)



    let columns = [
        {
            name: t("image"),
            cell: (row) => (
              // eslint-disable-next-line jsx-a11y/alt-text
              <img
                src={row.image}
                width="80px"
                height="80px"
                style={{
                  padding: "2px",
                  justifyContent: "center",
                  alignItems: "center",
                  objectFit: "contain",
                }}
              />
            ),
          }, 
        {
          name: t("nameAr"),
          selector: "nameAr",
          sortable: true,
        },
        {
          name: t("nameEn"),
          selector: "nameEn",
          sortable: true,
        },
        {
            name: t("price"),
            selector: "price",
            sortable: true,
          },
          {
            name: t("Old Price"),
            selector: "oldPrice",
            sortable: true,
          },
  
          
    
        {
          name: t("modify"),
          cell: (row) => (
            <Button
              variant="secondary"
              onClick={() => clickHandler(row._id)}
              id={row._id}
            >
              {t("Modify")}
              <i className="fa fa-pencil-square-o mx-1" />
            </Button>
          ),
          ignoreRowClick: true,
          allowOverflow: true,
          button: true,
        },
        {
          name: t("state"),
          cell: (row) =>
            row.status === true ? (
              <Button
                variant="danger"
                onClick={() => {
                  handleShowFun(row._id, false);
                }}
                id={row._id}
              >
                {t("block")}
                <i className="fa fa-lock mx-1" />
              </Button>
            ) : (
              <Button
                variant="success"
                onClick={() => {
                  handleShowFun(row._id, true);
                }}
                id={row._id}
              >
                {t("active")}
                <i className="fa fa-unlock mx-1" />
              </Button>
            ),
          ignoreRowClick: true,
          allowOverflow: true,
          button: true,
        },
      ];
    
      const tableData = {
        columns,
        data: menusCategoryResponse,
      };

      console.log('menusCategoryResponse')
      console.log(menusCategoryResponse)

    return (
        <div>
        <title>{`${t("food menu")}`} </title>
  
        <div className="d-flex justify-content-between">
          <div className="admin-content-text">{t("List management")} </div>
          <AddButton title={t("Add Menu")} navigateTo={`/admin/addMenu/${id}`} />
        </div>
  
        <DataTableExtensions {...tableData} filterPlaceholder={t("search here")}>
          <DataTable
            noHeader
            defaultSortAsc={false}
            pagination
            highlightOnHover
          />
        </DataTableExtensions>
        <ToastContainer />
      </div>
    )
}

export default AdminAllMenus
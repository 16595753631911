import React, { useEffect, useState } from "react";
import { Button, Container, Modal, Row, Spinner } from "react-bootstrap";
import DataTable from "react-data-table-component";
import ViewAllRestarantsHook from "../../hook/admin/ViewAllRestarantsHook";
import DataTableExtensions from "react-data-table-component-extensions";
import "react-data-table-component-extensions/dist/index.css";
import { ToastContainer } from "react-toastify";
import { useNavigate } from "react-router-dom";
import AddButton from "../Utilities/AddButton";
import { useTranslation } from "react-i18next";
import Cookies from "js-cookie";

const AdminAllRestaurants = () => {
  const { t } = useTranslation();
  const navigate = useNavigate();

  const [resturants, clickHandler, handleShowFun] = ViewAllRestarantsHook();

  let columns = [
    {
      name: t("image"),
      cell: (row) => (
        // eslint-disable-next-line jsx-a11y/alt-text
        <img
          src={row.logo}
          width="70px"
          height="70px"
          style={{
            padding: "2px",
            justifyContent: "center",
            alignItems: "center",
            objectFit: "contain",
          }}
        />
      ),
    },  
    {
      name: t("nameAr"),
      selector: (row) => row.nameAr,
      sortable: true,
    },
    {
      name: t("nameEn"),
      selector: "nameEn",
      sortable: true,
    },
    {
      name: t("tableNumberPerTime"),
      selector: "tableNumberPerTime",
      sortable: true,
    },
    {
      name: t("mobile"),
      selector: "mobile",
      sortable: true,
    },
    {
      name: t("ratingsAverage"),
      selector: "ratingsAverage",
      sortable: true,
    },
    // {
    //   name: t("ratingsQuantity"),
    //   selector: "ratingsQuantity",
    //   sortable: true,
    // },
    {
      name: t("categories"),
      cell: (row) => (
        <div
          style={{ cursor: 'pointer' }}
          variant='secondary'
          id={row._id}
          onClick={() => navigate(`/admin/categories/${row._id}`)}
        >
          {t("clickhere")}
          <i className="fa fa-folder-open-o mx-1" />

        </div>
      ),
      ignoreRowClick: true,
      allowOverflow: true,
    },
    {
      name: t("orders"),
      cell: (row) => (
        <div
          style={{ cursor: 'pointer' }}
          variant='secondary'
          id={row._id}
          onClick={() => navigate(`/foodorders/resturant/${row._id}`)}
        >
          {t("clickhere")}
          <i className="fa fa-folder-open-o mx-1" />

        </div>
      ),
      ignoreRowClick: true,
      allowOverflow: true,
    },
    {
      name: t("time table"),
      cell: (row) => (
        <div
          style={{ cursor: 'pointer' }}
          variant='secondary'
          id={row._id}
          onClick={() => navigate(`/admin/timetable/${row._id}`)}
        >
          {t("clickhere")}
          <i className="fa fa-folder-open-o mx-1" />

        </div>
      ),
      ignoreRowClick: true,
      allowOverflow: true,
    },

    {
      name: t("reserv"),
      cell: (row) => (
        <div
          style={{ cursor: 'pointer' }}
          variant='secondary'
          id={row._id}
          onClick={() => navigate(`/reservorders/resturant/${row._id}`)}
        >
          {t("clickhere")}
          <i className="fa fa-folder-open-o mx-1" />

        </div>
      ),
      ignoreRowClick: true,
      allowOverflow: true,
    },


    {
      name: t("modify"),
      cell: (row) => (
        <Button
          variant="secondary"
          onClick={() => clickHandler(row._id)}
          id={row._id}
        >
          {t("Modify")}
          <i className="fa fa-pencil-square-o mx-1" />
        </Button>
      ),
      ignoreRowClick: true,
      allowOverflow: true,
      button: true,
    },
    {
      name: t("state"),
      cell: (row) =>
        row.status == true ? (
          <Button
            variant="danger"
            onClick={() => {
              handleShowFun(row._id, false);
            }}
            id={row._id}
          >
            {t("block")}
            <i className="fa fa-lock mx-1" />
          </Button>
        ) : (
          <Button
            variant="success"
            onClick={() => {
              handleShowFun(row._id, true);
            }}
            id={row._id}
          >
            {t("active")}
            <i className="fa fa-unlock mx-1" />
          </Button>
        ),
      ignoreRowClick: true,
      allowOverflow: true,
      button: true,
    },
  ];

  const tableData = {
    columns,
    data: resturants,
  };

  return (
    <div>
      <title>{`${t("restaurants")}`} </title>

      <div className="d-flex justify-content-between">
        <div className="admin-content-text">{t("restaurantsManagment")} </div>
        <AddButton
          title={t("add restaurant")}
          navigateTo="/admin/addrestaurant"
        />
      </div>

      <DataTableExtensions {...tableData} filterPlaceholder={t("search here")}>
        <DataTable
          noHeader
          defaultSortAsc={false}
          pagination
          highlightOnHover
        />
      </DataTableExtensions>
      <ToastContainer />
    </div>
  );
};

export default AdminAllRestaurants;

import React, { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import notify from "../../hook/useNotification";
import { useNavigate } from "react-router-dom";
import { getAllTimeTablesForOneResturant ,updateTimeTable } from "../../redux/actions/timeTablesActions";
import { useTranslation } from "react-i18next";



const AdminResturantTimeTableHook =(id)=>{

   const navigate = useNavigate();
   const dispatch = useDispatch();
   const { t } = useTranslation();
   const[loading,setLoading]=useState(true);

   useEffect(()=>{
   dispatch(getAllTimeTablesForOneResturant(id));
   },[dispatch,id]);

   let times=useSelector((state)=>state.timeTablesReducer.getAllTimeTablesForOneResturant);

   




   const clickHandler= (res_id)=>{
    
    navigate(`/admin/editTimeTable/${res_id}`);
   }

   useEffect(() => {
    if (loading === false) {
      if (res) {
        notify("تم التعديل بنجاح", "success");
        setTimeout(() => {
          dispatch(getAllTimeTablesForOneResturant(id));
        }, 1000);
      } else {
        notify("فشل في عملية التعديل", "error");
      }
    }
  }, [loading]);

  const handleShowFun = async (id, status) => {
   // console.log('done')
    setLoading(true);
    await dispatch(

        updateTimeTable(id, {
        status: status,
      })
    );
    setLoading(false);
  };
  const res = useSelector((state) => state.timeTablesReducer.updateTimeTable);
   return[
    times,
    clickHandler,
    handleShowFun
   ]


}
export default AdminResturantTimeTableHook;
import { GET_ALL_COUPONS , UPDATE_ONE_COUPON ,ADD_NEW_COUPON ,GET_ONE_COUPON_BY_ID } from "../type";
import { useGetDataToken } from "../../hooks/useGetData";
import { useUpdateData } from "../../hooks/useUpdateData";
import { useInsertData } from "../../hooks/useInsertData";

//get all coupons
export const getAllCoupons = () => async(dispatch) => {

    try {
        const response = await useGetDataToken(`/api/v1/coupon`);
    dispatch({
      type: GET_ALL_COUPONS,
      payload: response,
      
    });
    } catch (error) {
        dispatch({
            type: GET_ALL_COUPONS,
            payload: error,
          });
    }
}

//update one coupon
export const updateCoupon = (id, body) => async (dispatch) => {
    try {
      const response = await useUpdateData(`/api/v1/coupon/${id}`, body);
  
      dispatch({
        type: UPDATE_ONE_COUPON,
        payload: response,  
      });
    } catch (error) {
      dispatch({
        type: UPDATE_ONE_COUPON,
        payload: error,
      });
    }
  };

  //add cuopon
  export const addCoupon= (body)=>async (dispatch) =>{
    try {
      const response= await useInsertData(`/api/v1/coupon`,body);
      dispatch({
        type:ADD_NEW_COUPON,
        payload: response,
        loading:true
  
      })
    } catch (error) {
      dispatch({
        type:ADD_NEW_COUPON,
        payload:error
      })
    }
   };

   //get one coupon by id
   export const getOneCoupon=(id ,body) =>async(dispatch)=>{
         try {
            const response= await useGetDataToken(`/api/v1/coupon/${id}`, body);
            dispatch({
                type:GET_ONE_COUPON_BY_ID,
                payload:response
            })
         } catch (error) {
            dispatch({
                type: GET_ONE_COUPON_BY_ID,
                payload: error,
              });
         }
   }



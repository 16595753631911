import {
    GET_ALL_RESERVES_BY_RESTAURANT_ID,GET_RESERVE_ORDER_DETAILS ,UPDATE_RESERVE_ORDER
} from "../type";

const inital = {
    getAllReservesByRestaurantId:[],
    getReserveOrderDetails:[],
    updateReserveOrder:[]

}

const reserveOrdersReducer = (state = inital, action) => {
    switch (action.type) {

        case GET_ALL_RESERVES_BY_RESTAURANT_ID:
            return{
                ...state,
                getAllReservesByRestaurantId:action.payload
            }
        case GET_RESERVE_ORDER_DETAILS:
            return{
                ...state,
                getReserveOrderDetails:action.payload
            }
        case UPDATE_RESERVE_ORDER:
            return{
                ...state,
                updateReserveOrder:action.payload
            }

        default:
            return state
    }
}

export default reserveOrdersReducer;
import React, { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import notify from "../../hook/useNotification";
import { useNavigate } from "react-router-dom";
import { getOneCoupon,updateCoupon } from "../../redux/actions/couponsAction";
import avatar from "../../../src/Images/adminIcon.png";

const AdminEditCouponHook =(id)=>{
    const navigate = useNavigate();
    const dispatch = useDispatch();
    const [coponCode, setCoponCode] = useState("");
    const [startDate, setStartDate] = useState("");
    const [endDate, setEndDate] = useState("");
    const [value, setValue] = useState("");
    const [type, setType] = useState("");
    const [loading, setLoading] = useState(true);

    useEffect(()=>{
        dispatch(getOneCoupon(id))
    },[dispatch,id]);

    const oneCoupon=useSelector((state)=>state.couponsReducers.getOneCoupon);

    useEffect(()=>{
        if(oneCoupon)
        {
            setCoponCode(oneCoupon.coponCode);
            setStartDate(oneCoupon.startDate);
            setEndDate(oneCoupon.endDate);
            setValue(oneCoupon.value);
            setType(oneCoupon.type);
        }
    },[oneCoupon]);

    //set copon code
  const onChangeCoponCode = (e) => {
    e.persist();
    setCoponCode(e.target.value);
  };

  //set start date
  const onChangeStartDate = (e) => {
    e.persist();
    setStartDate(e.target.value);
  };

  //set end date
  const onChangeEndDate = (e) => {
    e.persist();
    setEndDate(e.target.value);
  };

  //set value
  const onChangeValue = (e) => {
    e.persist();
    setValue(e.target.value);
  };

  //set type
  const onChangeType = (e) => {
    e.persist();
    setType(e.target.value);
  };
    


    //console.log(oneCoupon);
    const onSubmit= async()=>{
           
        if (coponCode == "") {
            notify("من فضلك ادخل كوبون الخصم", "error");
            return;
          }
          if (startDate == "") {
            notify("من فضلك ادخل تاريخ بداية الكوبون", "error");
            return;
          }
          if (endDate == "") {
            notify("من فضلك ادخل تاريخ نهاية الكوبون", "error");
            return;
          }
          if (value == "") {
            notify("من فضلك ادخل قيمة الكوبون", "error");
            return;
          }
          if (type == "") {
            notify("من فضلك ادخل نوع الكوبون", "error");
            return;
          }

          await dispatch(
            updateCoupon(id,{
                coponCode:coponCode,
                startDate:startDate,
                endDate:endDate,
                value:value,
                type:type                  
            })
          )
          setLoading(false);
    }

    const res=useSelector(state =>state.couponsReducers.updateCoupon)

    useEffect(() => {
        if (loading === false) {
          if (res._id) {
            notify("تم التعديل بنجاح", "success");
            setTimeout(() => {
              navigate("/admin/coupons");
            }, 1500);
          } else {
            notify("فشل في عملية التعديل", "error");
          }
        }
      }, [loading]);

    return[
        coponCode,
        startDate,
        endDate,
        value,
        type,
        onSubmit,
        onChangeCoponCode,
        onChangeStartDate,
        onChangeEndDate,
        onChangeValue,
        onChangeType      
    ]

}

export default AdminEditCouponHook;
import React from "react";
import { Col, Row, Spinner } from "react-bootstrap";
import { ToastContainer } from "react-toastify";
import AdminAddRestaurantTypeHook from "../../hook/admin/AdminAddRestaurantTypeHook";
import { useTranslation } from "react-i18next";
import Cookies from "js-cookie";
import { useSelector } from "react-redux";

const AdminAddRestaurantType = () => {
  const { t } = useTranslation();
  const [
    resturantTypeAr,
    resturantTypeEn,
    onChangeresturantTypeAr,
    onChangeresturantTypeEn,
    onSubmit,
  ] = AdminAddRestaurantTypeHook();
  const color = useSelector((state) => state.settingsReducers.oneSettings.primaryColor);

  return (
    <div>
      <title>{`${t("add restaurantTypes")}`} </title>
      <Row className="justify-content-start">
        <div className="admin-content-text pb-4">{t("add restaurantTypes")}</div>

        <Col sm="8">
          <label htmlFor="firstname" className="d-block mt-2 ">
            {" "}
            {t("resturantTypeAr")}{" "}
          </label>
          <input
            id="firstname"
            value={resturantTypeAr}
            onChange={onChangeresturantTypeAr}
            type="text"
            className="input-form d-block mt-2 px-3"
            placeholder={t("resturantTypeAr")}
          />
          <label htmlFor="firstname" className="d-block mt-2 ">
            {" "}
            {t("resturantTypeEn")}{" "}
          </label>
          <input
            id="firstname"
            value={resturantTypeEn}
            onChange={onChangeresturantTypeEn}
            type="text"
            className="input-form d-block mt-2 px-3"
            placeholder={t("resturantTypeEn")}
          />
        </Col>
      </Row>
      <Row>
        <Col sm="8" className="d-flex justify-content-end ">
          <button
            onClick={onSubmit}
            className="btn-save d-inline mt-2"
            style={{ backgroundColor: color }}
          >
            {t("add")}
          </button>
        </Col>
      </Row>

      <ToastContainer />
    </div>
  );
};

export default AdminAddRestaurantType;

export const GET_ALL_CATEGORY = "GET_ALL_CATEGORY";
export const CREATE_CATEGORY = "CREATE_CATEGORY";
export const GET_ERROR = "GET_ERROR";
export const GET_ALL_BRAND = "GET_ALL_BRAND";
export const CREATE_BRAND = "CREATE_BRAND";
export const CREATE_SUB_CATEGORY = "CREATE_SUB_CATEGORY";
export const GET_SUB_BY_CAT_ID = "GET_SUB_BY_CAT_ID";
export const CREATE_PRODUCT = "CREATE_PRODUCT";
export const GET_ALL_PRODUCT = "GET_ALL_PRODUCT";
export const GET_PRODUCT_DETAILS = "GET_PRODUCT_DETAILS";
export const GET_SIMILAR_PRODUCTS = "GET_SIMILAR_PRODUCTS";
export const DELETE_PRODUCT = "DELETE_PRODUCT";
export const UPDATE_PRODUCT = "UPDATE_PRODUCT";
export const CREATE_NEW_USER = "CREATE_NEW_USER";
export const LOGIN_USER = "LOGIN_USER";
export const CREATE_REVIEW = "CREATE_REVIEW";
export const REVIEWS_BY_PRODUCT_ID = "REVIEWS_BY_PRODUCT_ID";
export const DELETE_REVIEW = "DELETE_REVIEW";
export const UPDATE_REVIEW = "UPDATE_REVIEW";
export const ADD_TO_FAV = "ADD_TO_FAV";
export const REMOVE_FROM_FAV = "REMOVE_FROM_FAV";
export const USER_FAVORITE = "USER_FAVORITE";
export const ADD_COUPON = "ADD_COUPON";
export const GET_ALL_COUPON = "GET_ALL_COUPON";
export const DELETE_COUPON = "DELETE_COUPON";
export const GET_ONE_COUPON = "GET_ONE_COUPON";
export const EDIT_COUPON = "EDIT_COUPON";
export const USER_ADD_ADDRESS = "USER_ADD_ADDRESS";
export const GET_USER_ADDRESS_BY_ID = "GET_USER_ADDRESS_BY_ID";
export const DELETE_USER_ADDRESS = "DELETE_USER_ADDRESS";
export const USER_EDIT_ADDRESS = "USER_EDIT_ADDRESS";
export const GET_ONE_ADDRESS = "GET_ONE_ADDRESS";
export const ALL_USERS = "ALL_USERS";
export const UPDATE_USER_PROFILE = "UPDATE_USER_PROFILE";
export const UPDATE_USER_PASSWORD = "UPDATE_USER_PASSWORD";
export const ADD_TO_CART = "ADD_TO_CART";
export const GET_USER_CART_ITEMS = "GET_USER_CART_ITEMS";
export const DELETE_ALL_CART_ITEMS = "DELETE_ALL_CART_ITEMS";
export const REMOVE_ONE_ITEM = "REMOVE_ONE_ITEM";
export const UPDATE_ITEM_CART = "UPDATE_ITEM_CART";
export const APPLY_COUPON = "APPLY_COUPON";
export const CREATE_ORDER = "CREATE_ORDER";
export const GET_ALL_PAYMENT_METHOD = "GET_ALL_PAYMENT_METHOD";
export const GET_ALL_ORDERS_BY_USER = "GET_ALL_ORDERS_BY_USER";
export const GET_ALL_ORDERS_BY_ADMIN = "GET_ALL_ORDERS_BY_ADMIN";
export const GET_ONE_ORDER = "GET_ONE_ORDER";
export const UPDATA_ORDER_STATUS = "UPDATA_ORDER_STATUS";
export const CREATE_NEW_SLIDER = "CREATE_NEW_SLIDER";
export const GET_ALL_SLIDER = "GET_ALL_SLIDER";
export const GET_ONE_USER = "GET_ONE_USER";
export const UPDATE_USER = "UPDATE_USER";
export const GET_SETTINGS = "GET_SETTINGS";
export const GET_ALL_SHIPPING_METHODS = "GET_ALL_SHIPPING_METHODS";
export const EDIT_SETTINGS = "EDIT_SETTINGS";
export const GET_POLICY_TERMS_ABOUT = "GET_POLICY_TERMS_ABOUT";
export const UPDATE_TERMS_ABOUT_POLICY = "UPDATE_TERMS_ABOUT_POLICY";
export const GET_ALL_ORDER_STATUS = "GET_ALL_ORDER_STATUS";
export const ADMIN_ALL_CARTS = "ADMIN_ALL_CARTS";
export const SEND_EMAIL = "SEND_EMAIL";
export const GET_ALL_PRODUCT_CATEGORY = "GET_ALL_PRODUCT_CATEGORY";
export const GET_ALL_PRODUCT_SUB_CATEGORY = "GET_ALL_PRODUCT_SUB_CATEGORY";
export const GET_ALL_PRODUCT_BRAND = "GET_ALL_PRODUCT_BRAND";
export const BRAND_BY_ID = "BRAND_BY_ID";
export const GET_CAT_BY_ID = "GET_CAT_BY_ID";
export const GET_SALES_REPORT = "GET_SALES_REPORT";
export const ADD_PAYMENT_METHOD = "ADD_PAYMENT_METHOD";
export const GET_ONE_PAYMENT_METHOD = "GET_ONE_PAYMENT_METHOD";
export const EDIT_PAYMENT_METHOD = "EDIT_PAYMENT_METHOD";
export const ADD_VENDOR = "ADD_VENDOR";
export const GET_PRODUCTS_BY_VENDOR_ID = "GET_PRODUCTS_BY_VENDOR_ID";
export const GET_ORDERS_BY_VENDOR_ID = "GET_ORDERS_BY_VENDOR_ID";
export const ADD_USER_BY_ADMIN = "ADD_USER_BY_ADMIN";
export const GET_WEBSITE_DATA = "GET_WEBSITE_DATA";
export const GET_ALL_CATEGORIES = "GET_ALL_CATEGORIES";
export const DELETE_CATEGORY = "DELETE_CATEGORY";
export const DELETE_SLIDER = "DELETE_SLIDER";
export const UPDATE_SLIDER = "UPDATE_SLIDER";
export const GET_ONE_SLIDER = "GET_ONE_SLIDER";
export const UPDATE_CATEGORY = "UPDATE_CATEGORY";
export const UPDATE_BRAND = "UPDATE_BRAND";
export const PRINT_INVOICE_ORDER = "PRINT_INVOICE_ORDER";
export const ADD_SHIPPING_METHODS = "ADD_SHIPPING_METHODS";
export const GET_ONE_SHIPPING_METHODS = "GET_ONE_SHIPPING_METHODS";
export const EDIT_SHIPPING_METHODS = "EDIT_SHIPPING_METHODS";
export const UPDATE_PRODUCT_STATUS = "UPDATE_PRODUCT_STATUS";
export const CREATE_NEW_ADS = "CREATE_NEW_ADS";
export const GET_ALL_ADS = "GET_ALL_ADS";
export const DELETE_ADS = "DELETE_ADS";
export const UPDATE_ADS = "UPDATE_ADS";
export const GET_ONE_ADS = "GET_ONE_ADS";
export const GET_ALL_ADS_WEB_SITE = "GET_ALL_ADS_WEB_SITE";
export const GET_ONE_USER_TOKEN = "GET_ONE_USER_TOKEN";
export const GET_VENDOR_BY_USERNAME = "GET_VENDOR_BY_USERNAME";
export const ADD_TICKET = "ADD_TICKET";
export const GET_ALL_TICKETS = 'GET_ALL_TICKET';
export const UPDATE_TICKET = 'UPDATE_TICKET';
export const ADD_ORDER_HISTORY = "ADD_ORDER_HISTORY";
export const GET_ALL_ORDER_HISTORY = "GET_ALL_ORDER_HISTORY"
export const GET_ORDERS_BY_DATE = "GET_ORDERS_BY_DATE"
export const GET_ALL_CATEGORIES_WITHOUT_BLOCHED_GATEGORY = "GET_ALL_CATEGORIES_WITHOUT_BLOCHED_GATEGORY"
export const GET_ALL_PRODUCT_ADMIN = "GET_ALL_PRODUCT_ADMIN"
export const PRINT_INVOICE_SHIPPMENTS = "PRINT_INVOICE_SHIPPMENTS"
export const CREATE_SHIPPMENTS = "CREATE_SHIPPMENTS"
export const GET_ONE_SHIPPING_METHODS_ID = "GET_ONE_SHIPPING_METHODS_ID"
export const SELECTED_LOGO = "SELECTED_LOGO";
export const SELECTED_PRIMARYCOLOR = "SELECTED_PRIMARYCOLOR";

export const ALL_COUNTRIES = "ALL_COUNTRIES";
export const UPDATE_COUNTRY = "UPDATE_COUNTRY";
export const ADD_COUNTRY = "ADD_COUNTRY";
export const GET_ONE_COUNTRY = "GET_ONE_COUNTRY";
export const GET_ALL_CITIES_IN_COUNTRY = "GET_ALL_CITIES_IN_COUNTRY";
export const UPDATE_CITY = "UPDATE_CITY";
export const ADD_CITY = "ADD_CITY";
export const GET_ONE_CITY = "GET_ONE_CITY";

export const GET_ALL_COUPONS = "GET_ALL_COUPONS";
export const UPDATE_ONE_COUPON = "UPDATE_ONE_COUPON";
export const ADD_NEW_COUPON = "ADD_COUPON";
export const GET_ONE_COUPON_BY_ID = "GET_ONE_COUPON_BY_ID";

export const GET_ALL_RESTAURANT_TYPES = "GET_ALL_RESTAURANT_TYPES";
export const UPDATE_RESTAURANT_TYPES = "UPDATE_ONE_RESTAURANT_TYPES";
export const ADD_RESTAURANT_TYPE = "ADD_RESTAURANT_TYPE";
export const GET_ONE_RESTAURANT_TYPE = "GET_ONE_RESTAURANT_TYPE";

export const GET_ALL_RESTAURANT = "GET_ALL_RESTAURANT";
export const UPDATE_RESTAURANT = "UPDATE_RESTAURANT";
export const ADD_RESTAURANT = "ADD_RESTAURANT";
export const GET_ONE_RESTAURANT = "GET_ONE_RESTAURANT";
export const GET_ALL_RESTIRANT_CATEGORY = "GET_ALL_RESTIRANT_CATEGORY";
export const UPDATE_CATEGORY_RESTURANT = "UPDATE_CATEGORY_RESTURANT";
export const ADD_CATEGORY_RESURANT = "ADD_CATEGORY_RESURANT";
export const GET_ONE_CATEGORY = "GET_ONE_CATEGORY";
export const GET_ORDERS_BY_RESTAURANT_ID = "GET_ORDERS_BY_RESTAURANT_ID";
export const UPDATE_ORDER = "UPDATE_ORDER";
export const GET_ALL_MENUS_CATEGORIES = "GET_ALL_MENUS_CATEGORIES";
export const GET_ONE_MENU = "GET_ONE_MENU";
export const UPDATE_MENU = "UPDATE_MENU";
export const ADD_MENU = "ADD_MENU";
export const UPDATE_SETTINGS = "UPDATE_SETTINGS";
export const GET_ONE_SETTINGS = "GET_ONE_SETTINGS";
export const GET_ALL_RESERVES_BY_RESTAURANT_ID="GET_ALL_RESERVES_BY_RESTAURANT_ID";
export const GET_RESERVE_ORDER_DETAILS="GET_RESERVE_ORDER_DETAILS";
export const UPDATE_RESERVE_ORDER="UPDATE_RESERVE_ORDER";
export const GET_TIME_TABLES_FOR_ONE_RESTURANT="GET_TIME_TABLES_FOR_ONE_RESTURANT";
export const UPDATE_TIME_TABLE="UPDATE_TIME_TABLE";
export const ADD_TIME_TABLE="ADD_TIME_TABLE";
export const GET_ONE_TIME_TABLE="GET_ONE_TIME_TABLE";




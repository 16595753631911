import React, { useState, useEffect } from "react";
import notify from "../useNotification";
import { addCountry } from "../../redux/actions/countriesActions";
import { useDispatch, useSelector } from "react-redux";
import { useNavigate } from "react-router-dom";
import avatar from "../../../src/Images/adminIcon.png";

const AdminAddCountryHook = () => {
  const navigate = useNavigate();
  const dispatch = useDispatch();

  const [countryAr, setCountryAr] = useState("");
  const [countryEn, setCountryEn] = useState("");
  const [countryCode, setCountryCode] = useState("");
  const [CountryTellCode, setCountryTellCode] = useState("");
  const [img, setImg] = useState(avatar);
  const [selectedFile, setSelectedFile] = useState(null);
  const [loading, setLoading] = useState(true);

  //set country ar state
  const onChangeCountryAr = (e) => {
    e.persist();
    setCountryAr(e.target.value);
  };

  //set country en state
  const onChangeCountryEn = (e) => {
    e.persist();
    setCountryEn(e.target.value);
  };

  //set country code state
  const onChangeCountryCode = (e) => {
    e.persist();
    setCountryCode(e.target.value);
  };

  //set country tell code state
  const onChangeCountryTellCode = (e) => {
    e.persist();
    setCountryTellCode(e.target.value);
  };

  const onSubmit = async () => {
    if(countryAr=='')
    {
        notify("من فضلك ادخل اسم الدولة بالعربية", "error");
        return;
    }

    if(countryEn=='')
    {
        notify("من فضلك ادخل اسم الدولة بالانجليزية", "error");
        return;
    }

    if(countryCode=='')
    {
        notify("من فضلك كود الدولة", "error");
        return;
    }

    if(CountryTellCode=='')
    {
        notify("من فضلك كود الاتصال", "error");
        return;
    }
   
    await dispatch(addCountry({
        countryAr:countryAr,
        countryEn:countryEn,
        countryCode:countryCode,
        CountryTellCode:CountryTellCode
      }));

      setLoading(false)
  };

  const response = useSelector((state) => state.countriesReducers.addCountry);
  //console.log(response)
  useEffect(() => {

    if (loading === false) {
      if (response) {
      //  console.log('done')
        setCountryAr("");
        setCountryEn("");
        setCountryCode("");
        setCountryTellCode("");
        notify("تم إضافة المدينة بنجاح", "success");
        setTimeout(() => {
          navigate("/admin/countries");
        }, 1500);
      }
      else{
        notify("فشل في عملية الاضافة", "error");
      }
    }
  
  }, [loading]);

  return [
    countryAr,
    countryEn,
    countryCode,
    CountryTellCode,
    img,
    selectedFile,
    onSubmit,

    onChangeCountryAr,
    onChangeCountryEn,
    onChangeCountryCode,
    onChangeCountryTellCode,
  ];
};

export default AdminAddCountryHook;

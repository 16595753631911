import { SELECTED_LOGO, SELECTED_PRIMARYCOLOR } from "../type";

const utilitiesReducer = (state = { logo: "", color: "" }, action) => {
  if (action.type === SELECTED_LOGO) {
    return { ...state, logo: action.payload };
  }
  if (action.type === SELECTED_PRIMARYCOLOR) {
    return { ...state, color: action.payload };
  }
  return state;
};

export default utilitiesReducer;

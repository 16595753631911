import {
    GET_ALL_COUPONS,UPDATE_ONE_COUPON,ADD_NEW_COUPON,GET_ONE_COUPON_BY_ID
  } from "../type";
  
  const inital = {
    getAllCoupons:[],
    updateCoupon:[],
    addCoupon:[],
    getOneCoupon:[]
  }

  const couponsReducers = (state = inital, action) => {
    switch (action.type) {
        case GET_ALL_COUPONS:
            return{
                ...state,
                getAllCoupons: action.payload
            }
        case UPDATE_ONE_COUPON:
            return{
                ...state,
                updateCoupon:action.payload
            }
            case ADD_NEW_COUPON:
                return{
                    ...state,
                    addCoupon:action.payload
                }
            case  GET_ONE_COUPON_BY_ID:
                return{
                    ...state,
                    getOneCoupon:action.payload
                }

        default:
            return state;
    }
  }

  export default couponsReducers;
import React from "react";
import { Col, Container, Row } from "react-bootstrap";
import AdminEditRestaurant from "../../Components/Admin/AdminEditRestaurant";
import AdminEditRestaurantInfo from "../../Components/Admin/AdminEditRestaurantInfo";
import SideBar from "../../Components/Admin/SideBar";

const AdminEditRestaurantPage = () => {

  const user=JSON.parse(localStorage.getItem("user"));
  const rule=user.type;
  const restaurant_id=user.resturant;
  return (
    <div className="d-flex justify-content-start">

      <div>
        <SideBar />
      </div>
      <div className="p-4" style={{ width: '100%' }}>
      {
        rule === 'admin'?<AdminEditRestaurant />:<AdminEditRestaurantInfo />
      }
      
      </div>
    </div>
  );
};

export default AdminEditRestaurantPage;
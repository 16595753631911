import React, { useState, useEffect } from "react";
import notify from "../useNotification";
import { addRestaurant } from "../../redux/actions/restaurantsActions";
import { getAllCountries } from "../../redux/actions/countriesActions";
import { getAllCitiesByCountryId } from "../../redux/actions/citiesActions";
import { getAllRestaurantTypes } from "../../redux/actions/restaurantTypesActions";
import { useDispatch, useSelector } from "react-redux";
import { useNavigate } from "react-router-dom";
import avatar from "../../../src/Images/adminIcon.png";

const AdminAddRestaurantHook = () => {
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const [loading, setLoading] = useState(true);
  const [nameAr, setNameAr] = useState("");
  const [nameEn, setNameEn] = useState("");
  const [address, setAdress] = useState("");
  const [mobile, setMobile] = useState("");
  const [descriptionAr, setDescriptionAr] = useState("");
  const [descriptionEn, setDescriptionEn] = useState("");
  const [takeOrderCost, setTakeOrderCost] = useState("");
  const [tableReservedCost, setTableReservedCost] = useState("");
  const [tableNumberPerTime, setTableNumberPerTime] = useState("");
  const [resturantType, setResturantType] = useState("");
  const [country, setCountry] = useState("");
  const [city, setCity] = useState("");
  const [img, setImg] = useState(avatar);
  const [selectedFile, setSelectedFile] = useState(null);
  const [sliderImg, setSliderImg] = useState(avatar);
  const [selectedFileSliderImg, setSelectedFileSliderImg] = useState(null);
  //for info list
  const [tablekeysValues, settablekeysValues] = useState([]);
  const [key, setKey] = useState("infoDesc");
  const [secondkey, setSecondKey] = useState("isAvailable");
  const [value, setValue] = useState("");
  const [show, setShow] = useState(false);
  const handleShow = () => setShow(true);

  const onChangeKey = (e) => {
    e.persist();
    console.log(e.target.value);
    setKey(e.target.value);
  };
  const onChangeValue = (e) => {
    e.persist();
    console.log(e.target.value);
    setValue(e.target.value);
  };

  const clickHandler = () => {
    settablekeysValues([
      ...tablekeysValues,
      { key: key, value: value, count: tablekeysValues.length + 1 ,
        key2:secondkey , value2:'true' , count2: tablekeysValues.length + 2}
    ]);

    // setKey("");
    setValue("");
    //console.log(tablekeysValues);
  };

  const deleteElement = (count) => {
    //console.log(count)
    settablekeysValues(tablekeysValues.filter((el) => el.count !== count));
    settablekeysValues(tablekeysValues.filter((el) => el.count+1 !== count+1));
  };

  //set description ar
  const onChangedescriptionAr = (e) => {
    setDescriptionAr(e.target.value);
  };

  //set description en
  const onChangedescriptionEn = (e) => {
    setDescriptionEn(e.target.value);
  };

  //logo pic
  const onImageChange = (event) => {
    if (event.target.files && event.target.files[0])
      setImg(URL.createObjectURL(event.target.files[0]));
    setSelectedFile(event.target.files[0]);
  };

  //slider img
  const onSliderImgChange = (event) => {
    // console.log('done');
    if (event.target.files && event.target.files[0])
      setSliderImg(URL.createObjectURL(event.target.files[0]));
    setSelectedFileSliderImg(event.target.files[0]);
  };

  useEffect(() => {
    const get = async () => {
      await dispatch(getAllCountries());
    };
    get();
  }, [dispatch]);

  const allCountries = useSelector(
    (state) => state.countriesReducers.allCountries
  );

  useEffect(() => {
    const get = async () => {
      await dispatch(getAllRestaurantTypes());
    };
    get();
  }, [dispatch]);

  const restaurantTypes = useSelector(
    (state) => state.restaurantTypesReducers.getAllRestaurantTypes
  );

  //console.log(restaurantTypes);

  //set restauranttypes
  const onChangerestauranttypes = (e) => {
    // e.persist();
    setResturantType(e.target.value);
  };

  //set restaurant namear
  const onChangenameAr = (e) => {
    // e.persist();
    setNameAr(e.target.value);
  };

  //set restaurant name en
  const onChangenameEn = (e) => {
    //e.persist();
    setNameEn(e.target.value);
  };

  //set address
  const onChangeSetAddress = (e) => {
    //e.persist();
    setAdress(e.target.value);
  };

  //set mobile
  const onChangeSetMobile = (e) => {
    //e.persist();
    setMobile(e.target.value);
  };

  //set take order cost
  const onChangeSetTakeOrderCost = (e) => {
    //e.persist();
    setTakeOrderCost(e.target.value);
  };

  //set setTableReservedCost
  const onChangeSetTableReservedCost = (e) => {
    //e.persist();
    setTableReservedCost(e.target.value);
  };

  //set tableNumberPerTime
  const onChangeSetTableNumberPerTime = (e) => {
    //e.persist();
    setTableNumberPerTime(e.target.value);
  };

  //set country
  const onChangeSetCountry = async (e) => {
    //e.persist();
    if (e.target.value != "" && e.target.value != 0) {
      //console.log(country);
      await dispatch(getAllCitiesByCountryId(e.target.value));
    }
    setCountry(e.target.value);
  };

  const cities = useSelector(
    (state) => state.citiesReducers.allCitiesInCountry
  );
  //console.log(cities);

  //set city
  const onChangeSetCity = (e) => {
    //e.persist();
    //console.log(e.target.value);
    setCity(e.target.value);
  };

  const onSubmit = async () => {
    //console.log(tablekeysValues);
    if (nameAr == "") {
      notify("من فضلك ادخل اسم المطعم بالعربية", "error");
      return;
    }
    if (nameEn == "") {
      notify("من فضلك ادخل اسم المطعم بالانجليزية", "error");
      return;
    }
    if (address == "") {
      notify("من فضلك ادخل عنوان المطعم", "error");
      return;
    }
    if (mobile.length <= 10) {
      notify("من فضلك ادخل رقم جوال صحيح", "error");
      return;
    }
    if (takeOrderCost == "") {
      notify("من فضلك ادخل سعر حجز الاوردر", "error");
      return;
    }
    if (tableReservedCost == "") {
      notify("من فضلك ادخل سعر حجز الطاولة", "error");
      return;
    }
    if (tableNumberPerTime == "") {
      notify("من فضلك ادخل عدد الطاولات المتاحة كل ساعة", "error");
      return;
    }

    if (resturantType == "" && resturantType == 0) {
      notify("من فضلك اختر بلد", "error");
      return;
    }

    if (country == "" && country == 0) {
      notify("من فضلك اختر بلد", "error");
      return;
    }
    if (city == "" && city == 0) {
      notify("من فضلك ادخل اختر مدينة", "error");
      return;
    }
    if (selectedFile == "") {
      notify("من فضلك ارفع شعار المطعم", "error");
      return;
    }
    if (selectedFileSliderImg == "") {
      notify("من فضلك ارفع صورة معرض الصور", "error");
      return;
    }

    const formData = new FormData();
    formData.append("nameAr", nameAr);
    formData.append("nameEn", nameEn);
    formData.append("address", address);
    formData.append("mobile", mobile);
    formData.append("takeOrderCost", takeOrderCost);
    formData.append("tableReservedCost", tableReservedCost);

    formData.append("descriptionAr", descriptionAr);
    formData.append("descriptionEn", descriptionEn);

    formData.append("tableNumberPerTime", tableNumberPerTime);
    formData.append("country", country);
    formData.append("tableReservedCost", tableReservedCost);
    formData.append("resturantType", resturantType);

    formData.append("logo", selectedFile);
    formData.append("sliderImage", selectedFileSliderImg);
    formData.append("city", city);

   

    setLoading(true);
    await dispatch(addRestaurant(formData));
    setLoading(false);
  };

  const response = useSelector(
    (state) => state.restaurantReducer.addRestaurant
  );
  // console.log(loading);

  useEffect(() => {
    if (loading === false) {
      if (response) {
        console.log("done");
        notify("تم إضافة المطعم بنجاح", "success");
        setTimeout(() => {
          navigate("/admin/resturants");
        }, 1500);
      } else {
        notify("فشل في عملية الاضافة", "error");
      }
    }
  }, [loading]);

  return [
    nameAr,
    nameEn,
    address,
    mobile,
    takeOrderCost,
    tableReservedCost,
    tableNumberPerTime,
    onChangenameAr,
    onChangenameEn,
    onChangeSetAddress,
    onChangeSetMobile,
    onChangeSetTakeOrderCost,
    onChangeSetTableReservedCost,
    onChangeSetTableNumberPerTime,
    allCountries,
    cities,
    img,
    onChangeSetCountry,
    onChangeSetCity,
    onImageChange,
    restaurantTypes,
    onChangerestauranttypes,
    sliderImg,
    onSliderImgChange,

    descriptionAr,
    descriptionEn,
    onChangedescriptionAr,
    onChangedescriptionEn,

    key,
    onChangeKey,
    value,
    onChangeValue,
    clickHandler,
    deleteElement,
    show,
    handleShow,
    tablekeysValues,
    onSubmit,
  ];
};

export default AdminAddRestaurantHook;

import { BrowserRouter, Routes, Route } from "react-router-dom";
import LoginPage from "./Pages/Auth/LoginPage";
import AdminAllUsersPage from "./Pages/Admin/AdminAllUsersPage";
import ProtectedRouteHook from "./hook/auth/ProtectedRouteHook";
import ProtectedRoute from "./Components/Utilities/ProtectedRoute";
import AdminEditUserPage from "./Pages/Admin/AdminEditUserPage";
import AdminAddUserPage from "./Pages/Admin/AdminAddUserPage";
import { Suspense, useEffect, lazy } from "react";
import AdminProfilePage from "./Pages/Admin/AdminProfilePage";
import NavBarLogin from "./Components/Utilities/NavBarLogin";
import AdminSettingsPage from "./Pages/Admin/AdminSettingsPage";
import AdminAllCountriesPage from "./Pages/Admin/AdminAllCountriesPage";
import AdminAddCountryPage from "./Pages/Admin/AdminAddCountryPage";
import AdminEditCountryPage from "./Pages/Admin/AdminEditCountryPage";
import AdminAllCitiesPage from "./Pages/Admin/AdminAllCitiesPage";
import AdminAddCityPage from "./Pages/Admin/AdminAddCityPage";
import AdminEditCityPage from "./Pages/Admin/AdminEditCityPage";
import AdminAllCouponsPage from "./Pages/Admin/AdminAllCouponsPage";
import AdminAddCouponPage from "./Pages/Admin/AdminAddCoupon";
import AdminEditCouponPage from "./Pages/Admin/AdminEditCoupon";
import AdminAllRestaurantTypesPage from "./Pages/Admin/AdminAllRestaurantTypesPage";
import AdminAddRestaurantTypePage from "./Pages/Admin/AdminAddRestaurantTypePage";
import AdminEditRestaurantTypePage from "./Pages/Admin/AdminEditRestaurantTypePage";
import AdminAllRestaurantsPage from "./Pages/Admin/AdminAllRestaurantsPage";
import AdminAddRestaurantPage from "./Pages/Admin/AdminAddRestaurantPage";
import AdminEditRestaurantPage from "./Pages/Admin/AdminEditRestaurantPage";
import AdminAllCategoriesPage from "./Pages/Admin/AdminAllCategoriesPage";
import AdminAddCategoryPage from "./Pages/Admin/AdminAddCategoryPage";
import AdminEditCategoryPage from "./Pages/Admin/AdminEditCategoryPage";
import AdminAllRestaurantOrdersPage from "./Pages/Admin/AdminAllRestaurantOrdersPage";
import AdminOrderDetailsPage from "./Pages/Admin/AdminOrderDetailsPage";
import AdminAllMenusPage from "./Pages/Admin/AdminAllMenusPage";
import AdminAddMenuPage from "./Pages/Admin/AdminAddMenuPage";
import AdminEditMenuPage from "./Pages/Admin/AdminEditMenuPage";
import AdminAllReserveOrderPage from "./Pages/Admin/AdminAllReserveOrderPage";
import AdminReserveOrderDetails from "./Pages/Admin/AdminReserveOrderDetailsPage";
import AdminResturantTimeTablePage from "./Pages/Admin/AdminResturantTimeTablePage";
import AdminResturantAddTimeTablePage from "./Pages/Admin/AdminResturantAddTimeTablePage";
import AdminResturantEditTimeTablePage from "./Pages/Admin/AdminResturantEditTimeTablePage";

function App() {
  const [isAdmin, isResturantAdmin, userData] = ProtectedRouteHook();

  // const [webSiteData] = GetConfigDataHook()  

  return (
    <div className="font">
      <BrowserRouter>
        <Suspense fallback="Loading ..........">
          <NavBarLogin />

          <Routes>
            {/* All Routes For Any One  */}
            <Route path="/login" element={<LoginPage />} />
            {/* All Route When Admin logged */}
            <Route element={<ProtectedRoute auth={isAdmin} />}>
              <Route path="/admin/allusers" element={<AdminAllUsersPage />} />
              <Route
                path="/admin/edituser/:id"
                element={<AdminEditUserPage />}
              />
              <Route path="/admin/addUser" element={<AdminAddUserPage />} />
              <Route path="/admin/settings" element={<AdminSettingsPage />} />
              <Route index element={<AdminProfilePage />} />
              <Route
                path="/admin/countries"
                element={<AdminAllCountriesPage />}
              />
              <Route
                path="/admin/addcountry"
                element={<AdminAddCountryPage />}
              />
              <Route
                path="/admin/editcountry/:id"
                element={<AdminEditCountryPage />}
              />

              <Route
                path="/admin/cities/country/:id"
                element={<AdminAllCitiesPage />}
              />
              <Route path="/admin/addcity/:id" element={<AdminAddCityPage />} />
              <Route
                path="/admin/editcity/:id"
                element={<AdminEditCityPage />}
              />

              <Route path="/admin/coupons" element={<AdminAllCouponsPage />} />
              <Route path="/admin/addcoupon" element={<AdminAddCouponPage />} />
              <Route
                path="/admin/editcoupon/:id"
                element={<AdminEditCouponPage />}
              />
              <Route
                path="/admin/addcoupon"
                element={<AdminAddCouponPage />}
              />
              <Route
                path="/admin/editcoupon/:id"
                element={<AdminEditCouponPage />}
              />
              <Route
                path="/admin/resturantTypes"
                element={<AdminAllRestaurantTypesPage />}
              />
              <Route
                path="/admin/addRestaurantType"
                element={<AdminAddRestaurantTypePage />}
              />
              <Route
                path="/admin/editresturantType/:id"
                element={<AdminEditRestaurantTypePage />}
              />

              <Route
                path="/admin/resturants"
                element={<AdminAllRestaurantsPage />}
              />
              <Route
                path="/admin/addrestaurant"
                element={<AdminAddRestaurantPage />}
              />

              <Route
                path="/admin/editrestaurant/:id"
                element={<AdminEditRestaurantPage />}
              />

              <Route
                path="/admin/categories/:id"
                element={<AdminAllCategoriesPage />}
              />
              <Route
                path="/admin/addCategory/:id"
                element={<AdminAddCategoryPage />}
              />
              <Route
                path="/admin/editCategory/:id/:resturantId"
                element={<AdminEditCategoryPage />}
              />

              <Route
                path="/foodorders/resturant/:id"
                element={<AdminAllRestaurantOrdersPage />}
              />
              <Route
                path="/foodorders/details/:id"
                element={<AdminOrderDetailsPage />}
              />

              <Route
                path="/admin/categories/:id"
                element={<AdminAllCategoriesPage />}
              />
              <Route
                path="/admin/addCategory/:id"
                element={<AdminAddCategoryPage />}
              />
              <Route
                path="/admin/editCategory/:id/:resturantId"
                element={<AdminEditCategoryPage />}
              />

              <Route
                path="/foodorders/resturant/:id"
                element={<AdminAllRestaurantOrdersPage />}
              />
              <Route path="/admin/menus/:id" element={<AdminAllMenusPage />} />
              <Route path="/admin/addMenu/:id" element={<AdminAddMenuPage />} />
              <Route path="/admin/editMenu/:id" element={<AdminEditMenuPage />} />

              <Route
                path="/reservorders/resturant/:id"
                element={<AdminAllReserveOrderPage />}
              />

              <Route
                path="/reservorders/reverse/:id"
                element={<AdminReserveOrderDetails />}
              />

              <Route
              path="/admin/timetable/:id"
              element={<AdminResturantTimeTablePage />}
            />
            <Route
              path="/admin/addtimetable/:id"
              element={<AdminResturantAddTimeTablePage />}
            />
  
            <Route
              path="/admin/editTimeTable/:id"
              element={<AdminResturantEditTimeTablePage />}
            />
              
              
            </Route>
            {/* All Routes When Vendor Logged   AdminResturantEditTimeTablePage */}
            <Route element={<ProtectedRoute auth={isResturantAdmin} />}>
            <Route
            path="/admin/editrestaurant/:id"
            element={<AdminEditRestaurantPage />}
          />
          <Route
            path="/admin/timetable/:id"
            element={<AdminResturantTimeTablePage />}
          />
          <Route
            path="/admin/addtimetable/:id"
            element={<AdminResturantAddTimeTablePage />}
          />

          <Route
            path="/admin/editTimeTable/:id"
            element={<AdminResturantEditTimeTablePage />}
          />


            </Route>
          </Routes>
        </Suspense>
      </BrowserRouter>
    </div>
  );
}

export default App;

import React, { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import notify from "../useNotification";
import { useNavigate } from "react-router-dom";
import { updateRestaurantType,getOneRestaurantType } from "../../redux/actions/restaurantTypesActions";
import avatar from "../../../src/Images/adminIcon.png";

const AdminEditRestaurantTypeHook =(id)=>{
    const navigate = useNavigate();
    const dispatch = useDispatch();
    const[resturantTypeAr,setResturantTypeAr]=useState('');
    const[resturantTypeEn,setResturantTypeEn]=useState('');
    const [loading, setLoading] = useState(true);

    //set restaurant ar
  const onChangeresturantTypeAr=(e)=>{
    e.persist();
    setResturantTypeAr(e.target.value);
  }

  //set restaurant en
  const onChangeresturantTypeEn=(e)=>{
    e.persist();
    setResturantTypeEn(e.target.value)
  }
   
    useEffect(()=>{
      dispatch(getOneRestaurantType(id))
    },[dispatch,id]);

    const restaurantType=useSelector(state=>state.restaurantTypesReducers.getOneRestaurantType)
    //console.log(restaurantType);

    useEffect(()=>{
     if(restaurantType)
     {
        setResturantTypeAr(restaurantType.resturantTypeAr);
        setResturantTypeEn(restaurantType.resturantTypeEn);
     }
    },[restaurantType]);

    const onSubmit = async ()=>{
        if (resturantTypeAr == "") {
            notify("من فضلك ادخل تصنيف المطعم بالعربية", "error");
            return;
          }
        if (resturantTypeEn == "") {
            notify("من فضلك ادخل تصنيف المطعم بالانجليزية", "error");
            return;
          }
    
          await dispatch(updateRestaurantType(id,{
            resturantTypeAr:resturantTypeAr,
            resturantTypeEn:resturantTypeEn
          }))
           setLoading(false);
    }

    const res = useSelector(state=>state.restaurantTypesReducers.getOneRestaurantType);

    useEffect(() => {
        if (loading === false) {
          if (res._id) {
            notify("تم التعديل بنجاح", "success");
            setTimeout(() => {
              navigate("/admin/resturantTypes");
            }, 1500);
          } else {
            notify("فشل في عملية التعديل", "error");
          }
        }
      }, [loading]);




    return[
        resturantTypeAr,
        resturantTypeEn,
        onChangeresturantTypeAr,
        onChangeresturantTypeEn,
        onSubmit
    ]


}

export default AdminEditRestaurantTypeHook;
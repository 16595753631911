import React, { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { getAllUsers, updateUser } from "../../redux/actions/usersActions";
import { useNavigate } from "react-router-dom";
import notify from "../useNotification";

const ViewAllUsersHook = () => {
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const [loading, setLoading] = useState(true);
  let user = [];
  if (localStorage.getItem("user") != null)
    user = JSON.parse(localStorage.getItem("user"));


  const handleShowFun = async (id, status, email) => {
    setLoading(true);
    await dispatch(
      updateUser(id, {
        status: status,
        email: email
      })
    );
    setLoading(false);
  };
  const res = useSelector((state) => state.usersReducers.updateUser);

  useEffect(() => {
    if (loading === false) {
      if (res) {
        notify("تم التعديل بنجاح", "success");
        setTimeout(() => {
          dispatch(getAllUsers());
        }, 1000);
      } else {
        notify("فشل في عملية التعديل", "error");
      }
    }
  }, [loading]);






  useEffect(() => {
    dispatch(getAllUsers());
  }, []);
  const allUsers = useSelector((state) => state.usersReducers.allUser);
  let users = [];
  if (allUsers) users = allUsers;

  const clickHandler = (id) => {
    navigate(`/admin/edituser/${id}`);
  };

  return [users, clickHandler, handleShowFun];
};

export default ViewAllUsersHook;

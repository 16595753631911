import React, { useEffect, useRef, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import {
    getOneOrder, updateOrder
} from "../../redux/actions/ordersActions";
import notify from "../useNotification";


const GetOrderDetailsHook = (id) => {
    const dispatch = useDispatch();
    const [loading, setLoading] = useState(true);

    const [loadingOrderStatus, setLoadingOrderStatus] = useState(true);

    const [orderData, setOrderData] = useState([]);
    const ref = useRef(null)
    const [orderStatus, setOrderStatus] = useState();

    const onChangeOrderStatus = (e) => {
        setOrderStatus(e.target.value)
    }

    const get = async () => {
        ref.current.continuousStart()
        setLoading(true);
        await dispatch(getOneOrder(id));
        setLoading(false);
    };

    useEffect(() => {
        get();
    }, []);

    const res = useSelector((state) => state.ordersReducer.oneOrder);

    useEffect(() => {
        if (loading === false) {
            if (res) {
                setOrderData(res);
                setOrderStatus(res.status)
                ref.current.complete()
            }
        }
    }, [loading]);



    const changeOrderStatusFunc = async () => {
        console.log('asd')
        if (!orderStatus) {
            notify("من فضلك ادخل حالة الطلب", "warn");
            return;
        }
        setLoadingOrderStatus(true);
        await dispatch(updateOrder(id, { status: orderStatus }));
        setLoadingOrderStatus(false);
    };

    useEffect(() => {
        if (loadingOrderStatus === false) {
            if (res) {
                notify("تم تغير حالة الطلب بنجاح", "success");
            } else {
                notify("فشل في تغير حالة الطلب", "warn");
            }
        }
    }, [loadingOrderStatus]);

    return [orderData, ref, orderStatus,
        onChangeOrderStatus, changeOrderStatusFunc];
};

export default GetOrderDetailsHook;

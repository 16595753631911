import { useState } from "react";
import { useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import { useNavigate } from "react-router-dom";
import notify from "../useNotification";
import {
  getOneCategory,
  updateCategoryResturant,
} from "../../redux/actions/categoriesAction";

const AdminEditCategoryHook = (id , resturantId ) => {
  const navigate = useNavigate();
  const dispatch = useDispatch();

  const [nameAr, setNameAr] = useState("");
  const [nameEn, setNameEn] = useState("");
  const [image, setImage] = useState();
  const [selectedFile, setSelectedFile] = useState(null);
  const [loading, setLoading] = useState(true);

  const onChangeNameAr = (e) => {
    e.persist();
    setNameAr(e.target.value);
  };

  const onChangeNameEn = (e) => {
    e.persist();
    setNameEn(e.target.value);
  };

  const onImageChange = (event) => {
    if (event.target.files && event.target.files[0])
      setImage(URL.createObjectURL(event.target.files[0]));
    setSelectedFile(event.target.files[0]);
  };

  useEffect(() => {
    const get = async () => {
      await dispatch(getOneCategory(id));
    };
    get();
  }, []);

  const categoryResponse = useSelector(
    (state) => state.categoriesReducers.oneCategory
  );

  useEffect(() => {
    if (categoryResponse) {
      setNameAr(categoryResponse.nameAr);
      setNameEn(categoryResponse.nameEn);
      setImage(categoryResponse.image);
      setSelectedFile(categoryResponse.image)
    }
  }, [categoryResponse]);

  const onSubmit = async () => {
 
    if (nameAr === "") {
      notify("من فضلك ادخل اسم الدولة بالعربية", "error");
      return;
    }

    if (nameEn === "") {
      notify("من فضلك ادخل اسم الدولة بالانجليزية", "error");
      return;
    }

    setLoading(true);
    const formData = new FormData();
    formData.append("nameAr", nameAr);
    formData.append("nameEn", nameEn);
    formData.append("image", selectedFile);
    

    await dispatch(updateCategoryResturant(id, formData));
    setLoading(false);
  };

  //console.log(response)
  useEffect(() => {
    if (loading === false) {
      //  console.log('done')
      setNameAr("");
      setNameEn("");
      notify("تم تعديل الصنف بنجاح", "success");
      setTimeout(() => {
        navigate(`/admin/categories/${resturantId}`);
      }, 1500);
    }
  }, [loading]);

  return [
    nameAr,
    nameEn,
    image,
    onSubmit,
    onChangeNameAr,
    onChangeNameEn,
    onImageChange,
  ];
};

export default AdminEditCategoryHook;

import { GET_ALL_RESTAURANT_TYPES,UPDATE_RESTAURANT_TYPES ,ADD_RESTAURANT_TYPE ,GET_ONE_RESTAURANT_TYPE } from "../type";
import { useGetDataToken } from "../../hooks/useGetData";
import { useUpdateData } from "../../hooks/useUpdateData";
import { useInsertData } from "../../hooks/useInsertData";

//get all restaurant types
export const getAllRestaurantTypes= ()=> async(dispatch)=>{
   try {
    const response = await useGetDataToken(`/api/v1/resturantTypes`);
    dispatch({
      type:GET_ALL_RESTAURANT_TYPES,
      payload:response
    });

   } catch (error) {
    dispatch({
        type:GET_ALL_RESTAURANT_TYPES,
        payload:error
      });
   }
}

//update restaurant type
export const updateRestaurantType =(id, body) =>async (dispatch) =>{
    try {
        const response = await useUpdateData(`/api/v1/resturantTypes/${id}`, body);
        dispatch({
            type: UPDATE_RESTAURANT_TYPES,
            payload: response,
          });
    } catch (error) {
        dispatch({
            type: UPDATE_RESTAURANT_TYPES,
            payload: error,
          });
    }

}

//add restaurant type
export const addRestaurantType =(body) =>async (dispatch) =>{
    try {
        const response = await useInsertData(`/api/v1/resturantTypes`,body);
        dispatch({
            type:ADD_RESTAURANT_TYPE,
            payload:response
        });
    } catch (error) {
        dispatch({
            type:ADD_RESTAURANT_TYPE,
            payload:error
        })
    }

}

//get one restaurant type
export const getOneRestaurantType= (id) =>async(dispatch) =>{
    
    try {
        const response= await useGetDataToken(`/api/v1/resturantTypes/${id}`);
        dispatch({
            type:GET_ONE_RESTAURANT_TYPE,
            payload:response
        })
        
    } catch (error) {
        dispatch({
            type:GET_ONE_RESTAURANT_TYPE,
            payload:error
        })
    }

}

import React from "react";
import { Col, Container, Row } from "react-bootstrap";
import AdminAllCountries from "../../Components/Admin/AdminAllCountries";
import SideBar from "../../Components/Admin/SideBar";

const AdminAllCountriesPage = () => {
  return (
    <div className="d-flex justify-content-start">

      <div>
        <SideBar />
      </div>
      <div className="p-4" style={{ width: '100%' }}>
       <AdminAllCountries />
      </div>
    </div>
  );
};

export default AdminAllCountriesPage;
import React from "react";
import { Button } from "react-bootstrap";
import DataTable from "react-data-table-component";
import DataTableExtensions from "react-data-table-component-extensions";
import "react-data-table-component-extensions/dist/index.css";
import { useTranslation } from "react-i18next";
import { useNavigate, useParams } from "react-router-dom";
import { ToastContainer } from "react-toastify";
import AddButton from "../Utilities/AddButton";
import AdminAllCategoriesHook from "../../hook/admin/AdminAllCategoriesHook";

const AdminAllCategories = () => {

    const { t } = useTranslation();
    const {id} = useParams()
  const navigate = useNavigate();
  const [countriesResponse, clickHandler, handleShowFun] = AdminAllCategoriesHook(id)

// console.log('countriesResponse')
// console.log(countriesResponse)

    let columns = [
        {
            name: t("image"),
            cell: (row) => (
              // eslint-disable-next-line jsx-a11y/alt-text
              <img
                src={row.image}
                width="80px"
                height="80px"
                style={{
                  padding: "2px",
                  justifyContent: "center",
                  alignItems: "center",
                  objectFit: "contain",
                }}
              />
            ),
          }, 
        {
          name: t("nameAr"),
          selector: (row)=>row["nameAr"],
          sortable: true,
        },
        {
          name: t("nameEn"),
          selector:(row)=>row["nameEn"],
          sortable: true,
        },
  
     
        
        {
          name: t("food menu"),
          cell: (row) => (
            <div
              style={{ cursor: 'pointer' }}
              variant='secondary'
              id={row._id}
              onClick={() => navigate(`/admin/menus/${row._id}`)}
            >
              {t("clickhere")}
              <i className="fa fa-folder-open-o mx-1" />
    
            </div>
          ),
          ignoreRowClick: true,
          allowOverflow: true,
        },
    
      
    
        {
          name: t("modify"),
          cell: (row) => (
            <Button
              variant="secondary"
              onClick={() => clickHandler(row._id)}
              id={row._id}
            >
              {t("Modify")}
              <i className="fa fa-pencil-square-o mx-1" />
            </Button>
          ),
          ignoreRowClick: true,
          allowOverflow: true,
          button: true,
        },
        {
          name: t("state"),
          cell: (row) =>
            row.status == true ? (
              <Button
                variant="danger"
                onClick={() => {
                  handleShowFun(row._id, false);
                }}
                id={row._id}
              >
                {t("block")}
                <i className="fa fa-lock mx-1" />
              </Button>
            ) : (
              <Button
                variant="success"
                onClick={() => {
                  handleShowFun(row._id, true);
                }}
                id={row._id}
              >
                {t("active")}
                <i className="fa fa-unlock mx-1" />
              </Button>
            ),
          ignoreRowClick: true,
          allowOverflow: true,
          button: true,
        },
      ];
    
      const tableData = {
        columns,
        data: countriesResponse,
      };


    return (
        <div>
        <title>{`${t("categories")}`} </title>
  
        <div className="d-flex justify-content-between">
          <div className="admin-content-text">{t("Classification Management")} </div>
          <AddButton title={t("add category")} navigateTo={`/admin/addCategory/${id}`} />
        </div>
  
        <DataTableExtensions {...tableData} filterPlaceholder={t("search here")}>
          <DataTable
            noHeader
            defaultSortAsc={false}
            pagination
            highlightOnHover
          />
        </DataTableExtensions>
        <ToastContainer />
      </div>
    )
}

export default AdminAllCategories
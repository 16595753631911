import { ALL_COUNTRIES ,UPDATE_COUNTRY,ADD_COUNTRY,GET_ONE_COUNTRY} from "../type";
import { useGetDataToken } from "../../hooks/useGetData";
import { useUpdateData } from "../../hooks/useUpdateData";
import { useInsertData } from "../../hooks/useInsertData"


//get All countries
export const getAllCountries = () => async (dispatch) => {

  try {
    const response = await useGetDataToken(`/api/v1/countries`);
    /// console.log(response)
    dispatch({
      type: ALL_COUNTRIES,
      payload: response,
      loading: true,
    });
  } catch (error) {
    dispatch({
      type: ALL_COUNTRIES,
      payload: error,
    });
  }
};

 // add country
 export const addCountry= (body)=>async (dispatch) =>{
  try {
    //console.log('done');
    const response= await useInsertData(`/api/v1/countries`,body);
    dispatch({
      type:ADD_COUNTRY,
      payload: response,
      loading:true

    })
  } catch (error) {
    dispatch({
      type:ADD_COUNTRY,
      payload:error
    })
  }
 };

// get one country
 export const getOneCountry= (id) =>async(dispatch) =>{
  try {
    const response = await useGetDataToken(`/api/v1/countries/${id}`);

    dispatch({
      type: GET_ONE_COUNTRY,
      payload: response,
      loading: true,
    });
  } catch (error) {
    dispatch({
      type: GET_ONE_COUNTRY,
      payload: error,
    });
  }
}




// update country
export const updateCountry = (id, body) => async (dispatch) => {
  try {
    const response = await useUpdateData(`/api/v1/countries/${id}`, body);

    dispatch({
      type: UPDATE_COUNTRY,
      payload: response,
      loading: true,
    });
  } catch (error) {
    dispatch({
      type: UPDATE_COUNTRY,
      payload: error,
    });
  }
};



import React, { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { getAllCoupons ,updateCoupon } from "../../redux/actions/couponsAction";
import { useNavigate } from "react-router-dom";
import notify from "../useNotification";

const ViewAllCouponsHook = () => {
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const [loading, setLoading] = useState(true);

  useEffect(() => {
     dispatch(getAllCoupons());
   }, [dispatch]);
 
 
 
   const allCoupons = useSelector((state) => state.couponsReducers.getAllCoupons);   //allCountries
 
   
   let coupons = [];
 
   if (allCoupons) coupons = allCoupons;

   const clickHandler = (id) => {
    navigate(`/admin/editcoupon/${id}`);
    
  };

  const handleShowFun= async (id,status)=>{
    setLoading(true);
    await dispatch(

      updateCoupon(id, {
        status: status,
      })
    );
    setLoading(false);
  }

  const res = useSelector((state) => state.couponsReducers.updateCoupon);
  useEffect(() => {
    if (loading === false) {
      if (res) {
        notify("تم التعديل بنجاح", "success");
        setTimeout(() => {
          dispatch(getAllCoupons());
        }, 1000);
      } else {
        notify("فشل في عملية التعديل", "error");
      }
    }
  }, [loading]);

  // console.log(coupons);

  return[
    coupons,
    clickHandler,
    handleShowFun
  ]
}

export default ViewAllCouponsHook;
import React, { useState, useEffect } from "react";
import notify from "../useNotification";
import {addCoupon} from "../../redux/actions/couponsAction";
import { useDispatch, useSelector } from "react-redux";
import { useNavigate } from "react-router-dom";
import avatar from "../../../src/Images/adminIcon.png";

const AdminAddCouponHook = (id) => {
  const navigate = useNavigate();
  const dispatch = useDispatch();

  const [coponCode, setCoponCode] = useState("");
  const [startDate, setStartDate] = useState("");
  const [endDate, setEndDate] = useState("");
  const [value, setValue] = useState("");
  const [type, setType] = useState("");
  const [loading, setLoading] = useState(true);

  //set copon code
  const onChangeCoponCode = (e) => {
    e.persist();
    setCoponCode(e.target.value);
  };

  //set start date
  const onChangeStartDate = (e) => {
    e.persist();
    setStartDate(e.target.value);
  };

  //set end date
  const onChangeEndDate = (e) => {
    e.persist();
    setEndDate(e.target.value);
  };

  //set value
  const onChangeValue = (e) => {
    e.persist();
    setValue(e.target.value);
  };

  //set type
  const onChangeType = (e) => {
    e.persist();
    setType(e.target.value);
  };

  const onSubmit = async () => {
    if (coponCode == "") {
      notify("من فضلك ادخل كوبون الخصم", "error");
      return;
    }
    if (startDate == "") {
      notify("من فضلك ادخل تاريخ بداية الكوبون", "error");
      return;
    }
    if (endDate == "") {
      notify("من فضلك ادخل تاريخ نهاية الكوبون", "error");
      return;
    }
    if (value == "") {
      notify("من فضلك ادخل قيمة الكوبون", "error");
      return;
    }
    if (type == "") {
      notify("من فضلك ادخل نوع الكوبون", "error");
      return;
    }

    await dispatch(
        addCoupon({
        coponCode: coponCode,
        startDate: startDate,
        endDate: endDate,
        value: value,
        type: type,
      })
    );

    setLoading(false);
  };

  const res = useSelector((state) => state.couponsReducers.addCoupon);
  useEffect(() => {

    if (loading === false) {
      if (res) {
      //  console.log('done')
        
        notify("تم إضافة الكوبون بنجاح", "success");
        setTimeout(() => {
          navigate("/admin/coupons");
        }, 1500);
      }
      else{
        notify("فشل في عملية الاضافة", "error");
      }
    }
  
  }, [loading]);

  return [
    coponCode,
    startDate,
    endDate,
    value,
    type,
    onChangeCoponCode,
    onChangeStartDate,
    onChangeEndDate,
    onChangeValue,
    onChangeType,
    onSubmit,
  ];
};

export default AdminAddCouponHook;

import React from "react";
import { Col, Row, Spinner } from "react-bootstrap";
import { ToastContainer } from "react-toastify";
import AdminResturantAddTimeTableHook from "../../hook/admin/AdminResturantAddTimeTableHook";
import { useTranslation } from "react-i18next";
import Cookies from "js-cookie";
import { useParams } from "react-router-dom";
import { useSelector } from "react-redux";

const AdminResturantAddTimeTable = () => {
  const { t } = useTranslation();
  const { id } = useParams();
  const color = useSelector((state) => state.settingsReducers.oneSettings.primaryColor);

 const[
      time,
      dayNum,
      onChangeSetTime,
      onChangeSetDayNum,
      onSubmit
 ] =AdminResturantAddTimeTableHook(id);

 //{ dayName: 'Sunday', dayNumber: 0 },
//     { dayName: 'Monday', dayNumber: 1 },
//     { dayName: 'Tuesday', dayNumber: 2 },
//     { dayName: 'Wednesday', dayNumber: 3 },
//     { dayName: 'Thursday', dayNumber: 4 },
//     { dayName: 'Friday', dayNumber: 5 },
//     { dayName: 'Saturday', dayNumber: 6 }
  
 return (
    <div>
      <title>{`${t("add time table")}`} </title>
      <Row className="justify-content-start">
        <div className="admin-content-text pb-4">{t("add time table")}</div>

        <Col sm="8">
          <label htmlFor="firstname" className="d-block mt-2 ">
            {" "}
            {t("time")}{" "}
          </label>
          <input
            id="firstname"
            value={time}
            onChange={onChangeSetTime}
            type="time"
            className="input-form d-block mt-2 px-3"
            placeholder={t("time")}
          />
          <label htmlFor="firstname" className="d-block mt-2 ">
            {" "}
            {t("dayNum")}{" "}
          </label>
          
          <select
          id="countries"
          name="countries"
          onChange={onChangeSetDayNum}
          value={dayNum}
          className="select input-form-area mt-2 px-3 "
        >
          <option value="0">{t("Sunday")}</option>
          <option value="1">{t("Monday")}</option>
          <option value="2">{t("Tuesday")}</option>
          <option value="3">{t("Wednesday")}</option>
          <option value="4">{t("Thursday")}</option>
          <option value="5">{t("Friday")}</option>
          <option value="6">{t("Saturday")}</option>    
        </select>
         
        </Col>
      </Row>
      <Row>
        <Col sm="8" className="d-flex justify-content-end ">
          <button
            onClick={onSubmit}
            className="btn-save d-inline mt-2 "
            style={{ backgroundColor: color }}
          >
            {t("add")}
          </button>
        </Col>
      </Row>

      <ToastContainer />
    </div>
  );
 
  
  
}

export default AdminResturantAddTimeTable;
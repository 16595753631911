import React, { useState, useEffect } from "react";
import notify from "../useNotification";
import { addUser } from "../../redux/actions/usersActions";
import { useDispatch, useSelector } from "react-redux";
import { useNavigate } from "react-router-dom";
import avatar from "../../../src/Images/adminIcon.png";
import { getAllRestaurants } from "../../redux/actions/restaurantsActions";



const AdminAddUserHook = () => {
  const [fullname, setFullname] = useState("");
  const [img, setImg] = useState(avatar);
  const [selectedFile, setSelectedFile] = useState(null);

  const [email, setEmail] = useState("");
  const [mobile, setMobile] = useState("");
  const [type, setType] = useState("");
  const [resturants, setResturants] = useState("");

  const [password, setPassword] = useState("");
  const [confirmPassword, setConfirmPassword] = useState("");
  const [loading, setLoading] = useState(true);

  const navigate = useNavigate();


  const dispatch = useDispatch();

  const onImageChange = (event) => {
    if (event.target.files && event.target.files[0])
      setImg(URL.createObjectURL(event.target.files[0]));
    setSelectedFile(event.target.files[0]);
  };

  useEffect(() => {
    dispatch(getAllRestaurants());
  }, [dispatch]);
  const resturantsData = useSelector((state) => state.restaurantReducer.getAllRestaurants);

  const onChangeResturants = (e) => {
    e.persist();
    setResturants(e.target.value);
  };
  //set full name state
  const onChangeFullname = (e) => {
    e.persist();
    setFullname(e.target.value);
  };
  //set Last Name state

  //set email state
  const onChangeEmail = (e) => {
    e.persist();
    setEmail(e.target.value);
  };
  //set mobile state
  const onChangeMobile = (e) => {
    // e.persist();
    setMobile(e.target.value);
  };
  // set Password state
  const onChangePassword = (e) => {
    e.persist();
    setPassword(e.target.value);
  };
  // set Confirm Password state
  const onChangeConfirmPassword = (e) => {
    e.persist();
    setConfirmPassword(e.target.value);
  };
  // Set type state
  const onChangeType = (e) => {
    setType(e.target.value);
  };

  const onSubmit = async () => {

    if (fullname === "") {
      notify("من فضلك ادخل الاسم الاول", "error");
      return;
    }

    if (email === "") {
      notify("من فضلك ادخل الايميل ", "error");
      return;
    }
    if (mobile === "") {
      notify("من فضلك ادخل رقم الجوال ", "error");
      return;
    }
    if (mobile.length <= 10) {
      notify("من فضلك ادخل رقم الجوال صحيح", "error");
      return;
    }
    if (password === "") {
      notify("من فضلك ادخل الباسورد", "error");
      return;
    }
    if (confirmPassword === "") {
      notify("من فضلك تاكيد الباسورد   ", "error");
      return;
    }
    if (password !== confirmPassword) {
      notify("من فضلك تاكد من تطابق كلمة السر", "error");
      return;
    } if (type === "" || type === "0") {
      notify("من فضلك تأكد من اختيار النوع", "error")
      return;
    } else {

      await dispatch(addUser({
        fullname: fullname,
        mobile: mobile,
        email: email,
        password: password,
        type: type,
        resturant: resturants
      }))
      setLoading(false)
    }
  };
  const response = useSelector((state) => state.usersReducers.addUser);
  //console.log(response)
  useEffect(() => {

    if (loading === false) {
      if (response) {
        //  console.log('done')
        setFullname("");
        setEmail("");
        setMobile("");
        setPassword("");
        setConfirmPassword("");
        setType("");

        notify("تم إضافة المستخدم بنجاح", "success");

        // setTimeout(() => {
        //   navigate("/admin/allusers");
        // }, 1500);
      }
      else {
        notify("فشل في عملية الاضافة", "error");
      }
    }

  }, [loading]);

  return [
    fullname,
    email,
    mobile,
    password,
    confirmPassword,
    type,
    onChangeFullname,
    onChangeEmail,
    onChangeMobile,
    onChangePassword,
    onChangeConfirmPassword,
    onChangeType,
    onSubmit,
    onChangeResturants,
    resturants,
    resturantsData

  ];
};

export default AdminAddUserHook;

import { NavLink } from "react-router-dom";
import {
  FaBars,
  FaFlag,
  FaHome,
  FaLock,
  FaMoneyBill,
  FaUser,
} from "react-icons/fa";
import { MdMessage } from "react-icons/md";
import { BiAnalyse, BiSearch } from "react-icons/bi";
import { BiCog } from "react-icons/bi";
import { BiDollarCircle } from "react-icons/bi";
import { BiGroup } from "react-icons/bi";
import { AiFillHeart, AiTwotoneFileExclamation } from "react-icons/ai";
import { BsCartCheck } from "react-icons/bs";
import { useState } from "react";
import { AnimatePresence, motion } from "framer-motion";
import SidebarMenu from "./SidebarMenu";
import { useTranslation } from "react-i18next";
import logo from "../../Images/logo.jpg";
import { useSelector } from "react-redux";

const SideBar = ({ children }) => {
  // const color = useSelector((state) => state.utilitiesReducer.color);
  const color = useSelector((state) => state.settingsReducers.oneSettings.primaryColor);

  const { t } = useTranslation();

  //master admin routes
  const routes = [
    {
      path: "/admin/allusers",
      name: t("users"),
      icon: <FaHome />,
    },

    {
      path: "/admin/countries",
      name: t("countries"),
      icon: <FaHome />,
    },

    {
      path: "/admin/coupons",
      name: t("coupons"),
      icon: <FaHome />,
    },
    {
      path: "/admin/resturantTypes",
      name: t("restaurantTypes"),
      icon: <FaHome />,
    },
    {
      path: "/admin/resturants",
      name: t("restaurants"),
      icon: <FaHome />,
    },


    {
      path: "/settings",
      name: t("Settings"),
      icon: <BiCog />,
      exact: true,
      subRoutes: [
        {
          path: "/admin/settings",
          name: t("Site Settings"),
          icon: <FaUser />,
        },
      ],
    },
  ];
  const user = JSON.parse(localStorage.getItem("user"));

  const rule = user?.type;
  const restaurant_id = user?.resturant;

  //restaurant admin routes    path="/foodorders/resturant/:id"
  const restaurantRoutes = [
    {
      path: `/admin/categories/${restaurant_id}`,
      name: t("categories"),
      icon: <FaHome />,
    },

    {
      path: `/foodorders/resturant/${restaurant_id}`,
      name: t("orders"),
      icon: <FaHome />,
    },
    {
      path: `/reservorders/resturant/${restaurant_id}`,
      name: t("reserv"),
      icon: <FaHome />,
    },
    

    {
      path: `/admin/editrestaurant/${restaurant_id}`,
      name: t("resturant Setting"),
      icon: <FaHome />,
    },

    {
      path: `/admin/timetable/${restaurant_id}`,
      name: t("time table"),
      icon: <FaHome />,
    },



   
  ]



  const [isOpen, setIsOpen] = useState(true);
  //const [isRAdmin, setRIsAdmin] = useState(false);
  const toggle = () => setIsOpen(!isOpen);

  //console.log(restaurant_id);
  const inputAnimation = {
    hidden: {
      width: 0,
      padding: 0,
      transition: {
        duration: 0.2,
      },
    },
    show: {
      width: "140px",
      padding: "5px 15px",
      transition: {
        duration: 0.2,
      },
    },
  };

  const showAnimation = {
    hidden: {
      width: 0,
      opacity: 0,
      transition: {
        duration: 0.5,
      },
    },
    show: {
      opacity: 1,
      width: "auto",
      transition: {
        duration: 0.5,
      },
    },
  };

  return (
    <>
      <div className="main-container">
        <motion.div
          animate={{
            width: isOpen ? "200px" : "45px",

            transition: {
              duration: 0.5,
              type: "spring",
              damping: 10,
            },
          }}
          className={`sidebarMenu `}
          style={{ backgroundColor: color && color }}
        >
          <div className="top_section">
            <AnimatePresence>
              {isOpen && (
                <motion.h1
                  variants={showAnimation}
                  initial="hidden"
                  animate="show"
                  exit="hidden"
                  className="logoMenu"
                >
                  {t("control panal")}
                </motion.h1>
              )}
            </AnimatePresence>

            <div className="bars">
              <FaBars onClick={toggle} />
            </div>
          </div>

          <section className="routes">


            {rule == 'admin' ?
              routes.map((route, index) => {
                if (route.subRoutes) {
                  return (
                    <SidebarMenu
                      setIsOpen={setIsOpen}
                      route={route}
                      showAnimation={showAnimation}
                      isOpen={isOpen}
                    />
                  );
                }

                return (
                  <NavLink
                    to={route.path}
                    key={index}
                    className="link"
                  >
                    <div className="icon">{route.icon}</div>
                    <AnimatePresence>
                      {isOpen && (
                        <motion.div
                          variants={showAnimation}
                          initial="hidden"
                          animate="show"
                          exit="hidden"
                          className="link_text"
                        >
                          {route.name}
                        </motion.div>
                      )}
                    </AnimatePresence>
                  </NavLink>
                );

              }) : restaurantRoutes.map((route, index) => {

                if (route.subRoutes) {
                  return (
                    <SidebarMenu
                      setIsOpen={setIsOpen}
                      route={route}
                      showAnimation={showAnimation}
                      isOpen={isOpen}
                    />
                  );
                }

                return (
                  <NavLink
                    to={route.path}
                    key={index}
                    className="link"
                  >
                    <div className="icon">{route.icon}</div>
                    <AnimatePresence>
                      {isOpen && (
                        <motion.div
                          variants={showAnimation}
                          initial="hidden"
                          animate="show"
                          exit="hidden"
                          className="link_text"
                        >
                          {route.name}
                        </motion.div>
                      )}
                    </AnimatePresence>
                  </NavLink>
                );

              })}
          </section>
        </motion.div>

        <main>{children}</main>
      </div>
    </>
  );
};

export default SideBar;

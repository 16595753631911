import React, { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import {
  getAllMenusCategory,
  updateMenu,
} from "../../redux/actions/menusAction";
import { useNavigate } from "react-router-dom";
import notify from "../useNotification";
import { useTranslation } from "react-i18next";

const AdminAllMenusHook = (id) => {
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const [loading, setLoading] = useState(true);
  const { t } = useTranslation();

  useEffect(() => {
    dispatch(getAllMenusCategory(id));
  }, []);

  const menusCategoryResponse = useSelector(
    (state) => state.menusReducers.allMenusCategory
  );

  const clickHandler = (menuId) => {
    navigate(`/admin/editMenu/${menuId}`);
  };

  const handleShowFun = async (id, status) => {
    // console.log('done')
    setLoading(true);
    const formData = new FormData();
    formData.append("status", status);
    await dispatch(updateMenu(id, formData));
    setLoading(false);
  };
  
  const res = useSelector(
    (state) => state.menusReducers.updateMenu
  );
  useEffect(() => {
    if (loading === false) {
      if (res) {
        notify(t("The status has been modified successfully"), "success");
        setTimeout(() => {
          dispatch(getAllMenusCategory(id));
        }, 1000);
      } else {
        notify("فشل في عملية التعديل", "error");
      }
    }
  }, [loading, res]);

  return [menusCategoryResponse, clickHandler, handleShowFun];
};

export default AdminAllMenusHook;

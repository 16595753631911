import React from "react";
import AdminSettings from "../../Components/Admin/AdminSettings";
import SideBar from "../../Components/Admin/SideBar";

const AdminSettingsPage = () => {
    return (

        <div className="d-flex justify-content-start">
            <div>
                <SideBar />
            </div>
            <div className="p-4" style={{ width: '100%' }}>
                <AdminSettings />
            </div>
        </div>

    );
};
export default AdminSettingsPage;

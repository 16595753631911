import React, { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { getAllResturantCategories , updateCategoryResturant } from "../../redux/actions/categoriesAction";
import { useNavigate } from "react-router-dom";
import notify from "../useNotification";
import { useTranslation } from "react-i18next";

const AdminAllCategoriesHook = (id) => {
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const [loading, setLoading] = useState(true);
  const {t} = useTranslation()

  useEffect(() => {
    // console.log('use effect country')
    dispatch(getAllResturantCategories(id));
  }, []);

  const countriesResponse = useSelector(
    (state) => state.categoriesReducers.allResturantCategories
  ); //allCountries


  const clickHandler = (categoryId) => {
    navigate(`/admin/editCategory/${categoryId}/${id}`);
  };




  const handleShowFun = async (id, status) => {
    // console.log('done')
    setLoading(true);
    const formData = new FormData()
    formData.append("status",status)
    await dispatch(
        updateCategoryResturant(id,formData )
    );
    setLoading(false);
  };
  const res = useSelector((state) => state.categoriesReducers.updateResturantCategory);
  useEffect(()=>{
    if(loading === false){
        if(res){
            notify(t("The status has been modified successfully"), "success");
            setTimeout(() => {
                dispatch(getAllResturantCategories(id));
            }, 1000);
            
        }else {
            notify("فشل في عملية التعديل", "error");
          }
    }

  },[loading, res])

  return [countriesResponse, clickHandler, handleShowFun];
};

export default AdminAllCategoriesHook;


import React from "react";
import { Col, Row } from "react-bootstrap";
import { useTranslation } from "react-i18next";
import { useSelector } from "react-redux";
import { useParams } from "react-router-dom";
import { ToastContainer } from "react-toastify";
import AdminEditCategoryHook from "../../hook/admin/AdminEditCategoryHook";


const AdminEditCategory = () => {
  const { t } = useTranslation();
  const { id, resturantId } = useParams();



  const [
    nameAr,
    nameEn,
    image,
    onSubmit,
    onChangeNameAr,
    onChangeNameEn,
    onImageChange,
  ] = AdminEditCategoryHook(id, resturantId)
  const color = useSelector((state) => state.settingsReducers.oneSettings.primaryColor);

  return (
    <div>
      <title>{`${t("add category")}`} </title>
      <Row className="justify-content-start">
        <div className="admin-content-text pb-4">{t("add category")}</div>

        <Col sm="8">
          <div className="text-form pb-2">{t("image")}</div>
          <div>
            <label htmlFor="upload-photo">
              <img
                src={image}
                alt="fzx"
                height="120px"
                width="120px"
                style={{ cursor: "pointer" }}
              />
            </label>
            <input
              type="file"
              name="photo"
              onChange={onImageChange}
              id="upload-photo"
            />
          </div>

          <label htmlFor="firstname" className="d-block mt-2 ">
            {" "}
            {t("nameAr")}{" "}
          </label>
          <input
            id="firstname"
            value={nameAr}
            onChange={onChangeNameAr}
            type="text"
            className="input-form d-block mt-2 px-3"
            placeholder={t("nameAr")}
          />
          <label htmlFor="firstname" className="d-block mt-2 ">
            {" "}
            {t("nameEn")}{" "}
          </label>
          <input
            id="firstname"
            value={nameEn}
            onChange={onChangeNameEn}
            type="text"
            className="input-form d-block mt-2 px-3"
            placeholder={t("nameEn")}
          />

        </Col>
      </Row>
      <Row>
        <Col sm="8" className="d-flex justify-content-end ">
          <button
            onClick={onSubmit}
            className="btn-save d-inline mt-2 "
            style={{ backgroundColor: color }}
          >
            {t("add")}
          </button>
        </Col>
      </Row>

      <ToastContainer />
    </div>
  );
};

export default AdminEditCategory;

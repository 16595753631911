import {
    GET_ALL_RESERVES_BY_RESTAURANT_ID , GET_RESERVE_ORDER_DETAILS ,UPDATE_RESERVE_ORDER
} from "../type";
import { useGetDataToken } from "../../hooks/useGetData";
import { useUpdateData } from "../../hooks/useUpdateData";
import { useInsertData } from "../../hooks/useInsertData"


//get All reserve orders by restaurant  id
export const getAllReservesByRestaurantId = (id) => async (dispatch) => {
    try {
        const response = await useGetDataToken(`/api/v1/reservorders/resturant/${id}`);
    
        dispatch({
          type: GET_ALL_RESERVES_BY_RESTAURANT_ID ,
          payload: response,
          loading: true,
        });
      } catch (error) {
        dispatch({
          type: GET_ALL_RESERVES_BY_RESTAURANT_ID ,
          payload: error,
        });
      }
}

//get reserve order details by id
export const getReserveOrderDetails = (id) => async (dispatch) => {
  try {
    const response = await useGetDataToken(`/api/v1/reservorders/reverse/${id}`);

    dispatch({
      type: GET_RESERVE_ORDER_DETAILS ,
      payload: response,
      loading: true,
    });
  } catch (error) {
    dispatch({
      type: GET_RESERVE_ORDER_DETAILS ,
      payload: error,
    });
  }
}

//update reserv order
export const updateReserveOrder = (id, body) =>async(dispatch) =>{
  try {
    const response = await useUpdateData(`/api/v1/reservOrders/${id}`,body);

    dispatch({
      type: UPDATE_RESERVE_ORDER ,
      payload: response,
      loading: true,
    });
  } catch (error) {
    dispatch({
      type: UPDATE_RESERVE_ORDER ,
      payload: error,
    });
  }
}
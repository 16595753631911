import React from "react";
import { Row, Col } from "react-bootstrap";
import Cookies from "js-cookie";

import { t } from "i18next";


const UserAllOrderItem = ({ orderItem }) => {
  return (
    <div className="mt-2">
      {orderItem.foodOrderDetails
        ? orderItem.foodOrderDetails.map((item) => {

          return <div key={item._id}>
            <Row className="mb-2" >
              <Col xs="6" lg="2"  md="6">
                <img
                  width="180px"
                  height="120px"
                  src={(item?.resturantMenu && item?.resturantMenu?.image)}
                  alt=""
                  style={{ objectFit: "contain" }}
                />
              </Col>
              <Col xs="6" lg="10" md="6">
                <div className="d-inline pt-2 cat-title">
                  {(item?.resturantMenu && item?.resturantMenu?.nameAr) || ""}
                </div>
                <div className="mt-3">
                  <div className="cat-text mt-1 d-inline">{t("Quantity")}</div>
                  <input
                    value={item.quantity}
                    className="mx-2 "
                    type="number"
                    style={{ width: "40px", height: "30px" }}
                  />
                </div>
              </Col>
            </Row>
          </div>;
        })
        : null}

    </div >
  );
};

export default UserAllOrderItem;

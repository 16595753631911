import React from "react";
import { Col, Row, Spinner } from "react-bootstrap";
import { useParams } from "react-router-dom";
import { ToastContainer } from "react-toastify";
import AdminEditCountryHook from "../../hook/admin/AdminEditCountryHook";
import { useTranslation } from "react-i18next";
import { useSelector } from "react-redux";

const AdminEditCountry = () => {
  const { id } = useParams();
  const { t } = useTranslation();
  const [
    countryAr,
    countryEn,
    countryCode,
    CountryTellCode,
    img,
    selectedFile,
    status,
    onChangeCountryAr,
    onChangeCountryEn,
    onChangeCountryCode,
    onChangeCountryTellCode,
    onChangeStatus,
    onSubmit
  ] = AdminEditCountryHook(id);
  const color = useSelector((state) => state.settingsReducers.oneSettings.primaryColor);

  return (
    <div>
      <title>{`${t("edit country")}`} </title>
      <Row className="justify-content-start">
        <div className="admin-content-text pb-4">{t("edit country")}</div>

        <Col sm="8">
          <label htmlFor="firstname" className="d-block mt-2 ">
            {" "}
            {t("countryAr")}{" "}
          </label>
          <input
            id="firstname"
            value={countryAr}
            onChange={onChangeCountryAr}
            type="text"
            className="input-form d-block mt-2 px-3"
            placeholder={t("countryAr")}
          />
          <label htmlFor="firstname" className="d-block mt-2 ">
            {" "}
            {t("countryEn")}{" "}
          </label>
          <input
            id="firstname"
            value={countryEn}
            onChange={onChangeCountryEn}
            type="text"
            className="input-form d-block mt-2 px-3"
            placeholder={t("countryEn")}
          />

          <label htmlFor="firstname" className="d-block mt-2 ">
            {" "}
            {t("countryCode")}{" "}
          </label>
          <input
            id="firstname"
            value={countryCode}
            onChange={onChangeCountryCode}
            type="text"
            className="input-form d-block mt-2 px-3"
            placeholder={t("countryCode")}
          />

          <label htmlFor="firstname" className="d-block mt-2 ">
            {" "}
            {t("CountryTellCode")}{" "}
          </label>
          <input
            id="firstname"
            value={CountryTellCode}
            onChange={onChangeCountryTellCode}
            type="text"
            className="input-form d-block mt-2 px-3"
            placeholder={t("CountryTellCode")}
          />

          <label htmlFor="firstname" className="d-block mt-2 ">
            {" "}
            {t("status")}{" "}
          </label>
          <select className="select input-form-area mt-2 px-2 "
            onChange={onChangeStatus}>
            <option value="0">الحالة</option>
            <option value='true'>نشط</option>
            <option value='false'>غير نشط</option>
          </select>
        </Col>

      </Row>
      <Row>
        <Col sm="8" className="d-flex justify-content-end ">
          <button
            onClick={onSubmit}
            className="btn-save d-inline mt-2 "
            style={{ backgroundColor: color }}
          >
            {t("edit")}
          </button>
        </Col>
      </Row>

      <ToastContainer />
    </div>
  );

};

export default AdminEditCountry;

import React, { useState, useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";

const ProtectedRouteHook = () => {
  const [userData, setUserData] = useState(
    JSON.parse(localStorage.getItem("user"))
  );

  const [isAdmin, setIsAdmin] = useState();
  const [isResturantAdmin, setIsResturantAdmin] = useState();

  useEffect(() => {

    if (userData != null) {
      if (userData.role === "admin") {
        setIsResturantAdmin(false);
        setIsAdmin(true);
      } else if (userData.role === "resturantAdmin") {
        setIsResturantAdmin(true);
        setIsAdmin(false);
      }
    } else {
      setIsAdmin(false);
      setIsResturantAdmin(false);
    }
  }, []);


  return [isAdmin, isResturantAdmin, userData];
};

export default ProtectedRouteHook;

import { GET_ALL_RESTAURANT, UPDATE_RESTAURANT, ADD_RESTAURANT ,GET_ONE_RESTAURANT } from "../type";
import { useGetDataToken } from "../../hooks/useGetData";
import { useUpdateDataWithImage } from "../../hooks/useUpdateData";
import { useInsertDataWithImage } from "../../hooks/useInsertData";

//get all restaurants
export const getAllRestaurants = () => async (dispatch) => {
  try {
    const response = await useGetDataToken(`/api/v1/resturants`);

    dispatch({
      type: GET_ALL_RESTAURANT,
      payload: response,
    });
  } catch (error) {
    dispatch({
      type: GET_ALL_RESTAURANT,
      payload: error,
    });
  }
};

//update restaurant
export const updateRestaurant = (id, body) => async (dispatch) => {
  try {
    const response = await useUpdateDataWithImage(`/api/v1/resturants/${id}`, body);

    dispatch({
      type: UPDATE_RESTAURANT,
      payload: response,
    });
  } catch (error) {
    dispatch({
      type: UPDATE_RESTAURANT,
      payload: error,
    });
  }
};

//add restaurant
export const addRestaurant = (body) => async (dispatch) => {
  try {
    const response = await useInsertDataWithImage(`/api/v1/resturants`, body);

    dispatch({
      type: ADD_RESTAURANT,
      payload: response,
    });
  } catch (error) {
    dispatch({
      type: ADD_RESTAURANT,
      payload: error,
    });
  }
};

//get one restaurant by id
export const getOneRestaurant=(id) =>async (dispatch) =>{
    try {
        const response= await useGetDataToken(`/api/v1/resturants/${id}`);
        dispatch({
            type:GET_ONE_RESTAURANT,
            payload:response
        })

    } catch (error) {
        dispatch({
            type:GET_ONE_RESTAURANT,
            payload:error
        })
    }
}


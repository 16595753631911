import React, { useEffect, useState } from "react";
import { Col, Row, Spinner } from "react-bootstrap";
import DataTable from "react-data-table-component";
import AdminEditResturantTimeTableHook from "../../hook/admin/AdminEditResturantTimeTableHook";
import DataTableExtensions from "react-data-table-component-extensions";
import "react-data-table-component-extensions/dist/index.css";
import { ToastContainer } from "react-toastify";
import { useNavigate } from "react-router-dom";
import AddButton from "../Utilities/AddButton";
import { useTranslation } from "react-i18next";
import Cookies from "js-cookie";
import { useParams } from "react-router-dom";
import formatDate2 from "../../Utilities/formDate2";
import SearchButton from "../Utilities/SearchButton";
import { useSelector } from "react-redux";

const AdminResturantEditTimeTable = () => {
  const { id } = useParams();
  const { t } = useTranslation();
  const navigate = useNavigate();

  const[
        time, 
        dayNum,
        onChangeSetTime,
        onChangeSetDayNum,
        onSubmit
  ] =AdminEditResturantTimeTableHook(id);
  const color = useSelector((state) => state.settingsReducers.oneSettings.primaryColor);

  if(time)
    {
     
           let day=time;

           switch(day)
           {
             case 0:
                return dayNum=t("Sunday");
                
             case 1:
                return dayNum=t("Monday");
                
                case 2:
                    return dayNum=t("Tuesday");
                    

                case 3:
                    return dayNum=t("Wednesday");
                    

                case 4:
                    return dayNum=t("Thursday");
                    

                case 5:
                    return dayNum=t("Friday");
                    

                case 6:
                    return dayNum=t("Saturday");
                default:
                    
           }
       
    }

    return (
        <div>
          <title>{`${t("edit time table")}`} </title>
          <Row className="justify-content-start">
            <div className="admin-content-text pb-4">{t("edit time table")}</div>
    
            <Col sm="8">
              <label htmlFor="firstname" className="d-block mt-2 ">
                {" "}
                {t("time")}{" "}
              </label>
              <input
                id="firstname"
                value={time}
                onChange={onChangeSetTime}
                type="time"
                className="input-form d-block mt-2 px-3"
                placeholder={t("time")}
              />
              <label htmlFor="firstname" className="d-block mt-2 ">
                {" "}
                {t("dayNum")}{" "}
              </label>
              
              <select
              id="countries"
              name="countries"
              onChange={onChangeSetDayNum}
              value={dayNum}
              className="select input-form-area mt-2 px-3 "
            >
              <option value="0">{t("Sunday")}</option>
              <option value="1">{t("Monday")}</option>
              <option value="2">{t("Tuesday")}</option>
              <option value="3">{t("Wednesday")}</option>
              <option value="4">{t("Thursday")}</option>
              <option value="5">{t("Friday")}</option>
              <option value="6">{t("Saturday")}</option>    
            </select>
             
            </Col>
          </Row>
          <Row>
            <Col sm="8" className="d-flex justify-content-end ">
              <button
                onClick={onSubmit}
                className="btn-save d-inline mt-2 "
                style={{ backgroundColor: color }}
              >
                {t("edit")}
              </button>
            </Col>
          </Row>
    
          <ToastContainer />
        </div>
      );
  
}
export default AdminResturantEditTimeTable;
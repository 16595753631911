import React, { Fragment, useEffect, useState } from "react";
import {
  Container,
  FormControl,
  Nav,
  Navbar,
  NavDropdown,
} from "react-bootstrap";

import user from "../../Images/adminIcon.png";
// import logo from "../../Images/logo.jpg";

import { useTranslation } from "react-i18next";
import cookies from "js-cookie";
import { useDispatch, useSelector } from "react-redux";
import { getOneSettings } from "../../redux/actions/settingsActions";


const NavBarLogin = () => {
  const dispatch = useDispatch()

  const languages = [
    {
      code: "en",
      name: "English",
      dir: "ltr",
    },
    {
      code: "ar",
      name: "العربية",
      dir: "rtl",
    },
  ];
  const [t, i18n] = useTranslation();
  const currentLanguageCode = cookies.get("i18next") || "en";
  const currentLanguage = languages.find((l) => l.code === currentLanguageCode);

  useEffect(() => {
    document.body.dir = currentLanguage.dir || "ltr";
  }, [currentLanguage]);
  const [userData, setUserData] = useState("");

  useEffect(() => {
    if (localStorage.getItem("user") != null) {
      setUserData(JSON.parse(localStorage.getItem("user")));
    }
    dispatch(getOneSettings());
  }, []);

  // const logo = useSelector(
  //   (state) => state.utilitiesReducer.logo
  // );
  // const color = useSelector(
  //   (state) => state.utilitiesReducer.color
  // );

  const color = useSelector((state) => state.settingsReducers.oneSettings.primaryColor);
  const logo = useSelector((state) => state.settingsReducers.oneSettings.logo);

  // console.log(color)

  const logOut = () => {
    localStorage.removeItem("user");
    setUserData("");
  };

  return (
    <Navbar
      className="sticky-top"
      variant="light"
      expand="sm"
      style={{ boxShadow: "0 4px 8px 0 rgba(0, 0, 0, 0.2)", backgroundColor: color && `${color}` }}
    >
      <Container>
        <Navbar.Brand>
          <a href="/">
            <img src={logo} className="logo2" alt="Logo" />
          </a>
        </Navbar.Brand>
        <Navbar.Toggle aria-controls="basic-navbar-nav" />
        <Navbar.Collapse id="basic-navbar-nav">

          <Nav className="me-auto">
            {i18n.language == "en" ? (
              <Nav.Link
                onClick={() => i18n.changeLanguage("ar")}
                className="nav-text langText d-flex mt-3 justify-content-center"
              >
                عربي
              </Nav.Link>
            ) : (
              <Nav.Link
                onClick={() => i18n.changeLanguage("en")}
                className="nav-text langText d-flex mt-3 justify-content-center"
              >
                English
              </Nav.Link>
            )}
            {userData != "" && (
              <NavDropdown title={userData.fullname} id="basic-nav-dropdown">
                {
                  <NavDropdown.Item href="/">
                    {t("profile")}
                  </NavDropdown.Item>
                }
                <NavDropdown.Divider />
                <NavDropdown.Item onClick={logOut} href="/">
                  {t("logout")}
                </NavDropdown.Item>
              </NavDropdown>
            )}
          </Nav>
        </Navbar.Collapse>
      </Container>
    </Navbar>
  );
};

export default NavBarLogin;

import React, { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { getAllCitiesByCountryId ,updateCity } from "../../redux/actions/citiesActions";
import { useNavigate } from "react-router-dom";
import notify from "../useNotification";

const ViewAllCitiesHook=(id)=>{
    const navigate = useNavigate();
    const dispatch = useDispatch();
     const [loading, setLoading] = useState(true);
    // const [loadingData, setLoadingData] = useState(true);

    // const [cityAr, setCityAr] = useState("");
    // const [cityEn, setCityEn] = useState("");
    // const [country, setCountry] = useState("");
    
      //console.log(id);
      const countryCities = useSelector((state) => state.citiesReducers.allCitiesInCountry);
      let cities=[];
      if (countryCities) cities = countryCities;

  useEffect(() => {
    const get = async () => {
      
      await dispatch(getAllCitiesByCountryId(id));
      
    };
    get();
  }, [dispatch,id]);

  const clickHandler=(id)=>{
    navigate(`/admin/editcity/${id}`);
  }

  useEffect(() => {
    if (loading === false) {
      if (res) {
        notify("تم التعديل بنجاح", "success");
        setTimeout(() => {
          dispatch(getAllCitiesByCountryId(id));
        }, 1000);
      } else {
        notify("فشل في عملية التعديل", "error");
      }
    }
  }, [loading]);

  const handleShowFun = async (id, status) => {
    
     setLoading(true);
     await dispatch(
 
        updateCity(id, {
         status: status,
       })
     );
     setLoading(false);
   };
   const res = useSelector((state) => state.citiesReducers.updateCity);
   


  


  return[
   cities,clickHandler,handleShowFun

  ]


}


export default ViewAllCitiesHook;

import { useState } from "react";
import { useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import { useNavigate } from "react-router-dom";
import notify from "../useNotification";
import {
getOneMenu , updateMenu
} from "../../redux/actions/menusAction";

const AdminEditMenuHook = (id ) => {
  const navigate = useNavigate();
  const dispatch = useDispatch();

  const [nameAr, setNameAr] = useState("");
  const [nameEn, setNameEn] = useState("");
  const [price,setPrice] = useState(0)
  const [oldPrice,setOldPrice] = useState(0)
  const [description,setDescription] = useState("")
  const [image, setImage] = useState();
  const [selectedFile, setSelectedFile] = useState(null);
  const [loading, setLoading] = useState(true);

  const onChangeNameAr = (e) => {
    e.persist();
    setNameAr(e.target.value);
  };

  const onChangeNameEn = (e) => {
    e.persist();
    setNameEn(e.target.value);
  };


  const onChangePrice = (e) => {
    e.persist();
    setPrice(e.target.value);
  };

  const onChangeOldPrice = (e) => {
    e.persist();
    setOldPrice(e.target.value);
  };
  const onChangeDescription = (e) => {
    e.persist();
    setDescription(e.target.value);
  };

  const onImageChange = (event) => {
    if (event.target.files && event.target.files[0])
      setImage(URL.createObjectURL(event.target.files[0]));
    setSelectedFile(event.target.files[0]);
  };

  useEffect(() => {
    const get = async () => {
      await dispatch(getOneMenu(id));
    };
    get();
  }, []);

  const menuResponse = useSelector(
    (state) => state.menusReducers.oneMenu  );

  useEffect(() => {
    if (menuResponse) {
      setNameAr(menuResponse.nameAr);
      setNameEn(menuResponse.nameEn);
      setPrice(menuResponse.price)
      setOldPrice(menuResponse.oldPrice)
      setDescription(menuResponse.description)
      setImage(menuResponse.image);
      setSelectedFile(menuResponse.image)
    }
  }, [menuResponse]);

  const onSubmit = async () => {
 
    if (nameAr === "") {
      notify("من فضلك ادخل اسم الدولة بالعربية", "error");
      return;
    }

    if (nameEn === "") {
      notify("من فضلك ادخل اسم الدولة بالانجليزية", "error");
      return;
    }
    if (price === 0) {
        notify("من فضلك  بإضافة السعر ", "error");
        return;
      }
    setLoading(true);
    const formData = new FormData();
    formData.append("nameAr", nameAr);
    formData.append("nameEn", nameEn);
    formData.append("image", selectedFile);
    formData.append("price", price);
    formData.append("oldPrice", oldPrice);
    formData.append("description", description);
    formData.append("resturant", menuResponse?.resturant);
    formData.append("restaurantCategory",menuResponse?.restaurantCategory );

    await dispatch(updateMenu(id, formData));
    setLoading(false);
  };

  //console.log(response)
  useEffect(() => {
    if (loading === false) {
      //  console.log('done')
      setNameAr("");
      setNameEn("");
      notify("تم التعديل  بنجاح", "success");
      setTimeout(() => {
        navigate(`/admin/menus/${menuResponse?.restaurantCategory}`);
      }, 1500);
    }
  }, [loading]);

  return [
    nameAr,
    nameEn,
    price,
    oldPrice,
    description,
    image,
    onChangeNameAr,
    onChangeNameEn,
    onChangePrice,
    onChangeOldPrice,
    onChangeDescription,
    onImageChange,
    onSubmit,
  ];
};

export default AdminEditMenuHook;

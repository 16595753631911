import { UPDATE_SETTINGS, GET_ONE_SETTINGS } from "../type";
import { useGetDataToken } from "../../hooks/useGetData";
import { useUpdateData, useUpdateDataWithImage } from "../../hooks/useUpdateData";


//update Settings
export const updateSettings = (id, body) => async (dispatch) => {
    try {
        const response = await useUpdateDataWithImage(`/api/v1/settings/${id}`, body);

        dispatch({
            type: UPDATE_SETTINGS,
            payload: response,
        });
    } catch (error) {
        dispatch({
            type: UPDATE_SETTINGS,
            payload: error,
        });
    }
};

//get one Settings by id
export const getOneSettings = () => async (dispatch) => {
    try {
        const response = await useGetDataToken(`/api/v1/settings`);
        dispatch({
            type: GET_ONE_SETTINGS,
            payload: response
        })
    } catch (error) {
        dispatch({
            type: GET_ONE_SETTINGS,
            payload: error
        })
    }
}


import React, { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import {
  getAllReservesByRestaurantId
} from "../../redux/actions/ReserveOrdersActions";
import { useNavigate } from "react-router-dom";
import notify from "../useNotification";
import { useTranslation } from "react-i18next";

const AdminAllReserveOrderHook = (id) => {
    const dispatch = useDispatch();
    const navigate = useNavigate();
    const [loading, setLoading] = useState(true);
    const [searchField, setSearchField] = useState('');
    const[reserveOrders,setReserveOrders]=useState([]);
    const[allOrders,setAllOrders]=useState([]);
    

    const { t } = useTranslation();

    useEffect( ()=>{
        dispatch(getAllReservesByRestaurantId(id))
    },[dispatch,id]);

   let items=useSelector((state)=>state.reserveOrdersReducer.getAllReservesByRestaurantId);

   useEffect(()=>{
      if(items)
      {
        setReserveOrders(items);
        setAllOrders(items);
      }
   },[items])

   const clickHandler = (id) => {
    navigate(`/reservorders/reverse/${id}`);

  };

  const search= (e)=>{
    //let searchField=e.target.previousSibling.value;
    setSearchField(e.target.previousSibling.value);
    //console.log('done');

  }

  useEffect(()=>{
    if(searchField)
    {
     // console.log(reserveOrders);
     let Orders= allOrders.filter((order)=>{
        let status=order.status;
    
        if(searchField==status)
        {
          return order;
        }
      })
      
      setReserveOrders(Orders);
    }
    else
    {
          setReserveOrders(allOrders);
    }
  
    //console.log(reserveOrders);
  },[searchField])

 


   return [reserveOrders, clickHandler,search];

}

export default AdminAllReserveOrderHook;
import {
  ALL_COUNTRIES,
  UPDATE_COUNTRY,
  ADD_COUNTRY,
  GET_ONE_COUNTRY,
} from "../type";

const inital = {
  allCountries: [],
  updateCountry: [],
  addCountry: [],
  getOneCountry: [],
};

const countriesReducers = (state = inital, action) => {
  switch (action.type) {
    case ALL_COUNTRIES:
      return {
        ...state,
        allCountries: action.payload,
      };

    case UPDATE_COUNTRY:
      return {
        ...state,
        updateCountry: action.payload,
      };
    case ADD_COUNTRY:
      return {
        ...state,
        addCountry: action.payload,
      };
    case GET_ONE_COUNTRY:
      return {
        ...state,
        getOneCountry: action.payload,
      };

    default:
      return state;
  }
};

export default countriesReducers;

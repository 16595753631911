import React, { useEffect, useState } from "react";
import avatar from "../../Images/avatar.png";
import notify from "../../hook/useNotification";
import { useDispatch, useSelector } from "react-redux";
import { selectedLogo, selectedPrimaryColor } from "../../redux/actions/utilitiesAction";
import { getOneSettings, updateSettings } from "../../redux/actions/settingsActions";

const AdminSettingsHook = () => {
  const [facebook, setFacebook] = useState("");
  const [phone, setPhone] = useState("");
  const [phone2, setPhone2] = useState("");
  const [email, setEmail] = useState("");
  const [address, setAddress] = useState("");
  const [img, setImg] = useState(avatar);

  const [titleAr, settitleAr] = useState("");
  const [titleEn, settitleEn] = useState("");
  const [showPicker, setShowPicker] = useState(false);
  const [loading, setLoading] = useState(true);

  const [colors, setColors] = useState("");
  const [seccolors, setSeccolors] = useState("");
  const [vat, setVat] = useState("");
  const [selectedFile, setSelectedFile] = useState(null);

  const [showPickerseccolors, setShowPickerseccolors] = useState(false);

  const dispatch = useDispatch()




  const onChangePickerseccolors = (e) => {
    setShowPickerseccolors(!showPickerseccolors);
  };

  const handleChaneCompleteseccolors = (color) => {
    setSeccolors(color.hex);
    setShowPickerseccolors(!showPickerseccolors);
  };

  const onChangePicker = (e) => {
    setShowPicker(!showPicker);
  };

  const handleChaneComplete = (color) => {
    setColors(color.hex);
    setShowPicker(!showPicker);
  };

  const onChangeFacebook = (e) => {
    e.persist();
    setFacebook(e.target.value);
  };
  const onChangePhone = (e) => {
    e.persist();
    setPhone(e.target.value);
  };

  const onChangeVat = (e) => {
    e.persist();

    setVat(e.target.value);
  };
  const onChangePhone2 = (e) => {
    e.persist();
    setPhone2(e.target.value);
  };
  const onChangeEmail = (e) => {
    e.persist();
    setEmail(e.target.value);
  };
  const onChangeAddress = (e) => {
    e.persist();
    setAddress(e.target.value);
  };


  const onChangeTitleAr = (e) => {
    e.persist();
    settitleAr(e.target.value);
  };
  const onChangeTitleEn = (e) => {
    e.persist();
    settitleEn(e.target.value);
  };

  const onImageChange = (event) => {
    if (event.target.files && event.target.files[0]) {
      setImg(URL.createObjectURL(event.target.files[0]));
      setSelectedFile(event.target.files[0]);
    }
  };

  useEffect(() => {
    const run = async () => {
      await dispatch(getOneSettings());
    };
    run();
  }, []);

  const item = useSelector((state) => state.settingsReducers.oneSettings);
  useEffect(() => {
    if (item) {
      if (item.logo)
        setImg(item.logo);
      else
        setImg(avatar)
      setFacebook(item.facebook);
      setPhone(item.mobile1);
      setPhone2(item.mobile2);
      setEmail(item.email);
      setAddress(item.address);
      settitleAr(item.titleAr);
      settitleEn(item.titleEn);
      setColors(item.primaryColor);
      setSeccolors(item.secondaryColor);
      setVat(item.vat);
    }
  }, [item]);

  const onSubmit = async () => {
    // dispatch(selectedLogo(img))
    // dispatch(selectedPrimaryColor(colors))

    // dispatch(updateSettings(colors))
    const formData = new FormData();
    formData.append("facebook", facebook);
    formData.append("mobile1", phone);
    formData.append("mobile2", phone2);
    formData.append("email", email);

    formData.append("address", address);
    formData.append("logo", selectedFile);
    formData.append("titleAr", titleAr);
    formData.append("titleEn", titleEn);
    formData.append("primaryColor", colors);
    formData.append("seccolors", seccolors);
    formData.append("secondaryColor", seccolors);
    setLoading(true);
    await dispatch(updateSettings(item._id, formData));
    setLoading(false);
  };
  const res = useSelector((state) => state.settingsReducers.updateSettings);

  console.log(res)
  useEffect(() => {
    if (loading === false) {
      if (res.data.success === true) {
        notify("تم الاضافة بنجاح", "success");
        dispatch(getOneSettings());
      }
      else {
        notify("فشل في عملية الاضافه", "error");
      }
    }
  }, [loading]);



  return [
    facebook,
    phone,
    onChangeFacebook,
    onChangePhone,
    onSubmit,
    phone2,
    onChangePhone2,
    email,
    onChangeEmail,
    address,
    onChangeAddress,
    titleAr,
    titleEn,
    onChangeTitleAr,
    onChangeTitleEn,
    showPicker,
    colors,
    handleChaneComplete,
    onChangePicker,
    img,
    onImageChange,
    seccolors,
    onChangePickerseccolors,
    handleChaneCompleteseccolors,
    showPickerseccolors,
    vat,
    onChangeVat
  ];
};
export default AdminSettingsHook;

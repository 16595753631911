import { GET_ORDERS_BY_RESTAURANT_ID, UPDATE_ORDER, GET_ONE_ORDER } from "../type";
import { useGetDataToken } from "../../hooks/useGetData";
import { useUpdateData } from "../../hooks/useUpdateData";
import { useInsertData } from "../../hooks/useInsertData";

//get all orders for one restaurant
export const getAllOrdersByRestaurantId = (id,condition,type) => async (dispatch) => {

  let api='';
  console.log('type')
  console.log(type)
   condition == 'all' ? api= `/api/v1/foodorders/resturant/${id}` : api= `/api/v1/foodOrders/refreshorders/${id}/?status=${type}` ;
  try {
    const response = await useGetDataToken(api);


    dispatch({
      type: GET_ORDERS_BY_RESTAURANT_ID,
      payload: response,
      loading: true,
    });
  } catch (error) {
    dispatch({
      type: GET_ORDERS_BY_RESTAURANT_ID,
      payload: error,
    });
  }

  

}

//update order
export const updateOrder = (id, body) => async (dispatch) => {
  try {
    const response = await useUpdateData(`/api/v1/foodorders/${id}`, body);

    dispatch({
      type: UPDATE_ORDER,
      payload: response,
      loading: true,
    });
  } catch (error) {
    dispatch({
      type: UPDATE_ORDER,
      payload: error,
    });
  }
}


export const getOneOrder = (id) => async (dispatch) => {
  try {
    const response = await useGetDataToken(`/api/v1/foodorders/${id}`);
    dispatch({
      type: GET_ONE_ORDER,
      payload: response,
    });
  } catch (error) {
    dispatch({
      type: GET_ONE_ORDER,
      payload: error,
    });
  }
};
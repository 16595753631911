import {
    GET_ORDERS_BY_RESTAURANT_ID, UPDATE_ORDER, GET_ONE_ORDER
} from "../type";

const inital = {
    getAllOrdersByRestaurantId: [],
    updateOrder: [],
    oneOrder: []

}

const ordersReducers = (state = inital, action) => {
    switch (action.type) {
        case GET_ORDERS_BY_RESTAURANT_ID:
            return {
                ...state,
                getAllOrdersByRestaurantId: action.payload
            }
        case UPDATE_ORDER:
            return {
                ...state,
                updateOrder: action.payload
            }
        case GET_ONE_ORDER:
            return {
                ...state,
                oneOrder: action.payload
            }
        default:
            return state
    }
}

export default ordersReducers

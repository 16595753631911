import {
    GET_ALL_CITIES_IN_COUNTRY,UPDATE_CITY,ADD_CITY,GET_ONE_CITY

  } from "../type";
  
  const inital = {
    allCitiesInCountry: [],
    updateCity:[],
    addCity:[],
    getOneCity:[]
  };

  const citiesReducers = (state = inital, action) => {
    switch (action.type) {
        case GET_ALL_CITIES_IN_COUNTRY:
         return {
            ...state,
            allCitiesInCountry:action.payload
            }
        case UPDATE_CITY:
            return{
                ...state,
                updateCity:action.payload
            }
           case ADD_CITY:{
             return{
              ...state,
              addCity:action.payload
             }
           } 
           case GET_ONE_CITY:
            return{
              ...state,
              getOneCity:action.payload
            }


        default:
        return state;
    }
  };

  export default citiesReducers;
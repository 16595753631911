import { useGetDataToken } from "../../hooks/useGetData";
import { useInsertDataWithImage } from "../../hooks/useInsertData";
import { useUpdateDataWithImage } from "../../hooks/useUpdateData";
import {
  ADD_MENU,
  GET_ALL_MENUS_CATEGORIES,
  GET_ONE_MENU,
  UPDATE_MENU,
} from "../type";

// get ALL menus of category
export const getAllMenusCategory = (id) => async (dispatch) => {
  try {
    const response = await useGetDataToken(`/api/v1/menus/category/${id}`);
    dispatch({
      type: GET_ALL_MENUS_CATEGORIES,
      payload: response,
    });
  } catch (error) {
    dispatch({
      type: GET_ALL_MENUS_CATEGORIES,
      payload: error.response,
    });
  }
};

// get one menu
export const getOneMenu = (id) => async (dispatch) => {
  try {
    const response = await useGetDataToken(`/api/v1/menus/menu/${id}`);
    dispatch({
      type: GET_ONE_MENU,
      payload: response,
    });
  } catch (error) {
    dispatch({
      type: GET_ONE_MENU,
      payload: error.response,
    });
  }
};

// add menu
export const addMenu = (body) => async (dispatch) => {
  try {
    const response = await useInsertDataWithImage(`/api/v1/menus`, body);
    dispatch({
      type: ADD_MENU,
      payload: response,
    });
  } catch (error) {
    dispatch({
      type: ADD_MENU,
      payload: error.response,
    });
  }
};

// update menu
export const updateMenu = (id, body) => async (dispatch) => {
  try {
    const response = await useUpdateDataWithImage(`/api/v1/menus/${id}`,body);
    dispatch({
      type: UPDATE_MENU,
      payload: response,
    });
  } catch (error) {
    dispatch({
      type: UPDATE_MENU,
      payload: error.response,
    });
  }
};

import { useState } from "react";
import { useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import { useNavigate } from "react-router-dom";
import avatar from "../../../src/Images/adminIcon.png";
import notify from "../useNotification";
import { addCategoryResturant } from "../../redux/actions/categoriesAction";

const AdminAddCategoryHook = (id) => {
  const navigate = useNavigate();
  const dispatch = useDispatch();

  const [nameAr, setNameAr] = useState("");
  const [nameEn, setNameEn] = useState("");
  const [image, setImage] = useState(avatar);
  const [selectedFile, setSelectedFile] = useState(null);
  const [loading, setLoading] = useState(true);

  const onChangeNameAr = (e) => {
    e.persist();
    setNameAr(e.target.value);
  };

  const onChangeNameEn = (e) => {
    e.persist();
    setNameEn(e.target.value);
  };

  const onImageChange = (event) => {
    if (event.target.files && event.target.files[0])
      setImage(URL.createObjectURL(event.target.files[0]));
    setSelectedFile(event.target.files[0]);
  };

  const onSubmit = async () => {
    if (selectedFile === null) {
      notify("من فضلك أضف صوره للصنف", "error");
      return;
    }
    if (nameAr === "") {
      notify("من فضلك ادخل اسم الدولة بالعربية", "error");
      return;
    }

    if (nameEn === "") {
      notify("من فضلك ادخل اسم الدولة بالانجليزية", "error");
      return;
    }

    setLoading(true);
    const formData = new FormData();
    formData.append("nameAr", nameAr);
    formData.append("nameEn", nameEn);
    formData.append("image", selectedFile);
    formData.append("resturant", id);

    await dispatch(addCategoryResturant(formData));
    setLoading(false);
  };

  //console.log(response)
  useEffect(() => {
    if (loading === false) {
      //  console.log('done')
      setNameAr("");
      setNameEn("");
      notify("تم إضافة الصنف بنجاح", "success");
      setTimeout(() => {
        navigate(`/admin/categories/${id}`);
      }, 1500);
    }
  }, [loading]);

  return [
    nameAr,
    nameEn,
    image,
    onSubmit,
    onChangeNameAr,
    onChangeNameEn,
    onImageChange,
  ];
};

export default AdminAddCategoryHook;

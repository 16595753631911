import React, { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import notify from "../useNotification";
import { useNavigate } from "react-router-dom";
import { addTimeTable } from "../../redux/actions/timeTablesActions";
import { useTranslation } from "react-i18next";



const AdminResturantAddTimeTableHook =(id)=>{

   const navigate = useNavigate();
   const dispatch = useDispatch();
   const { t } = useTranslation();
   const[loading,setLoading]=useState(true);
   const[time,setTime]=useState('');
   const[dayNum,setDayNum]=useState('');
   const[resturant,setResturant]=useState(id);

   const onChangeSetTime= (e)=>{
      setTime(e.target.value);
   }

   const onChangeSetDayNum = (e)=>{
      setDayNum(e.target.value);
   }

   const onSubmit = async()=>{
      if(time=='')
        {
            notify("من فضلك ادخل الوقت", "error");
            return;
        }
        if(dayNum=='')
        {
            notify("من فضلك اختر اليوم", "error");
            return;
        }
       
        //console.log(time,dayNum,resturant);
        await dispatch(addTimeTable({
         time:time,
         dayNum:dayNum,
         resturant:resturant
       }));
 
       setLoading(false);
   }

    let response= useSelector((state)=>state.timeTablesReducer.addTimeTable);

    useEffect(() => {
      if (loading === false) {
          if (response) {
           
            notify("تم إضافة الموعد بنجاح", "success");
            setTimeout(() => {
              navigate(`/admin/timetable/${id}`);
            }, 1500);
          }
          else{
            notify("فشل في عملية الاضافة", "error");
          }
        }
      
      }, [loading]);

   return[
      time,
      dayNum,
      onChangeSetTime,
      onChangeSetDayNum,
      onSubmit
   ]
}

export default AdminResturantAddTimeTableHook;
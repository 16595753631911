import React, { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { getAllRestaurants,updateRestaurant } from "../../redux/actions/restaurantsActions";
import { useNavigate } from "react-router-dom";
import notify from "../useNotification";

const ViewAllRestaurantsHook = () => {
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const [loading, setLoading] = useState(true);

  useEffect(()=>{
    dispatch(getAllRestaurants());
  },[dispatch]);

  const resturants=useSelector((state)=>state.restaurantReducer.getAllRestaurants);

  const clickHandler =(id)=>{
    navigate(`/admin/editrestaurant/${id}`);
  }

  const handleShowFun = async(id, status)=>{
    setLoading(true);
    await dispatch(
      updateRestaurant(id,{
        status: status
    }))
    setLoading(false);
  }

  const res = useSelector((state) => state.restaurantReducer.updateRestaurant);
  useEffect(() => {
    if (loading === false) {
      if (res) {
        notify("تم التعديل بنجاح", "success");
        setTimeout(() => {
          dispatch(getAllRestaurants());
        }, 1000);
      } else {
        notify("فشل في عملية التعديل", "error");
      }
    }
  }, [loading]);
  
  //console.log(response);

  return[
    resturants,
    clickHandler,
    handleShowFun
  ]
  
}
export default ViewAllRestaurantsHook;
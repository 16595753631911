//import { updateTimeTable } from "../actions/timeTablesActions";
import {
    GET_TIME_TABLES_FOR_ONE_RESTURANT,
    UPDATE_TIME_TABLE,
    ADD_TIME_TABLE,
    GET_ONE_TIME_TABLE
} from "../type";

const inital = {
    getAllTimeTablesForOneResturant:[],
    updateTimeTable:[],
    addTimeTable:[],
    getOneTimeTable:[]
}

const timeTablesReducer = (state = inital, action) => {

    switch (action.type) {

        case GET_TIME_TABLES_FOR_ONE_RESTURANT:
            return{
                ...state,
                getAllTimeTablesForOneResturant:action.payload
            }

            case UPDATE_TIME_TABLE:
                return{
                    ...state,
                    updateTimeTable:action.payload
                }
            case ADD_TIME_TABLE:
                return{
                    ...state,
                    addTimeTable:action.payload
                }
            case GET_ONE_TIME_TABLE:
                return{
                    ...state,
                    getOneTimeTable:action.payload
                }
            
    
            default:

           return state
    }

}

export default timeTablesReducer;
import {
  ADD_MENU,
  GET_ALL_MENUS_CATEGORIES,
  GET_ONE_MENU,
  UPDATE_MENU,
} from "../type";

const inital = {
  allMenusCategory: [],
  updateMenu: [],
  addMenu: [],
  oneMenu: [],
};
const menusReducers = (state = inital, action) => {
  switch (action.type) {
    case GET_ALL_MENUS_CATEGORIES:
      return {
        ...state,
        allMenusCategory: action.payload,
      };
    case UPDATE_MENU:
      return {
        ...state,
        updateMenu: action.payload,
      };
    case ADD_MENU:
      return {
        ...state,
        addMenu: action.payload,
      };
    case GET_ONE_MENU:
      return {
        ...state,
        oneMenu: action.payload,
      };

    default:
      return state;
  }
};

export default menusReducers;

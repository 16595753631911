import React, { useEffect, useState } from "react";
import { Button, Container, Modal, Row, Spinner } from "react-bootstrap";
import DataTable from "react-data-table-component";
import AdminAllRestaurantOrdersHook from "../../hook/admin/AdminAllRestaurantOrdersHook";
import DataTableExtensions from "react-data-table-component-extensions";
import "react-data-table-component-extensions/dist/index.css";
import { ToastContainer } from "react-toastify";
import { useNavigate } from "react-router-dom";
import AddButton from "../Utilities/AddButton";
import { useTranslation } from "react-i18next";
import Cookies from "js-cookie";
import { useParams } from "react-router-dom";
import formatDate2 from "../../Utilities/formDate2";
import SearchButton from "../Utilities/SearchButton";

const AdminAllRestaurantOrders = () => {
  const { id } = useParams();
  const { t } = useTranslation();
  const navigate = useNavigate();

  const [
    orders,
    clickHandler,
    handleShowFun,
    refresh,
    flag,
    search
       ] = AdminAllRestaurantOrdersHook(id);

  let columns = [
    {
      name: t("order number"),
      selector: "orderNumber",
      sortable: true,
    },

    {
      name: t("nameAr"),
      cell: (row) => (
        <span
          variant="secondary"
          onClick={() => navigate(`/admin/editUser/${row.userId._id}`)}
          id={row._id}
          style={{
            fontSize: '14px',
            cursor: 'pointer',
            textDecoration: 'underline'
          }}
        >
          {row.userId.fullname}
        </span >
      ),
    },
    {
      name: t("mobile"),
      selector: "receiverMobile",
      sortable: true,
    },
    {
      name: t("orderdate"),
      selector: (row) => formatDate2(row.date),
      sortable: true,
    },
    {
      name: t("ordertime"),
      selector: "time",
      sortable: true,
    },
    {
      name: t("receiveDate"),
      selector: (row) => formatDate2(row.receiveDate),
      sortable: true,
    },
    {
      name: t("receiveTime"),
      selector: "receiveTime",
      sortable: true,
    },
    {
      name: t("order state"),
      selector: (row) =>
        row.status === 'pending' ? t("pending")
          : row.status === 'preparing' ? t("preparing")
            : row.status === 'received' ? t("received")
              : t("closed")
      ,
      sortable: true,
    },
    {
      name: t("details"),
      cell: (row) => (
        <Button
          variant="secondary"
          onClick={() => clickHandler(row._id)}
          id={row._id}
        >
          {t("details")}
        </Button>
      ),
      ignoreRowClick: true,
      allowOverflow: true,
      button: true,
    },
  ];

  const tableData = {
    columns,
    data: orders,
  };

  return (
    <div>
      <title>{`${t("orders")}`} </title>
      <div className="d-flex justify-content-between">
      <div className="admin-content-text">{t("orders Managment")} </div>

      <div className="d-flex justify-content-between">

     
      
      
      
      </div>

      
      
      </div>

      <div className="d-flex justify-content-between">
      <button className="btn-save d-inline mt-2" onClick={refresh} style={{background :'#c78ff2',width:'fit-content'}}>
      { flag=='all' ? t("update") : t("cancel")}
      </button>

      <SearchButton search={search} />
      </div>

      <DataTableExtensions {...tableData} filterPlaceholder={t("search here")}>
        <DataTable
          noHeader
          defaultSortAsc={false}
          pagination
          highlightOnHover
        />
      </DataTableExtensions>
      <ToastContainer />
    </div>
  );
}

export default AdminAllRestaurantOrders;
import React, { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import notify from "../../hook/useNotification";
import { useNavigate } from "react-router-dom";
import { getOneCountry, updateCountry } from "../../redux/actions/countriesActions.js";
import avatar from "../../../src/Images/adminIcon.png";

const AdminEditCountryHook = (id) => {
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const [countryAr, setCountryAr] = useState("");
  const [countryEn, setCountryEn] = useState("");
  const [countryCode, setCountryCode] = useState("");
  const [CountryTellCode, setCountryTellCode] = useState("");
  const [status, setStatus] = useState("");
  const [img, setImg] = useState(avatar);
  const [selectedFile, setSelectedFile] = useState(null);
  const [loading, setLoading] = useState(true);
  const [loadingData, setLoadingData] = useState(true);

  const onChangeCountryAr = (e) => {
    e.persist();
    setCountryAr(e.target.value);
  };

  //set country en state
  const onChangeCountryEn = (e) => {
    e.persist();
    setCountryEn(e.target.value);
  };

  //set country code state
  const onChangeCountryCode = (e) => {
    e.persist();
    setCountryCode(e.target.value);
  };

  //set country tell code state
  const onChangeCountryTellCode = (e) => {
    e.persist();
    setCountryTellCode(e.target.value);
  };

  //set country status
  const onChangeStatus = (e) => {
    e.persist();
    if(e.target.value !==0)
    {
        console.log(e.target.value)
        setStatus(e.target.value);
    }
    
  };

  useEffect(() => {
    dispatch(getOneCountry(id));
  }, [dispatch, id]);

  const oneCountry = useSelector(
    (state) => state.countriesReducers.getOneCountry
  );

  //console.log(oneCountry);

  useEffect(() => {
    if (oneCountry) {
      setCountryAr(oneCountry.countryAr);
      setCountryEn(oneCountry.countryEn);
      setCountryCode(oneCountry.countryCode);
      setCountryTellCode(oneCountry.CountryTellCode);
      setStatus(oneCountry.status);
    }
  }, [oneCountry]);

  const res = useSelector((state) => state.countriesReducers.updateCountry);

  const onSubmit = async () => {
    if(countryAr=='')
    {
        notify("من فضلك ادخل اسم الدولة بالعربية", "error");
        return;
    }

    if(countryEn=='')
    {
        notify("من فضلك ادخل اسم الدولة بالانجليزية", "error");
        return;
    }

    if(countryCode=='')
    {
        notify("من فضلك كود الدولة", "error");
        return;
    }

    if(CountryTellCode=='')
    {
        notify("من فضلك كود الاتصال", "error");
        return;
    }
    if(status==0)
    {
        notify("من فضلك حدد حالة الدولة", "error");
        return;
    }

    setLoading(true);

    await dispatch(
      updateCountry(id, {
        countryAr:countryAr,
        countryEn:countryEn,
        countryCode:countryCode,
        CountryTellCode:CountryTellCode,
        status:status
      })
    );
    // console.log(result);
    setLoading(false);
  }

  useEffect(() => {
    if (loading === false) {
      if (res._id) {
        notify("تم التعديل بنجاح", "success");
        setTimeout(() => {
          navigate("/admin/countries");
        }, 1500);
      } else {
        notify("فشل في عملية التعديل", "error");
      }
    }
  }, [loading]);

  return [
    countryAr,
    countryEn,
    countryCode,
    CountryTellCode,
    img,
    selectedFile,
    status,
    onChangeCountryAr,
    onChangeCountryEn,
    onChangeCountryCode,
    onChangeCountryTellCode,
    onChangeStatus,
    onSubmit
  ];
};

export default AdminEditCountryHook;

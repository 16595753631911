import { getOneRestaurantType } from "../actions/restaurantTypesActions";
import {
    GET_ALL_RESTAURANT_TYPES,UPDATE_RESTAURANT_TYPES,ADD_RESTAURANT_TYPE,GET_ONE_RESTAURANT_TYPE
  } from "../type";
  
  const inital = {
    getAllRestaurantTypes:[],
    updateRestaurantType:[],
    addRestaurantType:[],
    getOneRestaurantType:[]
  }

  const restaurantTypesReducers = (state = inital, action) => {
    switch (action.type) {
        case GET_ALL_RESTAURANT_TYPES:
            return{
                ...state,
                getAllRestaurantTypes:action.payload
            }
        case UPDATE_RESTAURANT_TYPES:
            return{
                ...state,
                updateRestaurantType:action.payload
            }
        case ADD_RESTAURANT_TYPE:
            return{
                ...state,
                addRestaurantType:action.payload
            }
        case GET_ONE_RESTAURANT_TYPE:
        return{
            ...state,
            getOneRestaurantType:action.payload
        }

        default:
            return state
    }

}

export default restaurantTypesReducers;
import React, { useEffect, useState } from "react";
import { Button, Container, Modal, Row, Spinner } from "react-bootstrap";
import DataTable from "react-data-table-component";
import ViewAllCouponsHook from "../../hook/admin/ViewAllCouponsHook";
import DataTableExtensions from "react-data-table-component-extensions";
import "react-data-table-component-extensions/dist/index.css";
import { ToastContainer } from "react-toastify";
import { useNavigate } from "react-router-dom";
import AddButton from "../Utilities/AddButton";
import { useTranslation } from "react-i18next";
import formatDate2 from "../../Utilities/formDate2";
import Cookies from "js-cookie";

const AdminAllCoupons = () => {
    const { t } = useTranslation();
    const navigate = useNavigate();
  //const [users, clickHandler, handleShowFun] = ViewAllUsersHook();  countries,clickHandler,handleShowFun
  const[coupons,clickHandler,handleShowFun] =ViewAllCouponsHook();

  let columns = [
    {
      name: t("coponCode"),
      selector: "coponCode",
      sortable: true,
    },
    {
      name: t("startDate"),
      selector: (row) => formatDate2(row.startDate),
      sortable: true,
    },
    {
      name: t("endDate"),
      selector: (row) => formatDate2(row.endDate),
      sortable: true,
    },
    {
      name: t("coponvalue"),
      selector: "value",
      sortable: true,
    },
    {
        name: t("copontype"),
        selector: "type",
        sortable: true,
    },

    {
      name: t("modify"),
      cell: (row) => (
        <Button
          variant="secondary"
          onClick={() => clickHandler(row._id)}
          id={row._id}
        >
          {t("Modify")}
          <i className="fa fa-pencil-square-o mx-1" />
        </Button>
      ),
      ignoreRowClick: true,
      allowOverflow: true,
      button: true,
    },
    {
      name: t("state"),
      cell: (row) =>
        row.status == true ? (
          <Button
            variant="danger"
            onClick={() => {
              handleShowFun(row._id, false);
            }}
            id={row._id}
          >
            {t("block")}
            <i className="fa fa-lock mx-1" />
          </Button>
        ) : (
          <Button
            variant="success"
            onClick={() => {
              handleShowFun(row._id, true);
            }}
            id={row._id}
          >
            {t("active")}
            <i className="fa fa-unlock mx-1" />
          </Button>
        ),
      ignoreRowClick: true,
      allowOverflow: true,
      button: true,
    },
  ];

  const tableData = {
    columns,
    data: coupons,
  };

  return (
    <div>
      <title>{`${t("coupons")}`} </title>

      <div className="d-flex justify-content-between">
        <div className="admin-content-text">{t("couponsManagment")} </div>
        <AddButton title={t("add coupon")} navigateTo="/admin/addcoupon" />
      </div>

      <DataTableExtensions {...tableData} filterPlaceholder={t("search here")}>
        <DataTable
          noHeader
          defaultSortAsc={false}
          pagination
          highlightOnHover
        />
      </DataTableExtensions>
      <ToastContainer />
    </div>
  );
}

export default AdminAllCoupons;
import { combineReducers } from "redux";
import authReducers from "./authReducers";
import usersReducers from "./usersReducers";
import countriesReducers from "./countriesReducers";
import citiesReducers from "./citiesReducers";
import couponsReducers from "./couponsReducers";
import restaurantTypesReducers from "./restaurantTypesReducers";
import restaurantReducer from "./restaurantReducer";
import categoriesReducers from "./categoriesReducers";
import ordersReducer from "./ordersReducer";
import utilitiesReducer from "./utilitiesReducer";
import menusReducers from "./menusReducers";
import settingsReducers from "./settingsReducers";
import reserveOrdersReducer from "./reserveOrdersReducer";
import timeTablesReducer from "./timeTablesReducer";

export default combineReducers({
  authReducers: authReducers,
  usersReducers: usersReducers,
  countriesReducers: countriesReducers,
  citiesReducers: citiesReducers,
  couponsReducers: couponsReducers,
  restaurantTypesReducers: restaurantTypesReducers,
  restaurantReducer: restaurantReducer,
  ordersReducer: ordersReducer,
  utilitiesReducer: utilitiesReducer,
  categoriesReducers: categoriesReducers,
  menusReducers: menusReducers,
  settingsReducers: settingsReducers,
  reserveOrdersReducer:reserveOrdersReducer,
  timeTablesReducer:timeTablesReducer
});

import React, { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import {
  getAllRestaurantTypes,
  updateRestaurantType,
} from "../../redux/actions/restaurantTypesActions";
import { useNavigate } from "react-router-dom";
import notify from "../useNotification";

const ViewAllRestaurantTypesHook = () => {
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const [loading, setLoading] = useState(true);

  useEffect(() => {
    dispatch(getAllRestaurantTypes());
  }, [dispatch]);

  const restaurantTypes = useSelector(
    (state) => state.restaurantTypesReducers.getAllRestaurantTypes
  );

  const clickHandler = (id) => {
     navigate(`/admin/editresturantType/${id}`);
  };

  const handleShowFun = async (id, status) => {
    setLoading(true);
    await dispatch(
      updateRestaurantType(id, {
        status: status,
      })
    );
    setLoading(false);
  };

  const res = useSelector((state) => state.restaurantTypesReducers.updateRestaurantType);
  useEffect(() => {
    if (loading === false) {
      if (res) {
        notify("تم التعديل بنجاح", "success");
        setTimeout(() => {
          dispatch(getAllRestaurantTypes());
        }, 1000);
      } else {
        notify("فشل في عملية التعديل", "error");
      }
    }
  }, [loading]);

  return [restaurantTypes, clickHandler, handleShowFun];
};

export default ViewAllRestaurantTypesHook;

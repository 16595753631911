import React from "react";
import { Col, Row, Spinner } from "react-bootstrap";
import { useSelector } from "react-redux";
import { useParams } from "react-router-dom";
import { ToastContainer } from "react-toastify";
import AdminEditUserHook from "../../hook/admin/AdminEditUserHook";


const AdminEditUser = () => {
  const { id } = useParams();
  const [
    fullname,
    mobile,
    email,
    status,
    onChangeFullname,
    onChangeEmail,
    onChangeStatus,
    onChangeMobile,
    onSubmit,
    userType,
    onChangeuserType,

  ] = AdminEditUserHook(id);

  const color = useSelector((state) => state.settingsReducers.oneSettings.primaryColor);

  return (
    <div>
      <Row className="justify-content-start ">
        <div className="admin-content-text pb-4">تعديل المستخدم</div>
        <Col sm="8">
          <label htmlFor="firstName" className="d-block mt-3">

            الاسم الكامل
          </label>
          <input
            id="firstName"
            value={fullname}
            onChange={onChangeFullname}
            type="text"
            className="input-form d-block mt-2 px-3"
            placeholder="الاسم الاول"
          />
          <label htmlFor="username" className="d-block mt-3">

            نوع المسخدم
          </label>

          <select
            value={userType}
            onChange={onChangeuserType}
            name="userType"
            id="userType"
            className="select input-form-area mt-2 px-2 "
          >
            <option value="0">نوع المستخدم</option>
            <option value="admin"> أدمن</option>
            <option value="resturantAdmin"> أدمن مطعم</option>
            <option value="user">مستخدم</option>
          </select>
          <label htmlFor="phone" className="d-block mt-3">
            رقم الجوال
          </label>
          <input
            id="phone"
            value={mobile}
            onChange={onChangeMobile}
            type="text"
            className="input-form d-block mt-2 px-3"
            placeholder="رقم الجوال"
          />
          <label htmlFor="email" className="d-block mt-3">

            البريد الالكتروني
          </label>
          <input
            id="email"
            value={email}
            onChange={onChangeEmail}
            type="email"
            className="input-form d-block mt-2 px-3"
            placeholder="البريد الالكتروني"
          />
          <label htmlFor="status" className="d-block mt-3">

            حالة المستخدم
          </label>
          <select
            value={status}
            onChange={onChangeStatus}
            name="status"
            id="status"
            className="select input-form-area mt-2 px-2 "
          >
            <option value="0">حالة المستخدم</option>
            <option value="true">نشط</option>
            <option value="false">غير نشط</option>
          </select>
        </Col>
      </Row>
      <Row>
        <Col sm="8" className="d-flex justify-content-end ">
          <button
            onClick={onSubmit}
            className="btn-save d-inline mt-2 "
            style={{ backgroundColor: color }}
          >
            حفظ التعديلات
          </button>
        </Col>
      </Row>
      <ToastContainer />
    </div>
  );
};

export default AdminEditUser;

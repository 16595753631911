import React from "react";
import AdminAddMenu from "../../Components/Admin/AdminAddMenu";
import SideBar from "../../Components/Admin/SideBar";

const AdminAddMenuPage = () => {
  return (
    <div className="d-flex justify-content-start">
      <div>
        <SideBar />
      </div>
      <div className="p-4" style={{ width: "100%" }}>
        <AdminAddMenu />
      </div>
    </div>
  );
};

export default AdminAddMenuPage;

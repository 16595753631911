
import { useState } from "react";
import { useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import { useNavigate } from "react-router-dom";
import avatar from "../../../src/Images/adminIcon.png";
import notify from "../useNotification";
import { addMenu } from "../../redux/actions/menusAction";
import {getOneCategory} from "../../redux/actions/categoriesAction";

const AdminAddMenuHook = (id) => {
  const navigate = useNavigate();
  const dispatch = useDispatch();
  let user = [];
  if (localStorage.getItem("user") != null)
    user = JSON.parse(localStorage.getItem("user"));


  const [nameAr, setNameAr] = useState("");
  const [nameEn, setNameEn] = useState("");
  const [price, setPrice] = useState(0)
  const [oldPrice, setOldPrice] = useState(0)
  const [description, setDescription] = useState("")

  const [image, setImage] = useState(avatar);
  const [selectedFile, setSelectedFile] = useState(null);
  const [loading, setLoading] = useState(true);


  const onChangeNameAr = (e) => {
    e.persist();
    setNameAr(e.target.value);
  };

  const onChangeNameEn = (e) => {
    e.persist();
    setNameEn(e.target.value);
  };
  const onChangePrice = (e) => {
    e.persist();
    setPrice(e.target.value);
  };

  const onChangeOldPrice = (e) => {
    e.persist();
    setOldPrice(e.target.value);
  };
  const onChangeDescription = (e) => {
    e.persist();
    setDescription(e.target.value);
  };

  const onImageChange = (event) => {
    if (event.target.files && event.target.files[0])
      setImage(URL.createObjectURL(event.target.files[0]));
    setSelectedFile(event.target.files[0]);
  };


  useEffect(()=>{
dispatch(getOneCategory(id))
  },[])
  const categoryResponse = useSelector(
    (state) => state.categoriesReducers.oneCategory
  );

  // console.log('categoryResponse')
  // console.log(categoryResponse?.resturant)

  const onSubmit = async () => {
    if (selectedFile === null) {
      notify("من فضلك أضف صوره للصنف", "error");
      return;
    }
    if (nameAr === "") {
      notify("من فضلك ادخل اسم الدولة بالعربية", "error");
      return;
    }

    if (nameEn === "") {
      notify("من فضلك ادخل اسم الدولة بالانجليزية", "error");
      return;
    }
    if (price === 0) {
      notify("من فضلك  بإضافة السعر ", "error");
      return;
    }

   


    setLoading(true);
    const formData = new FormData();
    formData.append("nameAr", nameAr);
    formData.append("nameEn", nameEn);
    formData.append("image", selectedFile);
    formData.append("price", price);
    formData.append("oldPrice", oldPrice);
    formData.append("description", description);

    // if (user.type === "admin") {
      formData.append("resturant", categoryResponse?.resturant);
    // }else{
    //   formData.append("resturant", user.resturant);
    // }

    formData.append("restaurantCategory", id);


    await dispatch(addMenu(formData));
    setLoading(false);
  };

  //console.log(response)
  useEffect(() => {
    if (loading === false) {
      //  console.log('done')
      setNameAr("");
      setNameEn("");
      notify("تم الإضافه  بنجاح", "success");
      setTimeout(() => {
        navigate(`/admin/menus/${id}`);
      }, 1500);
    }
  }, [loading]);

  return [
    nameAr,
    nameEn,
    price,
    oldPrice,
    description,
    image,
    onChangeNameAr,
    onChangeNameEn,
    onChangePrice,
    onChangeOldPrice,
    onChangeDescription,
    onImageChange,
    onSubmit
  ];
};

export default AdminAddMenuHook;

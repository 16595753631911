import React, { useState, useEffect } from "react";
import notify from "../useNotification";
import { addCity } from "../../redux/actions/citiesActions";
import { useDispatch, useSelector } from "react-redux";
import { useNavigate } from "react-router-dom";
import avatar from "../../../src/Images/adminIcon.png";

const AdminAddCityHook = (id) => {
    
    const navigate = useNavigate();
    const dispatch = useDispatch();
    const [cityAr, setCityAr] = useState("");
    const [cityEn, setCityEn] = useState("");
    const [loading, setLoading] = useState(true);
    const country_id=id;

    //set city ar name
    const onChangeCityAr=(e)=>{
        e.persist();
        setCityAr(e.target.value)
    }

    //set city en name
    const onChangeCityEn=(e)=>{
        e.persist();
        setCityEn(e.target.value)
    }

    

    const onSubmit = async () => {
        if(cityAr=='')
        {
            notify("من فضلك ادخل اسم المدينة بالعربية", "error");
            return;
        }
        if(cityEn=='')
        {
            notify("من فضلك ادخل اسم المدينة بالانجليزية", "error");
            return;
        }

        await dispatch(addCity({
            cityAr:cityAr,
            cityEn:cityEn,
            country:country_id
          }));
    
          setLoading(false)
    }

    const response = useSelector((state) => state.citiesReducers.addCity);

    useEffect(() => {
    if (loading === false) {
        if (response) {
        //  console.log('done')
          setCityAr("");
          setCityEn("");
          notify("تم إضافة المدينة بنجاح", "success");
          setTimeout(() => {
            navigate(`/admin/cities/country/${country_id}`);
          }, 1500);
        }
        else{
          notify("فشل في عملية الاضافة", "error");
        }
      }
    
    }, [loading]);

    return[
        cityAr,
        cityEn,
        onChangeCityAr,
        onChangeCityEn,
        onSubmit
    ]



}

export default AdminAddCityHook;


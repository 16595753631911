import React from "react";
import { Col, Container, Row } from "react-bootstrap";
import AdminEditUser from "../../Components/Admin/AdminEditUser";
import AdminOrderDetails from "../../Components/Admin/AdminOrderDetails";
import SideBar from "../../Components/Admin/SideBar";

const AdminOrderDetailsPage = () => {
    return (

        <div className="d-flex justify-content-start">
            <div>
                <SideBar />
            </div>
            <div className="p-4" style={{ width: '100%' }}>
                <AdminOrderDetails />
            </div>
        </div>

    );
};

export default AdminOrderDetailsPage;

import { ADD_CATEGORY_RESURANT, GET_ALL_RESTIRANT_CATEGORY, GET_ONE_CATEGORY, UPDATE_CATEGORY_RESTURANT } from "../type";

const inital = {
  allResturantCategories: [],
  updateResturantCategory:[],
  addResturantCategory:[],
  oneCategory:[]
};
const categoriesReducers = (state = inital, action) => {
  switch (action.type) {
    case GET_ALL_RESTIRANT_CATEGORY:
      return {
        ...state,
        allResturantCategories: action.payload,
      };
      case UPDATE_CATEGORY_RESTURANT:
        return {
          ...state,
          updateResturantCategory: action.payload,
        };
        case ADD_CATEGORY_RESURANT:
          return {
            ...state,
            addResturantCategory: action.payload,
          };
          case GET_ONE_CATEGORY:
            return {
              ...state,
              oneCategory: action.payload,
            };
        
    default:
      return state;
  }
};

export default categoriesReducers;

import {
    UPDATE_SETTINGS, GET_ONE_SETTINGS
} from "../type";

const inital = {
    updateSettings: [],
    oneSettings: []

}

const settingsReducers = (state = inital, action) => {
    switch (action.type) {
        case UPDATE_SETTINGS:
            return {
                ...state,
                updateSettings: action.payload
            }
        case GET_ONE_SETTINGS:
            return {
                ...state,
                oneSettings: action.payload
            }
        default:
            return state
    }
}

export default settingsReducers

import React, { useState, useEffect } from "react";
import notify from "../useNotification";
import { addRestaurantType } from "../../redux/actions/restaurantTypesActions";
import { useDispatch, useSelector } from "react-redux";
import { useNavigate } from "react-router-dom";
import avatar from "../../../src/Images/adminIcon.png";

const AdminAddRestaurantTypeHook = () => {
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const[resturantTypeAr,setResturantTypeAr]=useState('');
  const[resturantTypeEn,setResturantTypeEn]=useState('');
  const [loading, setLoading] = useState(true);

  //set restaurant ar
  const onChangeresturantTypeAr=(e)=>{
    e.persist();
    setResturantTypeAr(e.target.value);
  }

  //set restaurant en
  const onChangeresturantTypeEn=(e)=>{
    e.persist();
    setResturantTypeEn(e.target.value)
  }

  const onSubmit= async()=>{
    if (resturantTypeAr == "") {
        notify("من فضلك ادخل تصنيف المطعم بالعربية", "error");
        return;
      }
    if (resturantTypeEn == "") {
        notify("من فضلك ادخل تصنيف المطعم بالانجليزية", "error");
        return;
      }

      await dispatch(addRestaurantType({
        resturantTypeAr:resturantTypeAr,
        resturantTypeEn:resturantTypeEn
      }))
       setLoading(false);
  }

  const response = useSelector(state =>state.restaurantTypesReducers.addRestaurantType)

  useEffect(()=>{
    if (loading === false) {
        if (response) {
          notify("تم إضافة التصنيف بنجاح", "success");
          setTimeout(() => {
            navigate("/admin/resturantTypes");
          }, 1500);
        }
        else{
          notify("فشل في عملية الاضافة", "error");
        }
    }
  },[loading])

  return[
    resturantTypeAr,
    resturantTypeEn,
    onChangeresturantTypeAr,
    onChangeresturantTypeEn,
    onSubmit
  ]
  
}
export default AdminAddRestaurantTypeHook;


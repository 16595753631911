import React, { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import {
  getReserveOrderDetails ,updateReserveOrder
} from "../../redux/actions/ReserveOrdersActions";
import { useNavigate } from "react-router-dom";
import notify from "../useNotification";
import { useTranslation } from "react-i18next";

const AdminReserveOrderDetails =(id)=>{
    const dispatch = useDispatch();
    const navigate = useNavigate();
    const [loading, setLoading] = useState(true);

    const [reserveStatus, setReserveStatus] = useState();
    const[resturantId,setResturantId]=useState();
    let restaurant_id='';

    const onChangeReserveStatus= (e)=>{

        setReserveStatus(e.target.value);
    }
    
    useEffect(()=>{
      dispatch(getReserveOrderDetails(id))
    },[dispatch,id]);

    const orderDetails=useSelector((state)=>state.reserveOrdersReducer.getReserveOrderDetails);
    console.log(orderDetails);


    useEffect(()=>{
         if(orderDetails)
         {
            setReserveStatus(orderDetails.status);
            setResturantId(orderDetails.restaurantId);
         }
    },[orderDetails])

    const onSubmit = async()=>{
       
        await dispatch(
            updateReserveOrder(id,{
                status:reserveStatus
            })
        )

        setLoading(false);
    }
    
    const res=useSelector((state)=>state.reserveOrdersReducer.updateReserveOrder);

    useEffect(()=>{
        if (loading === false) {
            if (res._id) {
              notify("تم التعديل بنجاح", "success");
              setTimeout(() => {
                navigate(`/reservorders/resturant/${resturantId}`);
              }, 1500);
            } else {
              notify("فشل في عملية التعديل", "error");
            }
          }

      },[loading])


    return[
        orderDetails,
        reserveStatus,
        onChangeReserveStatus,
        onSubmit
        
    ];
}

export default AdminReserveOrderDetails;
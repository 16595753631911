import {
    GET_ALL_RESTAURANT,UPDATE_RESTAURANT,ADD_RESTAURANT ,GET_ONE_RESTAURANT
  } from "../type";
  
  const inital = {
    getAllRestaurants:[],
    updateRestaurant:[],
    addRestaurant:[],
    getOneRestaurant:[]
  }

  const restaurantReducer = (state = inital, action) => {
    switch (action.type) {
        case GET_ALL_RESTAURANT:
            return{
                ...state,
                getAllRestaurants:action.payload
            }
        case UPDATE_RESTAURANT:
            return{
                ...state,
                updateRestaurant:action.payload
            }
        case ADD_RESTAURANT:
            return{
                ...state,
                addRestaurant:action.payload
            }
        case GET_ONE_RESTAURANT :
            return{
                ...state,
                getOneRestaurant:action.payload
            }

        default:
            return state
    }
  }

  export default restaurantReducer;
import React, { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import notify from "../../hook/useNotification";
import { useNavigate } from "react-router-dom";
import {
  getOneUser,
  getAllUsers,
  updateUser,
} from "../../redux/actions/usersActions";

const AdminEditUserHook = (id) => {
  const [fullname, setFullname] = useState("");
  //const [lastname, setLastname] = useState("");
  const [mobile, setMobile] = useState("");
  const [email, setEmail] = useState("");
  const [status, setStatus] = useState();
  const [loading, setLoading] = useState(true);
  const [loadingData, setLoadingData] = useState(true);
  const [userType, setUserType] = useState("");
  const navigate = useNavigate();

  const dispatch = useDispatch();

  const onChangeuserType = (e) => {
    e.persist();
    setUserType(e.target.value);
  };
  const onChangeFirstname = (e) => {
    e.persist();
    setFullname(e.target.value);
  };

  const onChangeEmail = (e) => {
    e.persist();
    setEmail(e.target.value);
  };
  const onChangeStatus = (e) => {
    e.persist();
    setStatus(e.target.value);
  };
  const onChangeMobile = (e) => {
    e.persist();
    setMobile(e.target.value);
  };

  const oneUser = useSelector((state) => state.usersReducers.oneUser);

  useEffect(() => {
    const get = async () => {
      setLoadingData(true);
      await dispatch(getOneUser(id));
      setLoadingData(false);
    };
    get();
  }, []);

  useEffect(() => {
    if (oneUser) {
      setFullname(oneUser.fullname);
      setMobile(oneUser.mobile);
      setEmail(oneUser.email);
      setStatus(oneUser.status);
      setUserType(oneUser.type);
    }
  }, [loadingData]);

  const res = useSelector((state) => state.usersReducers.updateUser);
  // if(res._id)
  // console.log(res._id)
  // else
  // console.log('no')

  const onSubmit = async () => {
    if (
      fullname === "" ||
      email === "" ||
      mobile === "" ||
      status === "" ||
      userType === "" ||
      userType === "0"
    ) {
      notify("من فضلك اكمل البيانات", "error");
      return;
    }
    setLoading(true);

    await dispatch(
      updateUser(id, {
        fullname: fullname,
        email: email,
        mobile: mobile,
        status: status,
        type: userType,
      })
    );
    // console.log(result);
    setLoading(false);
  };

  useEffect(() => {
    if (loading === false) {
      if (res._id) {
        notify("تم التعديل بنجاح", "success");
        setTimeout(() => {
          navigate("/admin/allusers");
        }, 1500);
      } else {
        notify("فشل في عملية التعديل", "error");
      }
    }
  }, [loading]);

  return [
    fullname,
    mobile,
    email,
    status,
    onChangeFirstname,
    onChangeEmail,
    onChangeStatus,
    onChangeMobile,
    onSubmit,
    userType,
    onChangeuserType,
  ];
};

export default AdminEditUserHook;

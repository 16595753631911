import React, { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { getAllOrdersByRestaurantId, updateOrder } from "../../redux/actions/ordersActions";
import { useNavigate } from "react-router-dom";
import notify from "../useNotification";

const AdminAllRestaurantOrdersHook = (id) => {
  // console.log(id);
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const [loading, setLoading] = useState(true);
  const[orders,setOrders]=useState([]);
  const[allOrders,setAllOrders]=useState([]);

  const [searchField, setSearchField] = useState('');
  const[flag,setFlag]=useState('all');
  const[interval,setIntervalFlag]=useState('');

  const[type,setType]=useState('pending');

  //get all orders
  useEffect(() => {
    dispatch(getAllOrdersByRestaurantId(id,'all'))
  }, [dispatch, id]);


  useEffect(()=>{
    return (
      clearInterval(interval)
    )
  },[])

    let items = useSelector((state) => state.ordersReducer.getAllOrdersByRestaurantId);
   
    const refresh =  ()=>{
     
     if(flag=='refresh')
     {

        clearInterval(interval);
        setFlag('all');
        setOrders(allOrders);
     }
     else
     {
      
      let update_type=document.getElementsByTagName('select')[0].value;
    

      if(update_type==''){
        setType('pending');
      }else {
        setType(update_type)

      }  
      //console.log(update_type);

      setFlag('refresh');
      dispatch(getAllOrdersByRestaurantId(id,'refresh',document.getElementsByTagName('select')[0].value));
    
      const myinterval=setInterval(refreshOrders, 60000);
       setIntervalFlag(myinterval);
      
     }
     

    }

    const refreshOrders =  ()=>{

      setFlag('refresh');
      //console.log('type')
// console.log(type)
       dispatch(getAllOrdersByRestaurantId(id,'refresh',document.getElementsByTagName('select')[0].value));
      
    }



    useEffect(()=>{
      if(items &&  flag=='all')
      {
        setOrders(items);
        setAllOrders(items);
      }
      else
      {
        setOrders(items);
      }
    },[items]);

    const search = (e)=>{
      setSearchField(e.target.previousSibling.value);
    }

   //search button
    useEffect(()=>{
      if(searchField)
      {
       // console.log(searchField);
       let search= allOrders.filter((order)=>{
          let status=order.status;
      
          if(searchField==status)
          {
            return order;
          }
        })
        
        setOrders(search);
      }
      else
      {
        setOrders(allOrders);
      }
    
    },[searchField])

  const clickHandler = (id) => {
    navigate(`/foodorders/details/${id}`);

  };

  const handleShowFun = async (id, status) => {
    setLoading(true);

    await dispatch(
      updateOrder(id, {
        status: status,
      })
    );
    setLoading(false);
  }

  const res = useSelector((state) => state.ordersReducer.updateOrder);

  useEffect(() => {
    if (loading === false) {
      if (res) {
        notify("تم التعديل بنجاح", "success");
        setTimeout(() => {
          dispatch(getAllOrdersByRestaurantId(id));
        }, 1000);
      } else {
        notify("فشل في عملية التعديل", "error");
      }
    }
  }, [loading]);


  return [
    orders,
    clickHandler,
    handleShowFun,
    refresh,
    flag,
    search
  ]
}

export default AdminAllRestaurantOrdersHook;
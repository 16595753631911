import React from "react";
import { Col, Container, Row } from "react-bootstrap";
import AdminAllCoupons from "../../Components/Admin/AdminAllCoupons";
import SideBar from "../../Components/Admin/SideBar";

const AdminAllCouponsPage = () => {
  return (
    <div className="d-flex justify-content-start">

      <div>
        <SideBar />
      </div>
      <div className="p-4" style={{ width: '100%' }}>
       <AdminAllCoupons />
      </div>
    </div>
  );
};

export default AdminAllCouponsPage;
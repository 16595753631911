import React, { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import notify from "../useNotification";
import { useNavigate } from "react-router-dom";
import { updateRestaurant, getOneRestaurant } from "../../redux/actions/restaurantsActions";
import { getAllCountries } from "../../redux/actions/countriesActions";
import { getAllCitiesByCountryId } from "../../redux/actions/citiesActions";
import { getAllRestaurantTypes } from "../../redux/actions/restaurantTypesActions";
import avatar from "../../../src/Images/adminIcon.png";

const AdminEditRestaurantHook = (id) => {
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const [loading, setLoading] = useState(true);

  const [nameAr, setNameAr] = useState("");
  const [nameEn, setNameEn] = useState("");
  const [address, setAdress] = useState("");
  const [mobile, setMobile] = useState("");
  const [descriptionAr, setDescriptionAr] = useState("");
  const [descriptionEn, setDescriptionEn] = useState("");
  const [takeOrderCost, setTakeOrderCost] = useState("");
  const [tableReservedCost, setTableReservedCost] = useState("");
  const [tableNumberPerTime, setTableNumberPerTime] = useState("");
  const [resturantType, setResturantType] = useState("");
  const [country, setCountry] = useState("");
  const [city, setCity] = useState("");
  const [img, setImg] = useState(avatar);
  const [selectedFile, setSelectedFile] = useState(null);
  const [sliderImg, setSliderImg] = useState(avatar);
  const [selectedFileSliderImg, setSelectedFileSliderImg] = useState(null);
  const [onlineReserve, setOnlineReserve] = useState('');
  const [onlineTakeOrder, setOnlineTakeOrder] = useState('');


  const [tablekeysValues, settablekeysValues] = useState([]);
  const [key, setKey] = useState("infoDesc");
  const [value, setValue] = useState("");

  const user = JSON.parse(localStorage.getItem("user"));
  const rule = user.type;
  const restaurant_id = user.resturant;




  const onChangeKey = (e) => {
    e.persist();
    console.log(e.target.value);
    //setKey(e.target.value);
  };

  const onChangeValue = (e) => {
    e.persist();
    console.log(e.target.value);
    setValue(e.target.value);
  };

  const clickHandler = () => {

    settablekeysValues([
      ...tablekeysValues,
      { key: key, value: value, key2: 'isAvailable', value2: 'true', count: tablekeysValues.length + 1 },
    ]);

    //setKey('')
    setValue('')
    console.log(tablekeysValues);
  };


  const deleteElement = (count) => {
    console.log(count)
    settablekeysValues(tablekeysValues.filter(el => el.count !== count))

  }


  useEffect(() => {
    dispatch(getOneRestaurant(id))
  }, [dispatch, id]);

  const restaurant = useSelector((state) => state.restaurantReducer.getOneRestaurant);

  useEffect(() => {
    if (restaurant) {
      setNameAr(restaurant.nameAr);
      setNameEn(restaurant.nameEn);
      setAdress(restaurant.address);
      setMobile(restaurant.mobile);
      setDescriptionAr(restaurant.descriptionAr);
      setDescriptionEn(restaurant.descriptionEn);
      setTakeOrderCost(restaurant.tableFoodCost);
      setTableReservedCost(restaurant.tablePartyCost);
      setTableNumberPerTime(restaurant.tableNumberPerTime);
      setResturantType(restaurant.resturantType);
      setCountry(restaurant.country);
      setCity(restaurant.city);

      setImg(restaurant.logo);
      setSliderImg(restaurant.sliderImage);
      setSelectedFile(restaurant.logo);
      setSelectedFileSliderImg(restaurant.sliderImage);

      setOnlineReserve(restaurant.onlineReserve);
      setOnlineTakeOrder(restaurant.onlineTakeOrder);

    }
  }, [restaurant]);

  useEffect(() => {
    const get = async () => {
      await dispatch(getAllRestaurantTypes());

      if (country)
        await dispatch(getAllCitiesByCountryId(country));
    };
    get();
  }, [dispatch, country]);

  const restaurantTypes = useSelector(
    (state) => state.restaurantTypesReducers.getAllRestaurantTypes
  );

  useEffect(() => {
    const get = async () => {
      await dispatch(getAllCountries());
    };
    get();
  }, [dispatch]);

  const allCountries = useSelector(
    (state) => state.countriesReducers.allCountries
  );

  //  on change online take order 
  const onChangeOnlineTakeOrder = (e) => {
    setOnlineTakeOrder(e.target.value);
  };

  //  on change online reserv
  const onChangeOnlineReserve = (e) => {
    setOnlineReserve(e.target.value);
  };


  //set description ar
  const onChangedescriptionAr = (e) => {
    setDescriptionAr(e.target.value);
  };


  //set description en
  const onChangedescriptionEn = (e) => {
    setDescriptionEn(e.target.value);
  };

  //logo pic
  const onImageChange = (event) => {
    if (event.target.files && event.target.files[0])
      setImg(URL.createObjectURL(event.target.files[0]));
    setSelectedFile(event.target.files[0]);
  };

  //slider img
  const onSliderImgChange = (event) => {
    // console.log('done');
    if (event.target.files && event.target.files[0])
      setSliderImg(URL.createObjectURL(event.target.files[0]));
    setSelectedFileSliderImg(event.target.files[0]);
  };

  //set restauranttypes
  const onChangerestauranttypes = (e) => {
    // e.persist();
    setResturantType(e.target.value);
  };

  //set restaurant namear
  const onChangenameAr = (e) => {
    // e.persist();
    setNameAr(e.target.value);
  };

  //set restaurant name en
  const onChangenameEn = (e) => {
    //e.persist();
    setNameEn(e.target.value);
  };

  //set address
  const onChangeSetAddress = (e) => {
    //e.persist();
    setAdress(e.target.value);
  };

  //set mobile
  const onChangeSetMobile = (e) => {
    //e.persist();
    setMobile(e.target.value);
  };

  //set take order cost
  const onChangeSetTakeOrderCost = (e) => {
    //e.persist();
    setTakeOrderCost(e.target.value);
  };

  //set setTableReservedCost
  const onChangeSetTableReservedCost = (e) => {
    //e.persist();
    setTableReservedCost(e.target.value);
  };

  //set tableNumberPerTime
  const onChangeSetTableNumberPerTime = (e) => {
    //e.persist();
    setTableNumberPerTime(e.target.value);
  };

  //set country
  const onChangeSetCountry = async (e) => {
    //e.persist();
    if (e.target.value != "" && e.target.value != 0) {
      //console.log(country);
      await dispatch(getAllCitiesByCountryId(e.target.value));
    }
    setCountry(e.target.value);
  };

  const cities = useSelector(
    (state) => state.citiesReducers.allCitiesInCountry
  );
  //console.log(cities);

  //set city
  const onChangeSetCity = (e) => {
    //e.persist();
    //console.log(e.target.value);
    setCity(e.target.value);
  };

  const onSubmit = async () => {
    //console.log(tablekeysValues);
    if (nameAr == "") {
      notify("من فضلك ادخل اسم المطعم بالعربية", "error");
      return;
    }
    if (nameEn == "") {
      notify("من فضلك ادخل اسم المطعم بالانجليزية", "error");
      return;
    }
    if (address == "") {
      notify("من فضلك ادخل عنوان المطعم", "error");
      return;
    }
    if (mobile.length <= 10) {
      notify("من فضلك ادخل رقم جوال صحيح", "error");
      return;
    }
    if (takeOrderCost == "") {
      notify("من فضلك ادخل سعر حجز الاوردر", "error");
      return;
    }
    if (tableReservedCost == "") {
      notify("من فضلك ادخل سعر حجز الطاولة", "error");
      return;
    }
    if (tableNumberPerTime == "") {
      notify("من فضلك ادخل عدد الطاولات المتاحة كل ساعة", "error");
      return;
    }

    if (resturantType == "" && resturantType == 0) {
      notify("من فضلك اختر بلد", "error");
      return;
    }

    if (country == "" && country == 0) {
      notify("من فضلك اختر بلد", "error");
      return;
    }
    if (city == "" && city == 0) {
      notify("من فضلك ادخل اختر مدينة", "error");
      return;
    }


    const formData = new FormData();
    formData.append("nameAr", nameAr);
    formData.append("nameEn", nameEn);
    formData.append("address", address);
    formData.append("mobile", mobile);
    formData.append("takeOrderCost", takeOrderCost);
    formData.append("tableReservedCost", tableReservedCost);
    formData.append("descriptionAr", descriptionAr);
    formData.append("descriptionEn", descriptionEn);
    formData.append("tableNumberPerTime", tableNumberPerTime);
    formData.append("country", country);
    formData.append("tableReservedCost", tableReservedCost);
    formData.append("resturantType", resturantType);
    if (selectedFile)
      formData.append("logo", selectedFile);
    if (selectedFileSliderImg)
      formData.append("sliderImage", selectedFileSliderImg);
    formData.append("city", city);
    formData.append("onlineReserve", onlineReserve);
    formData.append("onlineTakeOrder", onlineTakeOrder);


    await dispatch(updateRestaurant(id, formData));
    setLoading(false);
  };

  const res = useSelector((state) => state.restaurantReducer.updateRestaurant);

  useEffect(() => {
    if (loading === false) {
      if (res) {

        //master admin

        if (rule == 'admin') {
          notify("تم التعديل بنجاح", "success");
          setTimeout(() => {
            navigate("/admin/resturants");
          }, 1500);
        }
        //one restaurant admin

        else {
          notify("تم التعديل بنجاح", "success");
        }

      } else {
        notify("فشل في عملية التعديل", "error");
      }
    }
  }, [loading]);

  return [
    nameAr,
    nameEn,
    address,
    mobile,
    takeOrderCost,
    tableReservedCost,
    tableNumberPerTime,
    onChangenameAr,
    onChangenameEn,
    onChangeSetAddress,
    onChangeSetMobile,
    onChangeSetTakeOrderCost,
    onChangeSetTableReservedCost,
    onChangeSetTableNumberPerTime,
    allCountries,
    cities,
    img,
    onChangeSetCountry,
    onChangeSetCity,
    onImageChange,
    restaurantTypes,
    onChangerestauranttypes,
    sliderImg,
    onSliderImgChange,
    descriptionAr,
    descriptionEn,
    onChangedescriptionAr,
    onChangedescriptionEn,
    resturantType,
    country,
    city,
    onlineReserve,
    onlineTakeOrder,
    onChangeOnlineTakeOrder,
    onChangeOnlineReserve,

    tablekeysValues,
    deleteElement,
    key,
    onChangeKey,
    value,
    onChangeValue,
    clickHandler,

    onSubmit,

  ];

}

export default AdminEditRestaurantHook;
import React from "react";
import { Col, Row, Spinner } from "react-bootstrap";
import { ToastContainer } from "react-toastify";
import AdminAddUserHook from "../../hook/admin/AdminAddUserHook";
import { useTranslation } from "react-i18next";
import Cookies from "js-cookie";
import { useSelector } from "react-redux";

const AdminAddUser = () => {
  const { t } = useTranslation();
  const [
    fullname,
    email,
    mobile,
    password,
    confirmPassword,
    type,
    onChangeFullname,
    onChangeEmail,
    onChangeMobile,
    onChangePassword,
    onChangeConfirmPassword,
    onChangeType,
    onSubmit,
    onChangeResturants,
    resturants,
    resturantsData
  ] = AdminAddUserHook();
  const color = useSelector((state) => state.settingsReducers.oneSettings.primaryColor);

  return (
    <div>
      <title>`${t("add user")} </title>

      <Row className="justify-content-start">
        <div className="admin-content-text pb-4">{t("add new user")}</div>

        <Col sm="8">
          <label htmlFor="firstname" className="d-block mt-2 ">
            {" "}
            {t("first name")}{" "}
          </label>
          <input
            id="firstname"
            value={fullname}
            onChange={onChangeFullname}
            type="text"
            className="input-form d-block mt-2 px-3"
            placeholder={t("first name")}
          />
          <label htmlFor="email" className="d-block mt-2 ">
            {" "}
            {t("email")}{" "}
          </label>
          <input
            id="email"
            value={email}
            onChange={onChangeEmail}
            type="email"
            className="input-form d-block mt-2 px-3"
            placeholder={t("email")}
          />
          <label htmlFor="phone" className="d-block mt-2 ">
            {" "}
            {t("phone")}{" "}
          </label>
          <input
            id="phone"
            value={mobile}
            onChange={onChangeMobile}
            type="number"
            className="input-form d-block mt-2 px-3"
            placeholder={t("phone")}
          />

          <label htmlFor="type" className="d-block mt-2 ">
            {" "}
            {t("type")}{" "}
          </label>

          <select
            value={type}
            onChange={onChangeType}
            name="type"
            id="type"
            className="select input-form-area mt-2 px-3 "
          >
            <option value="0">نوع المستخدم</option>
            <option value="admin"> أدمن</option>
            <option value="resturantAdmin"> أدمن مطعم</option>
            <option value="user">مستخدم</option>
          </select>

          {type == "resturantAdmin" ? (
            <>
              <label htmlFor="resturants" className="d-block mt-2 ">
                {t("resturants")}
              </label>
              <div className="d-flex justify-content-center align-items-center">
                <select
                  id="resturants"
                  name="resturants"
                  onChange={onChangeResturants}
                  value={resturants}
                  className="select input-form-area mt-2 px-3 "
                >
                  <option value="0">{t("select restaurant type")}</option>
                  {resturantsData
                    ? resturantsData.map((el) => {
                      return (
                        <option key={el._id} value={el._id}>
                          {Cookies.get("i18next") === "ar"
                            ? el.nameAr
                            : el.nameEn}
                        </option>
                      );
                    })
                    : null}
                </select>
              </div>
            </>
          ) : null}
          <label htmlFor="password" className="d-block mt-2 ">
            {t("password")}{" "}
          </label>
          <input
            id="password"
            value={password}
            onChange={onChangePassword}
            className="input-form d-block mt-2 px-3"
            placeholder={t("password")}
            type="password"
          />
          <label htmlFor="confirmPassword" className="d-block mt-2 ">
            {" "}
            {t("confirm password")}{" "}
          </label>

          <input
            id="confirmPassword"
            value={confirmPassword}
            onChange={onChangeConfirmPassword}
            className="input-form d-block mt-2 px-3"
            placeholder={t("confirm password")}
            type="password"
          />
        </Col>
      </Row>
      <Row>
        <Col sm="8" className="d-flex justify-content-end ">
          <button
            onClick={onSubmit}
            className="btn-save d-inline mt-2 "
            style={{ backgroundColor: color }}
          >
            {t("add")}
          </button>
        </Col>
      </Row>

      <ToastContainer />
    </div>
  );
};

export default AdminAddUser;

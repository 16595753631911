
import React from "react";
import SideBar from "../../Components/Admin/SideBar";
import AdminEditMenu from "../../Components/Admin/AdminEditMenu"

const AdminEditMenuPage = () => {
  return (
    <div className="d-flex justify-content-start">
      <div>
        <SideBar />
      </div>
      <div className="p-4" style={{ width: "100%" }}>
      <AdminEditMenu/>
      </div>
    </div>
  );
};

export default AdminEditMenuPage;

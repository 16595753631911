import {GET_ALL_CITIES_IN_COUNTRY ,UPDATE_CITY ,ADD_CITY,GET_ONE_CITY} from "../type";
import { useGetDataToken } from "../../hooks/useGetData";
import { useUpdateData } from "../../hooks/useUpdateData";
import { useInsertData } from "../../hooks/useInsertData"


//get All cities by country id
export const getAllCitiesByCountryId = (id) => async (dispatch) => {
    try {
        const response = await useGetDataToken(`/api/v1/cities/country/${id}`);
    
        dispatch({
          type: GET_ALL_CITIES_IN_COUNTRY,
          payload: response,
          loading: true,
        });
      } catch (error) {
        dispatch({
          type: GET_ALL_CITIES_IN_COUNTRY,
          payload: error,
        });
      }
}

// update city
export const updateCity = (id, body) => async (dispatch) => {
    try {
      const response = await useUpdateData(`/api/v1/cities/${id}`, body);
  
      dispatch({
        type: UPDATE_CITY,
        payload: response,
        loading: true,
      });
    } catch (error) {
      dispatch({
        type: UPDATE_CITY,
        payload: error,
      });
    }
  };

  //add city
  export const addCity=(body) =>async (dispatch) =>{
    const response= await useInsertData(`/api/v1/cities`,body);

    try {
      dispatch({
        type:ADD_CITY,
        payload: response,
        loading:true
  
      })
      
    } catch (error) {
      dispatch({
        type: ADD_CITY,
        payload: error,
      });
    }

  }

  //get one city by id
  export const getOneCity = (id) =>async (dispatch) =>{
    try {
      //console.log(id)
      const response = await useGetDataToken(`/api/v1/cities/${id}`);

      dispatch({
        type:GET_ONE_CITY,
        payload:response,
        loading:true
      })
    } catch (error) {
      dispatch({
        type:GET_ONE_CITY,
        payload:error
      })
      
    }
  }

 

